import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import MapboxDraw, { DrawCreateEvent, DrawUpdateEvent } from "@mapbox/mapbox-gl-draw";
import { Undo } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, Divider, Typography } from "@mui/material";
import { Feature, FeatureCollection, MultiPolygon, Polygon } from "geojson";
import { SnapPolygonMode } from "mapbox-gl-draw-snap-mode";

import { useGetMultiProjectQuery, useUpdateMultiProjectMutation } from "fond/api";
import defaultStyles from "fond/draw/defaultStyles";
import { MapContext } from "fond/map/MapProvider";
import { combine, feature as toFeature, featureCollection } from "fond/turf";
import { Store } from "fond/types";
import { isAnyPolygon, isPolygon } from "fond/types/geojson";
import { useStackedNavigationContext } from "fond/widgets";
import StackedNavigationHeader from "fond/widgets/StackedNavigation/StackedNavigationHeader";

import { intersect, snappingOptions, uncombine } from "../helper";
import directSelectMode from "../modes/directSelect";
import polygonCutMode from "../modes/polygonCut";

const BoundaryDrawPanel: React.FC = () => {
  const multiProjectId = useSelector((state: Store) => state.project.projectId);
  const { data: multiProject } = useGetMultiProjectQuery(multiProjectId);
  const { goBack, clear } = useStackedNavigationContext();
  const { map, drawControl, setDrawMode } = useContext(MapContext);
  const [updateMultiProject] = useUpdateMultiProjectMutation();

  useEffect(() => {
    drawControl.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {},
      userProperties: true,
      modes: {
        ...MapboxDraw.modes,
        draw_polygon: SnapPolygonMode,
        direct_select: directSelectMode,
        ...polygonCutMode,
      },
      ...snappingOptions,
      styles: defaultStyles,
    });
  }, [map, drawControl]);

  /**
   * Get the multiproject Boundary & convert it to multiple polygons ready to load as
   * the initial draw features.
   */
  const initialFeatures = useMemo(() => (multiProject?.Boundary ? uncombine(toFeature(multiProject.Boundary)) : []), [multiProject?.Boundary]);

  /**
   * Updates the existing boundaries feature-state, allowing for
   * styles to change based on edit status.
   */
  const setExistingBoundaryVisibility = useCallback(
    (visibile: boolean) => {
      map?.setFeatureState({ id: multiProjectId, source: "multiProject-source" }, { isEditing: !visibile });
    },
    [map, multiProjectId]
  );

  /**
   * Clips the feature being drawn or updated so that it does not
   * overlap any other part of the boundary (we apply a small buffer)
   */
  const clipFeature = useCallback(
    (feature: Feature<Polygon | MultiPolygon>): FeatureCollection => {
      return intersect(feature, drawControl.current.getAll().features || []);
    },
    [drawControl]
  );

  /**
   * When a polygon is drawn we make sure that it does not intersect with any other existing
   * boundaries.
   */
  const handleOnCreate = useCallback(
    (event: DrawCreateEvent) => {
      if (isPolygon(event.features[0])) {
        drawControl.current.set(clipFeature(event.features[0]));
      }
    },
    [clipFeature, drawControl]
  );

  /**
   * When a polygon is modifed we make sure that it does not intersect with any other existing
   * boundaries.
   */
  const handleOnUpdate = useCallback(
    (event: DrawUpdateEvent) => {
      if (isAnyPolygon(event.features[0]) && (event.action === "drag_complete" || event.action === "move")) {
        drawControl.current.set(clipFeature(event.features[0]));
      }
    },
    [clipFeature, drawControl]
  );

  const handleBack = () => {
    goBack();
  };

  const onReset = () => {
    drawControl.current.deleteAll();
    initialFeatures.forEach((feat) => {
      drawControl.current.add(feat);
    });
  };

  /**
   * Handles importing the currently draw features and updating the multiProject
   * with the new Boundary.
   */
  const handleOnImport = () => {
    const features = drawControl.current.getAll().features.filter(isAnyPolygon);

    // Combine all Polygons into a single MultiPolygon which is expected by the backend
    const collection = features.length > 0 ? (combine(featureCollection(features)) as FeatureCollection<MultiPolygon>) : null;

    updateMultiProject({ ID: multiProjectId, Boundary: collection?.features[0].geometry || null }).then(() => {
      setDrawMode("no_feature");
      clear();
    });
  };

  /**
   * When the maps style data changes (e.g. user changes to mono view)
   */
  const handleOnStyleChange = useCallback(() => {
    setExistingBoundaryVisibility(false);
  }, [setExistingBoundaryVisibility]);

  useEffect(() => {
    // Hide the existing boundary that we are about to edit
    setExistingBoundaryVisibility(false);

    // Clean up when exiting drawing mode
    return () => {
      setExistingBoundaryVisibility(true);
    };
  }, [drawControl, setExistingBoundaryVisibility]);

  /**
   * Handle the initialization of drawing boundaries
   */
  useEffect(() => {
    // Set the default draw mode to simple_select
    setDrawMode("simple_select");

    // If an existing boundary exists we are entering "edit" mode
    // so add the boundary as a drawn feature & hide the underlying read only version
    if (multiProject?.Boundary) {
      initialFeatures.forEach((feat) => {
        drawControl.current.add(feat);
      });
    } else {
      // If no existing boundary exists we enter drawing mode straight away.
      setDrawMode("draw_polygon");
    }
  }, [drawControl, initialFeatures, map, multiProjectId, multiProject?.Boundary, setDrawMode, setExistingBoundaryVisibility]);

  useEffect(() => {
    map?.on("draw.create", handleOnCreate);
    map?.on("draw.update", handleOnUpdate);
    map?.on("styledata", handleOnStyleChange);

    return () => {
      map?.off("draw.create", handleOnCreate);
      map?.off("draw.create", handleOnUpdate);
      map?.off("styledata", handleOnStyleChange);
      setDrawMode("no_feature");
    };
  }, [handleOnCreate, handleOnStyleChange, handleOnUpdate, map, setDrawMode]);

  return (
    <>
      <StackedNavigationHeader onBackButtonClick={handleBack} title="Draw on map" />
      <Box data-testid="draw-panel" padding={1}>
        <Alert severity="info" data-testid="layer-alert">
          <AlertTitle sx={{ mb: 0 }}>Draw city boundary</AlertTitle>
        </Alert>
        <Divider sx={{ mt: 3 }} />
        <Button fullWidth sx={{ my: 0.5, justifyContent: "left" }} startIcon={<Undo sx={{ height: 20 }} />} onClick={onReset}>
          <Typography color={(theme) => theme.palette.common.black} fontWeight={500} fontSize={13}>
            Reset
          </Typography>
        </Button>
        <Divider />
        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
          <Button color="primary" size="small" onClick={handleBack}>
            Cancel
          </Button>
          <Button variant="contained" size="small" onClick={handleOnImport} sx={{ ml: 1, px: 2 }} data-testid="finish-button">
            Finish
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default BoundaryDrawPanel;

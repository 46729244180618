import React, { useCallback, useContext, useEffect, useState } from "react";

import { CutPolygon } from "fond/svg_icons";

import { MapContext } from "../MapProvider";

import ToolbarButton from "./ToolbarButton";

const CutButton: React.FC = () => {
  const { drawControl, map } = useContext(MapContext);
  const [isDisabled, setIsDisabled] = useState(true);

  /**
   * Monitor when the selectionchange event occurs so that we can disabled
   * this feature based on if a single Multipolygon is selected.
   */
  const handleOnSelect = useCallback(() => {
    const selectedFeatures = drawControl.current.getSelected().features;

    // Confirm that the selected feature is a multiPolygon but also have multiple coordinates.
    if (selectedFeatures.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [drawControl]);

  useEffect(() => {
    map?.on("draw.selectionchange", handleOnSelect);

    return () => {
      map?.off("draw.selectionchange", handleOnSelect);
    };
  }, [map, handleOnSelect]);

  const handleOnClick = () => {
    drawControl.current?.changeMode("cut_polygon");
  };

  return (
    <ToolbarButton title="Cut features" onClick={handleOnClick} disabled={isDisabled}>
      <CutPolygon />
    </ToolbarButton>
  );
};

export default CutButton;

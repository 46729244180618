import React, { useContext } from "react";
import { DrawModeChangeEvent } from "@mapbox/mapbox-gl-draw";

import { PointerIcon } from "fond/svg_icons";

import { MapContext } from "../MapProvider";

import { IButtonProps } from "./Toolbar";
import ToolbarButton from "./ToolbarButton";

const SelectButton: React.FC<IButtonProps> = ({ mode }: IButtonProps) => {
  const { map, drawControl } = useContext(MapContext);

  const handleOnClick = () => {
    drawControl.current.changeMode("simple_select");
    map?.fire("draw.modechange", { type: "draw.modechange", mode: "simple_select" } as DrawModeChangeEvent);
  };

  return (
    <ToolbarButton title="Select feature" onClick={handleOnClick} active={mode === "simple_select"}>
      <PointerIcon />
    </ToolbarButton>
  );
};

export default SelectButton;

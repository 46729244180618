import { MapLayerConfigBulk } from "fond/types";

import { apiSlice } from "./apiSlice";

/**
 * MapLayerConfig API Slice
 */
export const mlcSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getMlcBulk: build.query<MapLayerConfigBulk, string>({
      query: (mapLayerConfigId: string) => `/v1/map-layer-configs/${mapLayerConfigId}/bulk`,
      providesTags: (result) => (result ? [{ type: "MapLayerConfig", id: result.ID }] : []),
    }),
    updateMlcBulk: build.mutation<MapLayerConfigBulk, { ID: string; data: MapLayerConfigBulk }>({
      query: ({ ID, data }) => ({
        url: `/v1/map-layer-configs/${ID}/bulk`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "MapLayerConfig", id: arg.ID }],
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const { useGetMlcBulkQuery, useUpdateMlcBulkMutation } = mlcSlice;

import React, { useMemo } from "react";
import { Alert } from "@mui/material";

import { MultiProject } from "fond/types";
import { StackedNavigationButton, SvgIcon, useStackedNavigationContext } from "fond/widgets";

interface AreaPanelProps {
  multiProject: MultiProject;
}

const AreaPanel: React.FC<AreaPanelProps> = ({ multiProject }: AreaPanelProps) => {
  const hasProjects = useMemo(() => multiProject?.Areas.some((area) => area.Project?.ID), [multiProject]);
  const { open } = useStackedNavigationContext();

  const handleOnClick = () => {
    open("areaDraw");
  };

  return (
    <>
      <StackedNavigationButton
        data-testid="area-method-navigation"
        icon={<SvgIcon icon="polygon" />}
        title="Subareas"
        onClick={handleOnClick}
        disabled={hasProjects}
        sx={{ m: 0 }}
      />
      {hasProjects && (
        <Alert severity="info" icon={false} sx={{ mt: 1 }}>
          <b>Note:</b> the subareas cannot be edited once projects have been created.
        </Alert>
      )}
    </>
  );
};

export default AreaPanel;

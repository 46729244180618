import { useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Search } from "@mui/icons-material";
import { Autocomplete, IconButton } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";

import { selectAllFolders, selectAllProjects, selectAllReports, selectCurrentAccount, useGetFoldersQuery, useGetProjectsQuery } from "fond/api";
import { SEARCH_KEY } from "fond/constants";
import { Folder, ProjectPreview, Report, Store } from "fond/types";
import { getPath } from "fond/utils/folder";
import { useAppDispatch } from "fond/utils/hooks";
import { searchCollectionByKey } from "fond/utils/search";

import { updateFilter } from "../redux";

import SearchSuggestionsRow from "./SearchSuggestionsRow";

import { ListSubheader, Paper, SeeAllResultsButton, TextField } from "./Search.styles";

const MAX_SUGGESTIONS_PER_GROUP = 5;
export interface SuggestionsList {
  projects: Array<ProjectPreview & { path: string[] }>;
  folders: Folder[];
  reports: Array<Report & { path: string[] }>;
}

const GlobalSearch: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<string>("");
  const [suggestionsList, setSuggestionsList] = useState<SuggestionsList>({ projects: [], folders: [], reports: [] });
  const [open, setOpen] = useState(false);
  const closeOptions = () => setOpen(false);

  const { filters } = useSelector((state: Store) => state.projects);
  const currentAccount = useSelector(selectCurrentAccount);

  useGetFoldersQuery(undefined);
  useGetProjectsQuery(undefined);
  const projects = useSelector(selectAllProjects);
  const folders = useSelector(selectAllFolders);
  const reports = useSelector(selectAllReports);

  const handleSearchTrigger = () => {
    if (value) {
      closeOptions();
      navigate(`/${filters}?${SEARCH_KEY}=${value}`);
    }
  };

  /**
   * Event handler for selecting autocomplete option
   * and redirect users to folder/project page
   */
  const handleOnChange = (
    _: React.SyntheticEvent<Element, Event>,
    selected: SuggestionsList["projects"][number] | Folder | { EntityType: "all" } | Report
  ) => {
    if (selected?.EntityType === "all") {
      return;
    }

    const resourceId = selected?.ID;
    if (selected?.EntityType === "folder") {
      const selectedInCurrentAccount = selected.Account.ID === currentAccount?.ID;
      dispatch(updateFilter(selectedInCurrentAccount ? "workspace" : "shared"));

      closeOptions();
      navigate(`/folders/${resourceId}`);
      return;
    }
    navigate(`/project/${resourceId}`);
  };

  const setSuggestionsListDebounced = useDebouncedCallback((searchValue: string) => {
    const suggestedProjects = searchCollectionByKey(projects, searchValue, "ProjectName").slice(0, MAX_SUGGESTIONS_PER_GROUP);
    const suggestedFolders = searchCollectionByKey(folders, searchValue, "Name").slice(0, MAX_SUGGESTIONS_PER_GROUP);
    const suggestedReports = searchCollectionByKey(reports, searchValue, "Name").slice(0, MAX_SUGGESTIONS_PER_GROUP);
    setSuggestionsList({
      projects: suggestedProjects.map((project) => ({ ...project, path: getPath(folders, project.FolderID) })),
      folders: suggestedFolders,
      reports: suggestedReports.map((report) => ({ ...report, path: getPath(folders, report.Folder?.ID) })),
    });
  }, 500);

  /**
   * Event handler for text field.
   * Sets the search value to navigate to search page (immediate)
   * and sets suggestion list based on the search value (debounced)
   */
  const handleInputChange = (searchValue: string) => {
    setValue(searchValue);
    setSuggestionsListDebounced(searchValue);
  };

  const getOptionLabel = (option: SuggestionsList["projects"][number] | Folder | { EntityType: "all" } | Report) => {
    switch (option.EntityType) {
      case "folder":
        return option.Name;
      case "project":
        return option.ProjectName;
      case "report":
        return option.Name;
      default:
        return value;
    }
  };

  const options = [
    ...suggestionsList.projects,
    ...suggestionsList.folders,
    ...suggestionsList.reports,
    ...(suggestionsList.projects.length === MAX_SUGGESTIONS_PER_GROUP ||
    suggestionsList.folders.length === MAX_SUGGESTIONS_PER_GROUP ||
    suggestionsList.reports.length === MAX_SUGGESTIONS_PER_GROUP
      ? [{ EntityType: "all" as const }]
      : []),
  ];

  const getGroupBy = (option: SuggestionsList["projects"][number] | Folder | { EntityType: "all" } | Report): string => {
    if (option.EntityType === "folder" && option.MultiProject !== null) {
      return "CITY PLANNER";
    }
    return option.EntityType.toLocaleUpperCase();
  };

  return (
    <Autocomplete
      data-testid="global-search"
      id="global-search"
      disableClearable
      open={open && (!!suggestionsList.folders.length || !!suggestionsList.projects.length || !!suggestionsList.reports.length)}
      onOpen={() => {
        if (value) setOpen(true);
      }}
      onClose={closeOptions}
      onClick={handleSearchTrigger}
      onChange={handleOnChange}
      onKeyDown={(event) => event.key === "Enter" && handleSearchTrigger()}
      onInputChange={(_, newValue) => handleInputChange(newValue)}
      options={options}
      filterOptions={(filteredOptions) => filteredOptions}
      groupBy={getGroupBy}
      getOptionLabel={getOptionLabel}
      PaperComponent={(props) => <Paper {...props} />}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ width: 490, background: (theme) => theme.palette.common.white }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <IconButton onClick={handleSearchTrigger}>
                <Search />
              </IconButton>
            ),
          }}
          placeholder="Search for projects or folders"
        />
      )}
      renderOption={(props, option) =>
        option.EntityType === "all" ? (
          <SeeAllResultsButton {...props} data-testid="see-all-results" key="ALL" onClick={handleSearchTrigger}>
            see all results
          </SeeAllResultsButton>
        ) : (
          <SearchSuggestionsRow {...props} option={option} onClick={closeOptions} key={option.ID} />
        )
      }
      renderGroup={(params) => (
        <React.Fragment key={params.group}>
          {params.group !== "ALL" && <ListSubheader component="div">{params.group}</ListSubheader>}
          {params.children}
        </React.Fragment>
      )}
    />
  );
};

export default GlobalSearch;

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import _ from "lodash";

import { requestJson } from "fond/api";
import { AsyncOperationState, makeAsyncActions } from "fond/async/redux";
import { removeMapboxDraw } from "fond/draw";
import { LayerIds } from "fond/layers";
import { getMap } from "fond/map/redux";
import { polygonErrorStyle, polygonStyle } from "fond/map/styles";
import { isAcceptableSize, POLYGON_UPLOAD_LAYER_ID, PolygonStatus } from "fond/project/polygon";
import { ProjectAction } from "fond/project/reduxActions";
import * as turf from "fond/turf";
import { logErrorToBrowser, makeActions, reduceReducers, updateIn } from "fond/utils";

import updateDataFromSelectedArea from "./download";

const { theme: defaultTheme } = MapboxDraw.lib;

export const actions = {
  ...makeActions("PROJECT/POLYGON", [
    "UPDATE_STATE",
    "UPDATE_POLYGON",
    "DOWNLOAD_DATA_SUCCESS",
    "DOWNLOAD_DATA_FAILURE",
    "RETRY_DOWNLOAD",
    "TOGGLE_LAYER",
    "RESET",
    "BEGIN_UPLOAD",
    "UPLOAD_PROGRESS",
    "UPLOAD_COMPLETE",
    "UPLOAD_FAILURE",
    "REQUEST_DATA",
    "REQUEST_DATA_CANCEL",
    "CLOSE",
    "SELECT_UPLOAD_OPTION",
    "UPLOAD_POLYGON_COMPLETE",
  ]),
  SUBMIT_REQUEST_DATA: makeAsyncActions("PROJECT/POLYGON/SUBMIT_REQUEST_DATA"),
};

export const PolygonState = {
  modeSelect: "modeSelect",

  uploadingPolygon: "uploadingPolygon",

  // Polygon select is active but the user hasn't begun drawing a polygon.
  nullDrawing: "nullDrawing",

  // The user has started drawing some points on their polygon but it's not a
  // complete polygon yet.
  partial: "partial",

  // The user has a complete polygon but hasn't hit the complete/upload button
  // yet.
  complete: "complete",

  // The user has hit the complete/upload button and the upload is either in
  // progress, complete or has errored.
  upload: "upload",
};

const initialRequestDataFormState = {
  isOpen: false,
  requestState: null, // AsyncOperationState
  hasSuceeded: false,
};

export const initialState = {
  isOpen: false,

  polygonState: PolygonState.modeSelect,

  selectedLayers: {
    [LayerIds.inAddress]: true,
    [LayerIds.inStreet]: true,
    [LayerIds.inParcel]: true,
  },

  selectedArea: turf.nullPolygon(),
  downloadedArea: turf.nullPolygon(),
  downloadedBaseData: {
    [LayerIds.inAddress]: turf.featureCollection([]),
    [LayerIds.inStreet]: turf.featureCollection([]),
    [LayerIds.inParcel]: turf.featureCollection([]),
    [LayerIds.inSpan]: turf.featureCollection([]),
    [LayerIds.inPole]: turf.featureCollection([]),
  },
  selectedData: {
    [LayerIds.inAddress]: turf.featureCollection([]),
    [LayerIds.inStreet]: turf.featureCollection([]),
    [LayerIds.inParcel]: turf.featureCollection([]),
    [LayerIds.inSpan]: turf.featureCollection([]),
    [LayerIds.inPole]: turf.featureCollection([]),
  },

  incompleteData: {
    // True if we have tried to retrieve data for the particular layer but it
    // has been unavailable for some or all of the selected region.
    [LayerIds.inAddress]: false,
    [LayerIds.inStreet]: false,
    [LayerIds.inParcel]: false,
    [LayerIds.inSpan]: false,
    [LayerIds.inPole]: false,
  },

  downloadState: null, // AsyncOperationState

  requestDataForm: initialRequestDataFormState,

  errorMessage: null,
};

export function retryDownload() {
  return (dispatch, getState) => {
    dispatch({ type: actions.RETRY_DOWNLOAD });
    dispatch(downloadData(getState().project.polygon.selectedArea));
  };
}

export function downloadData(polygon) {
  return async (dispatch, getState) => {
    const state = getState().project.polygon;
    try {
      const newState = await updateDataFromSelectedArea({
        ...state,
        selectedArea: polygon,
        aerial: state.selectedLayers[LayerIds.inPole] || state.selectedLayers[LayerIds.inSpan],
      });

      if ([PolygonStatus.InvalidArea, PolygonStatus.QueryTooLarge].includes(newState.status)) {
        dispatch({ type: actions.DOWNLOAD_DATA_FAILURE, newState: newState });
      } else {
        dispatch({ type: actions.DOWNLOAD_DATA_SUCCESS, newState: newState });
      }
    } catch (e) {
      logErrorToBrowser(e);
      dispatch({ type: actions.DOWNLOAD_DATA_FAILURE });
    }
  };
}

export function togglePolygonLayer(layerId) {
  return { type: actions.TOGGLE_LAYER, layerId: layerId };
}

export function reset(version) {
  return (dispatch, getState) => {
    dispatch({ type: actions.RESET });
    if (version.UploadedArea != null) {
      mapboxDraw.set(turf.featureCollection([version.UploadedArea]));
    } else {
      mapboxDraw.set(turf.featureCollection([]));
    }
  };
}

export function getBeginState(polygonState, project, data, layerIds) {
  return {
    ...polygonState,
    isOpen: true,
    selectedLayers: {
      [LayerIds.inAddress]: _.includes(layerIds, LayerIds.inAddress) || _.includes(layerIds, LayerIds.inParcel),
      [LayerIds.inStreet]: _.includes(layerIds, LayerIds.inStreet),
      [LayerIds.inParcel]: _.includes(layerIds, LayerIds.inParcel) || _.includes(layerIds, LayerIds.inAddress),
      [LayerIds.inPole]: _.includes(layerIds, LayerIds.inPole) || _.includes(layerIds, LayerIds.inSpan),
      [LayerIds.inSpan]: _.includes(layerIds, LayerIds.inSpan) || _.includes(layerIds, LayerIds.inPole),
    },
    downloadedArea: turf.nullPolygon(),
    downloadedBaseData: {
      [LayerIds.inAddress]: turf.featureCollection([]),
      [LayerIds.inStreet]: turf.featureCollection([]),
      [LayerIds.inParcel]: turf.featureCollection([]),
      [LayerIds.inPole]: turf.featureCollection([]),
      [LayerIds.inSpan]: turf.featureCollection([]),
    },
    ...(project.SelectedArea != null
      ? {
          selectedData: {
            [LayerIds.inAddress]: data?.layers[LayerIds.inAddress] || turf.featureCollection([]),
            [LayerIds.inStreet]: data?.layers[LayerIds.inStreet] || turf.featureCollection([]),
            [LayerIds.inParcel]: data?.layers[LayerIds.inParcel] || turf.featureCollection([]),
            [LayerIds.inPole]: data?.layers[LayerIds.inPole] || turf.featureCollection([]),
            [LayerIds.inSpan]: data?.layers[LayerIds.inSpan] || turf.featureCollection([]),
          },
          selectedArea: project.SelectedArea,
        }
      : null),
  };
}

export const reducer = reduceReducers((state = initialState, action) => {
  switch (action.type) {
    case actions.SELECT_UPLOAD_OPTION:
      return {
        ...state,
        polygonState: PolygonState.uploadingPolygon,
      };
    case actions.UPLOAD_POLYGON_COMPLETE:
      return {
        ...state,
        polygonState: "complete",
      };
    case actions.UPDATE_POLYGON: {
      return {
        ...state,
        selectedArea: action.selectedArea,
        isTooBig: !isAcceptableSize(action.selectedArea),
        downloadState: AsyncOperationState.executing,
        // If we finished uploading and then modified the polygon, go back to
        // 'complete' and reset the upload state.
        polygonState: state.polygonState === PolygonState.upload ? PolygonState.complete : state.polygonState,
      };
    }
    case actions.DOWNLOAD_DATA_SUCCESS:
      return {
        ...state,
        ...action.newState,
        downloadState: AsyncOperationState.success,
      };
    case actions.DOWNLOAD_DATA_FAILURE:
      return {
        ...state,
        ...action.newState,
        downloadState: AsyncOperationState.failure,
      };
    case actions.RETRY_DOWNLOAD:
      return {
        ...state,
        downloadState: AsyncOperationState.executing,
      };
    case actions.UPDATE_STATE:
      return { ...state, polygonState: action.state };
    case actions.TOGGLE_LAYER:
      return updateIn(state, ["selectedLayers", action.layerId], (v) => !v);
    case actions.RESET:
      return {
        ...state,
        polygonState: PolygonState.modeSelect,
        status: undefined,
        downloadState: null,
        errorMessage: null,
        isTooBig: false,
      };
    default:
      return state;
  }
});

export function isPolygonSelectActive(polygonState) {
  return polygonState.isOpen;
}

export function getUploadState(uploads) {
  if (_.every(uploads, (u) => u == null || u.status === AsyncOperationState.success)) {
    return AsyncOperationState.success;
  } else if (_.some(uploads, (u) => u != null && u.status === AsyncOperationState.executing)) {
    return AsyncOperationState.executing;
  } else {
    return AsyncOperationState.failure;
  }
}

export function generateRandomData(poly) {
  /**
   * Dev method: Given a geojson polygon, return a bunch of random addresses
   * and roads within it.
   */
  const points = turf.randomPoint(100, { bbox: turf.bbox(poly) }).features.filter((p) => turf.booleanPointInPolygon(p, poly));
  const coordinates = points.map((f) => f.geometry.coordinates);

  return {
    [LayerIds.inAddress]: turf.featureCollection(points),
    [LayerIds.inStreet]: turf.featureCollection([turf.lineString(coordinates)]),
  };
}

// Globals persisted across a particular polygon select session.
let mapboxDraw;
let startDrawingTimer;
let drawUpdateCallback;
let drawCreateCallback;

export function initDraw(initialPolygon = null) {
  return (dispatch, getState) => {
    const map = getMap();

    if (mapboxDraw != null) {
      teardownDraw();
    }

    mapboxDraw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {},
      styles: defaultTheme.map((style) => {
        if (style.id === "gl-draw-polygon-fill-active") {
          return { ...style, paint: polygonStyle.paint };
        } else {
          return style;
        }
      }),
    });

    map.addControl(mapboxDraw);

    // Save references to these functions so we can point to them to remove
    // them on teardown.
    drawUpdateCallback = () => {
      dispatch(updateDrawing());
    };
    drawCreateCallback = () => {
      dispatch(completeDrawing());
    };

    map.on("draw.delete", drawUpdateCallback);
    map.on("draw.update", drawUpdateCallback);
    map.on("draw.create", drawCreateCallback);

    if (initialPolygon != null) {
      setDrawPolygon(initialPolygon);
    } else {
      let hasStartedDrawing = false;
      startDrawingTimer = setInterval(() => {
        // MapboxDraw doesn't expose an event for "started drawing" so we poll
        // to make one ourselves. We need this in order for the overlay to have
        // separate "begin drawing your polygon" and "continue working on your
        // polygon" messages.
        const geojson = mapboxDraw.getAll();
        if (!hasStartedDrawing && geojson.features.length > 0 && geojson.features[0].geometry.coordinates[0].length > 2) {
          hasStartedDrawing = true;
          if (getState().project.polygon.polygonState === PolygonState.nullDrawing) {
            dispatch(beginDrawing());
          }
          clearInterval(startDrawingTimer);
          startDrawingTimer = null;
        }
      }, 100);

      mapboxDraw.changeMode("draw_polygon");
    }
  };
}

export function teardownDraw() {
  if (startDrawingTimer != null) {
    clearInterval(startDrawingTimer);
  }

  const map = getMap();
  removeMapboxDraw(mapboxDraw, map);
  mapboxDraw = null;

  map.off("draw.delete", drawUpdateCallback);
  map.off("draw.update", drawUpdateCallback);
  map.off("draw.create", drawCreateCallback);
}

export function setDrawPolygon(polygon) {
  if (polygon.type !== "Feature") {
    throw new Error("`polygon` must be a GeoJSON Feature");
  }
  const map = getMap();
  mapboxDraw.set(turf.featureCollection([polygon]));
  mapboxDraw.changeMode("simple_select", { featureIds: [mapboxDraw.getAll().features[0].id] });
  map.fitBounds(turf.bbox(polygon), { padding: 20 });
}

export function resetPolygonSelectMode() {
  return (dispatch) => {
    // This is used to reset the draw controls back to polygon select mode if
    // the user uses the search facility while polygon select is active.
    // Wait for the map to finish panning+zooming before setting the mode
    // otherwise the map will conflict with our setting.
    const map = getMap();

    let timer = setInterval(() => {
      if (!map.isMoving()) {
        // Because of internal finnickiness in the Mapbox Geocoder control,
        // this callback may be called twice, and we changeMode('draw_polygon')
        // while the user is editing a polygon, it resets the polygon to
        // nothing, so make sure not to reset polygon mode if we're aleady in
        // that mode.
        if (mapboxDraw.getMode() !== "draw_polygon") {
          mapboxDraw.changeMode("draw_polygon");
        }
        clearInterval(timer);
      }
    }, 50);
  };
}

function setPolygonError(isError) {
  return function (dispatch, getState) {
    const style = isError ? polygonErrorStyle : polygonStyle;
    _.forEach(style.paint, (v, k) => {
      getMap().setPaintProperty("gl-draw-polygon-fill-active.cold", k, v);
    });
  };
}

/**
 * When the user hits the "Upload" option after starting polygon select.
 */
export function selectUploadOption() {
  return { type: actions.SELECT_UPLOAD_OPTION };
}

/**
 * When the user hits "Draw a new polygon" after starting polygon select.
 */
export function selectDrawNewOption() {
  return (dispatch) => {
    dispatch({ type: actions.UPDATE_STATE, state: PolygonState.nullDrawing });
    dispatch(initDraw());
  };
}

/**
 * When the user hits "Draw from existing polygon" after starting polygon select.
 */
export function selectDrawExistingOption(feature) {
  return startDrawingPolygon(feature);
}

/**
 * Start drawing, either with an existing polygon (that may have been uploaded
 * or the already-existing project polygon), or from scratch.
 */
function startDrawingPolygon(polygon = null) {
  return (dispatch) => {
    if (polygon != null) {
      dispatch(updatePolygon(polygon));
      dispatch({ type: actions.UPLOAD_POLYGON_COMPLETE });
      dispatch(initDraw(polygon));
    } else {
      dispatch(selectDrawNewOption());
    }
  };
}

/**
 * When the user has uploaded a polygon, so now it can be displayed and we can
 * begin downloading the addresses / streets within it.
 */
export function uploadPolygonComplete(geojson) {
  return (dispatch, getState) => {
    dispatch(startDrawingPolygon(geojson.features[0]));
    dispatch(resetUpload(getState().project.projectId, POLYGON_UPLOAD_LAYER_ID));
  };
}

/**
 * When we're in the drawing mode and we make the initial click to begin drawing an actual polygon.
 */
export function beginDrawing() {
  return { type: actions.UPDATE_STATE, state: PolygonState.partial };
}

/**
 * Provide the ability to change the current drawing mode of mapbox draw
 */
export function changeMode(mode) {
  return (dispatch) => {
    mapboxDraw.changeMode(mode);
  };
}

/**
 * When we make an edit to the polygon we're drawing.
 */
export function updateDrawing() {
  return (dispatch) => {
    const geojson = mapboxDraw.getAll();
    if (geojson.features.length > 0) {
      const poly = geojson.features[0];
      dispatch(updatePolygon(poly));
      dispatch({ type: actions.UPDATE_POLYGON, selectedArea: poly });
      dispatch(setPolygonError(!isAcceptableSize(poly)));
    }
  };
}

/**
 * When we draw a complete polygon (ie. by double clicking after drawing a
 * number of points).
 */
export function completeDrawing() {
  return (dispatch) => {
    dispatch(updateDrawing());
    dispatch({ type: actions.UPDATE_STATE, state: PolygonState.complete });
  };
}

export function resetUpload(projectId, layerId) {
  return { type: ProjectAction.RESET_UPLOAD, projectId: projectId, layerId: layerId };
}

export function updatePolygon(poly) {
  if (poly.type !== "Feature") {
    throw new Error("`polygon` must be a GeoJSON Feature");
  }
  return (dispatch, getState) => {
    const isDownloading = isAcceptableSize(poly);
    dispatch({
      type: actions.UPDATE_POLYGON,
      selectedArea: poly,
      isDownloading: isDownloading,
    });

    const { versionId } = getState().project;
    requestJson("PATCH", `/v2/versions/${versionId}`, {
      SelectedArea: poly,
    });

    if (isDownloading) {
      dispatch(downloadData(poly));
    }
  };
}

import * as React from "react";
import { useSelector } from "react-redux";
import { Box, Divider, PopoverOrigin } from "@mui/material";

import { selectFolderById, useGetMultiProjectQuery } from "fond/api";
import { getCurrentProject } from "fond/project";
import { KnowledgeBase, Support } from "fond/project/pageMenu/help";
import { Close } from "fond/project/pageMenu/project";
import { Layout, MapAppearance } from "fond/project/pageMenu/view";
import { Store } from "fond/types";

import { Menu, MenuButton } from "./PageMenu.styles";

interface IProps {
  anchorOrigin: PopoverOrigin;
  menuAnchorEl: HTMLElement | null;
  open: boolean;
  onMenuClick(event: React.MouseEvent<HTMLButtonElement>): void;
  onClose(): void;
}

const MultiProjectMenu: React.FC<IProps> = ({ anchorOrigin, menuAnchorEl, open, onClose, onMenuClick }: IProps) => {
  const projectId = useSelector((state: Store): string => getCurrentProject(state.project)?.ID);
  const { data: project } = useGetMultiProjectQuery(projectId);
  const currentFolder = useSelector((state: Store) => (project?.Folder.ID ? selectFolderById(state, project.Folder.ID) : undefined));

  /**
   * Definition of the menu items.
   */
  const menus = [
    {
      id: "multi-project-menu-project",
      title: "Project",
      children: <Close currentFolderId={currentFolder?.ID} />,
    },
    {
      id: "multi-project-menu-view",
      title: "View",
      children: (
        <>
          <MapAppearance parentMenuOpen={Boolean(menuAnchorEl?.id === "multi-project-menu-view")} handleClose={onClose} />
          <Divider style={{ margin: 0 }} />
          <Layout type="multiProject" parentMenuOpen={Boolean(menuAnchorEl?.id === "multi-project-menu-view")} handleClose={onClose} />
        </>
      ),
    },
    {
      id: "multi-project-menu-help",
      title: "Help",
      children: (
        <>
          <Support handleClose={onClose} />
          <KnowledgeBase handleClose={onClose} />
        </>
      ),
    },
  ];

  return (
    <>
      {menus.map((menuItem) => (
        <Box key={menuItem.id}>
          <MenuButton
            id={menuItem.id}
            aria-controls={menuItem.id}
            aria-haspopup="true"
            data-testid={`${menuItem.id}-button`}
            onClick={onMenuClick}
            onMouseEnter={open ? onMenuClick : undefined}
            size="small"
          >
            {menuItem.title}
          </MenuButton>
          <Menu
            data-testid={menuItem.id}
            open={Boolean(menuAnchorEl?.id === menuItem.id)}
            anchorEl={menuAnchorEl}
            keepMounted
            anchorOrigin={anchorOrigin}
            MenuListProps={{ disablePadding: true }}
            disablePortal
          >
            <div key={menuItem.id}>{menuItem.children}</div>
          </Menu>
        </Box>
      ))}
    </>
  );
};

export default MultiProjectMenu;

import { useMemo } from "react";
import * as React from "react";
import { useFormState } from "react-final-form";
import { Image } from "@mui/icons-material";
import { Box } from "@mui/material";

import { Swab } from "ui";

import { STYLE_EDITOR_MAX_ZOOM } from "fond/constants";
import { FondStyleType, MapboxKey, StyleFieldName } from "fond/types";

import { getDefaultSpecValue, getFieldSpec } from "../helper";
import { FieldValueWrapper } from "../styles";

interface IProps {
  mapboxKey: MapboxKey;
  fieldName: StyleFieldName;
  type: FondStyleType;
}

const FieldValue: React.FC<IProps> = ({ fieldName, mapboxKey, type }: IProps) => {
  const { values } = useFormState();
  const value = values[fieldName];
  const interpolateValue = values[`${fieldName}Interpolate`];
  const fieldSpec = getFieldSpec(type, mapboxKey);
  const defaultValue = useMemo(() => getDefaultSpecValue(fieldSpec, fieldName), [fieldName]);
  const hasValue = (value !== undefined && value !== null) || interpolateValue !== null;

  const getValue = () => {
    switch (fieldName) {
      case "TextRotate": {
        return `${value || defaultValue} deg`;
      }
      case "TextLineHeight":
      case "TextMaxWidth":
      case "TextLetterSpacing": {
        return `${value || defaultValue} em`;
      }
      case "CircleColor":
      case "CircleStrokeColor":
      case "FillColor":
      case "FillOutlineColor":
      case "FontIconColor":
      case "FontIconHaloColor":
      case "LineColor":
      case "TextColor":
      case "TextHaloColor": {
        return <Swab color={value || defaultValue} aria-valuetext={value || defaultValue} data-testid="swab" />;
      }
      case "CircleRadius":
      case "CircleStrokeWidth":
      case "FontIconSize":
      case "FontIconHaloWidth":
      case "LineGapWidth":
      case "LineWidth":
      case "LineOffset":
      case "TextSize":
      case "TextPadding":
      case "TextHaloBlur":
      case "TextHaloWidth":
      case "SymbolSpacing": {
        return `${value || defaultValue} px`;
      }
      case "CircleOpacity":
      case "CircleStrokeOpacity":
      case "FontIconOpacity":
      case "IconOpacity":
      case "FillOpacity":
      case "TextOpacity":
      case "LineOpacity": {
        return `${Math.floor((value !== null && value !== undefined ? value : defaultValue) * 100)}%`;
      }
      case "TextFields": {
        return value?.[0]?.Literal || (value?.[0]?.AttributeName && `Attr: ${value?.[0]?.AttributeName}`) || "None";
      }
      case "IconImageID": {
        return value ? <Image /> : "None";
      }
      case "MaxZoom": {
        return value || STYLE_EDITOR_MAX_ZOOM;
      }
      case "FontIconAllowOverlap":
      case "FontIconKeepUpright":
      case "FontIconIgnorePlacement":
      case "TextAllowOverlap":
      case "TextIgnorePlacement":
      case "IconKeepUpright":
      case "IconAllowOverlap":
      case "IconIgnorePlacement": {
        return value ? "True" : "False";
      }
      case "IconTextFitPadding":
      case "LineDasharray": {
        return value?.length > 0 ? `${value.join(", ")}` : "None";
      }
      case "IconOffset":
      case "TextOffset":
      case "CircleTranslate": {
        return `${value?.[0] || 0}x, ${value?.[1] || 0}y`;
      }
      case "FontIconRotate":
      case "IconRotate": {
        return `${value || defaultValue} deg`;
      }
      case "FontIconCode": {
        return value ? <Box sx={{ fontFamily: "fontello Regular" }}>{String.fromCharCode(value)}</Box> : "None";
      }
      default:
        return value || defaultValue;
    }
  };

  return (
    <FieldValueWrapper isDefault={!hasValue} data-testid={`${fieldName}-field-value`}>
      {interpolateValue ? <>Zoom Range</> : getValue()}
    </FieldValueWrapper>
  );
};

export default FieldValue;

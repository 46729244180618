import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";

import { Attribute, LayerId, Project, Version } from "fond/types";
import { BlockSpinner } from "fond/widgets";

import QuickComment from "../comments/QuickComment";

import FeatureAttachments from "./FeatureAttachments";
import FeaturePropertiesTable from "./FeaturePropertiesTable";

export interface IProps {
  project: Project;
  layerPropertiesSchema?: Attribute[];
  addNewComment: boolean;
  hideQuickComment(): void;
  editableProperties: string[];
  layerId: LayerId;
  version?: Version;
  featureProperties: Record<string, string | number>;
  architectureAddressTypes: string[] | null;
  setFieldValues(updates: Record<string, string | number>): void;
  lengthFields: string[];
  isFetching: boolean;
}

const PropertiesPanelContent: React.FC<IProps> = ({ project, layerPropertiesSchema, addNewComment, hideQuickComment, ...propertiesTableProps }) => {
  const [activeTab, setActiveTab] = useState<"properties" | "attachments">("properties");

  if (!layerPropertiesSchema) return <BlockSpinner />;

  if (addNewComment) return <QuickComment onCancel={hideQuickComment} onSubmit={hideQuickComment} />;

  return (
    <Box overflow="hidden" flex={1} display="flex" flexDirection="column">
      <Box borderBottom={1} borderColor="divider">
        <Tabs value={activeTab} sx={{ mx: 0.5 }}>
          <Tab label="Properties" value="properties" onClick={() => setActiveTab("properties")} />
          <Tab label="Attachments" value="attachments" onClick={() => setActiveTab("attachments")} />
        </Tabs>
      </Box>
      {activeTab === "properties" && (
        <FeaturePropertiesTable {...propertiesTableProps} project={project} layerPropertiesSchema={layerPropertiesSchema} />
      )}
      {activeTab === "attachments" && <FeatureAttachments project={project} />}
    </Box>
  );
};

export default PropertiesPanelContent;

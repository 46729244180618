import { Position } from "geojson";

import { apiSlice } from "./apiSlice";

export type BoundaryAddressCount = {
  Addresses:
    | {
        ExactCount: number;
        MinCount: null;
      }
    | {
        ExactCount: null;
        MinCount: number;
      };
};

interface BoundaryFeatureCountRequest {
  boundaries: Array<{
    id: string | number;
    geometry: {
      type: "Polygon" | "MultiPolygon";
      coordinates: Position[][] | Position[][][];
    };
  }>;
}

interface BoundaryFeatureCountResponse {
  FeatureCounts: Record<string, BoundaryAddressCount>;
}

/**
 * AreaSelect API Slice
 */
export const areaSelectSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getBoundaryFeatureCount: build.query<BoundaryFeatureCountResponse, BoundaryFeatureCountRequest>({
      query: (boundaries) => ({
        url: "/v2/area-select/count-features",
        method: "POST",
        body: boundaries,
      }),
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const { useGetBoundaryFeatureCountQuery, useLazyGetBoundaryFeatureCountQuery } = areaSelectSlice;

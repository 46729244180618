import { useMemo, useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { LoadingButton } from "ui";

import { selectLayerLabelByLayerKey, selectLayersByVersionId, useGetAccountModulesQuery, useGetLayersQuery } from "fond/api";
import DemandConfirmUpdate from "fond/architecture/demand/DemandConfirmUpdate";
import { DEMAND_MODEL_MODIFIED } from "fond/constants";
import { LayerIds } from "fond/layers";
import mixpanel from "fond/mixpanel";
import { hasLayer } from "fond/project/redux";
import { Layer, LayerWithLabel, MultiProject, Project, Store } from "fond/types";
import { getItem, setItem } from "fond/utils/localStorage";
import { accountModuleCheck, Actions } from "fond/utils/permissions";
import { BlockSpinner } from "fond/widgets";

import ArchitectureFlexNapWarningModal from "./ArchitectureFlexNapWarningModal";
import ArchitecturePanelContent from "./ArchitecturePanelContent";
import {
  closeApplyDialog,
  closeDiscardDialog,
  confirmDiscardDialog,
  copyArch,
  deleteArchitecture,
  nextTab,
  save,
  useArchitectureEditorContext,
} from "./context";

interface IProps {
  project: Project | MultiProject;
  onClose(): void;
}

const useCustomStyles = makeStyles((theme: Theme) => ({
  root: {
    "&:first-child": {
      padding: 0,
    },
  },
  dialog: {
    // Set size for the dialog so it doesn't change size when the data finishes loading.
    width: "90%",
    maxWidth: 1500,
    height: 800,
  },
  dialogContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
  },
  dialogActions: {
    justifyContent: "flex-start",
  },
  cancelButton: {
    marginLeft: "auto",
    marginRight: theme.spacing(1),
  },
  helpWidgetIcon: {
    marginTop: 2,
  },
  saveButton: {
    marginRight: theme.spacing(1),
  },
  deleteButton: {
    marginRight: theme.spacing(1),
  },
}));

const ArchitectureContent: React.FC<IProps> = ({ project, onClose }: IProps) => {
  const [
    {
      selectedArchitectureID,
      projectArchitecture,
      isLoading,
      isSaving,
      widgetArchitecture,
      archHasChanged,
      confirmReplaceProjectArch,
      confirmDiscardChanges,
      editingBomRowID,
    },
    dispatch,
  ] = useArchitectureEditorContext();
  const classes = useCustomStyles();
  const projectId = project.ID;
  const versionId = useSelector((state: Store) => state.project.versionId);
  const { data: addressData } = useSelector((state: Store) => {
    return state.project.projects[state.project.projectId] || { data: null };
  });
  const { isLoading: isLayersLoading } = useGetLayersQuery(versionId, { skip: !versionId, refetchOnMountOrArgChange: true });
  const outputLayers = useSelector((state: Store): Layer[] => (!isLayersLoading && selectLayersByVersionId(state, versionId)) || []).filter((layer) =>
    layer.LayerKey.startsWith("output/")
  );
  const layerLabelByLayerKey = useSelector((state: Store) => selectLayerLabelByLayerKey(state, versionId));
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [flexNapWarningModalOpen, setflexNapWarningModalOpen] = useState(false);
  const { data: accountModules } = useGetAccountModulesQuery(project.Account.ID);
  const canEnableFlexNap = accountModuleCheck(accountModules, Actions.ARCHITECTURE_ENABLE_FLEXNAP);
  const canEdit = !widgetArchitecture?.IsFlexNap || canEnableFlexNap;
  const hasFlexNapUpdated = widgetArchitecture?.hasUpdatedFlexNap || projectArchitecture?.IsFlexNap !== widgetArchitecture?.IsFlexNap;
  const [demandConfirmUpdateModalOpen, setDemandConfirmUpdateModalOpen] = useState(false);

  // Buttons that appear on the bottom right.
  let rightButtons;
  if (widgetArchitecture == null) {
    // If the project doesn't have an architecture, and we have the empty project architecture selected in the list, then we can't do anything but cancel.
    rightButtons = ["cancel"];
  } else if (selectedArchitectureID && selectedArchitectureID === projectArchitecture?.ID) {
    // If we're editing the project arch, we can save. The save button knows to save to the project arch.
    rightButtons = ["cancel", "save"];
  } else if (widgetArchitecture.ID == null) {
    // If we're editing a new arch, we either have Next or Save, depending on whether we've setup all the arch's tiers.
    if (widgetArchitecture.IsConfigured) {
      rightButtons = ["cancel", "save"];
    } else {
      rightButtons = ["cancel", "next"];
    }
  } else {
    // If we're editing an existing base arch, we can save it back to the base arch or apply it to the project.
    rightButtons = ["cancel", "save", "apply"];
  }

  const handleOnClose = (event: React.ChangeEvent, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason !== "backdropClick") {
      mixpanel.track("Closed architecture modal");
      onClose();
    }
  };

  const handleSave = () => {
    if (sortedOutputLayers.length > 0 && hasFlexNapUpdated) {
      setflexNapWarningModalOpen(true);
    } else {
      handleConfirmOutputRemoval();
    }
  };

  const handleConfirmOutputRemoval = () => {
    dispatch(save({ versionId: versionId, projectId: projectId, type: project.EntityType }, { needRefreshMLC: hasFlexNapUpdated }));
    setflexNapWarningModalOpen(false);
  };

  // Sort layers by label.
  const sortedOutputLayers = useMemo(() => {
    const copiedLayers = [...outputLayers];
    const layersWithLabel: LayerWithLabel[] = copiedLayers.map((item) => {
      return { ...item, Label: layerLabelByLayerKey[item.LayerKey] || item.LayerKey };
    });
    return layersWithLabel.sort((a, b) => a.Label.localeCompare(b.Label));
  }, [outputLayers]);

  const onConfirm = async () => {
    setItem(DEMAND_MODEL_MODIFIED, false);
    setDemandConfirmUpdateModalOpen(false);
    handleSave();
  };

  return (
    <Dialog
      data-testid="architecture-modal"
      className="architecture-modal"
      classes={{
        paper: classes.dialog,
      }}
      maxWidth={false} // The width of the dialog is controlled by the classes.
      open
      // Otherwise the dialog will close if you click outside, without warning, even if you have unsaved changes. Esc will still close the dialog.
      onClose={handleOnClose}
    >
      <DialogContent
        dividers
        className={classes.dialogContent}
        classes={{
          root: classes.root,
        }}
      >
        {isLoading ? <BlockSpinner /> : <ArchitecturePanelContent project={project} />}
      </DialogContent>
      <DialogActions className={classes.dialogActions} disableSpacing>
        <Button
          data-testid="delete-button"
          variant="outlined"
          className={classes.deleteButton}
          // Only enabled if:
          //   we've finished loading
          //   an arch is selected in the list
          //   that selected arch is not the arch used in the project
          //   the current user is the creator of the arch
          //
          disabled={
            isLoading || selectedArchitectureID == null || (selectedArchitectureID && selectedArchitectureID === projectArchitecture?.ID) || !canEdit
          }
          onClick={() => setDeleteDialogOpen(true)}
        >
          Delete
        </Button>
        <Button
          variant="outlined"
          data-testid="copy-button"
          onClick={() => dispatch(copyArch())}
          disabled={isLoading || widgetArchitecture == null || !canEdit}
        >
          Copy
        </Button>
        {rightButtons.map((buttonType, i) => {
          if (buttonType === "cancel") {
            return (
              <Button
                key={i}
                className={classes.cancelButton}
                data-testid="cancel-button"
                disabled={editingBomRowID != null}
                onClick={() => {
                  setItem(DEMAND_MODEL_MODIFIED, false);
                  mixpanel.track("Closed architecture modal");
                  onClose();
                }}
              >
                Cancel
              </Button>
            );
          } else if (buttonType === "next") {
            return (
              <LoadingButton
                key={i}
                color="primary"
                variant="contained"
                data-testid="next-button"
                onClick={() => dispatch(nextTab())}
                disabled={isLoading || !canEdit}
              >
                Next
              </LoadingButton>
            );
          } else if (buttonType === "save") {
            let color: ButtonProps["color"];
            let variant: ButtonProps["variant"];
            let className;
            if (widgetArchitecture.ID == null || (selectedArchitectureID && selectedArchitectureID === projectArchitecture?.ID)) {
              color = "primary";
              variant = "contained";
            } else {
              className = classes.saveButton;
              variant = "outlined";
            }
            return (
              <LoadingButton
                key={i}
                color={color}
                variant={variant}
                data-testid="use-button"
                onClick={() => {
                  if (getItem(DEMAND_MODEL_MODIFIED) && hasLayer(addressData, LayerIds.inAddress)) {
                    setDemandConfirmUpdateModalOpen(true);
                  } else if (selectedArchitectureID && selectedArchitectureID === projectArchitecture?.ID) {
                    handleSave();
                  } else {
                    dispatch(save());
                  }
                }}
                // We wanted the save button to be disabled if a base arch has no
                // changes, but people get confused if they create a new arch and
                // then hit Next Next Next and then the Save button is disabled
                // and they don't know why. So enable the save button for new
                // architectures; if the architecture is unchanged, they'll get a
                // validation error saying that the name is required. Pat also
                // asked for the save & cancel buttons to be disabled while there
                // is a BOM rule open (since BOM rules have their own save &
                // cancel buttons).
                disabled={isLoading || (widgetArchitecture.ID != null && !archHasChanged) || editingBomRowID != null || !canEdit}
                loading={isSaving}
                className={className}
              >
                Save
              </LoadingButton>
            );
          } else if (buttonType === "apply") {
            return (
              <LoadingButton
                key={i}
                color="primary"
                variant="contained"
                data-testid="apply-to-project-button"
                onClick={handleSave}
                disabled={isLoading || !canEdit}
                loading={isSaving}
              >
                Apply to project
              </LoadingButton>
            );
          } else {
            throw new Error(buttonType);
          }
        })}
      </DialogActions>
      {/* This dialog opens when a user clicks the delete button in the DialogActions. */}
      <Dialog data-testid="delete-architecture-dialog" open={deleteDialogOpen}>
        <DialogTitle>Delete architecture?</DialogTitle>
        <DialogContent>
          <DialogContentText>Other users in the account will also lose access to this architecture if you proceed.</DialogContentText>
          <DialogContentText>
            However, deleting this architecture will <strong>not</strong> affect any projects that have the architecture applied to them.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancel-architecture-delete" color="primary" onClick={() => setDeleteDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            data-testid="confirm-architecture-delete"
            color="primary"
            variant="contained"
            onClick={() => {
              setDeleteDialogOpen(false);
              dispatch(deleteArchitecture());
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <ArchitectureFlexNapWarningModal
        open={flexNapWarningModalOpen}
        outputLayers={sortedOutputLayers}
        isFlexNap={!!widgetArchitecture?.IsFlexNap}
        onClose={() => setflexNapWarningModalOpen(false)}
        onConfirm={handleConfirmOutputRemoval}
      />
      <Dialog open={!flexNapWarningModalOpen && confirmReplaceProjectArch}>
        {/* This dialog opens when a user clicks the apply to project button in the DialogActions. */}
        <DialogTitle>Replace project architecture?</DialogTitle>
        <DialogContent>
          <DialogContentText>You will lose any changes you have made to the current project architecture.</DialogContentText>
          <DialogContentText>To save any changes make a copy of the project architecture before continuing.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => dispatch(closeApplyDialog())}>
            Cancel
          </Button>
          <Button
            data-testid="replace-project-arch-ok"
            color="primary"
            variant="contained"
            onClick={() => {
              dispatch(
                save(
                  { versionId: versionId, projectId: projectId, type: project.EntityType },
                  { isConfirmed: true, needRefreshMLC: hasFlexNapUpdated }
                )
              );
            }}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDiscardChanges != null}>
        <DialogTitle>Your architecture has unsaved changes</DialogTitle>
        <DialogContent>
          <DialogContentText>Your current architecture has unsaved changes. If you proceed, they will be discarded.</DialogContentText>
          <DialogContentText>If you want to keep them, hit Cancel and save your architecture.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => dispatch(closeDiscardDialog())}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={() => dispatch(confirmDiscardDialog())}>
            Discard changes
          </Button>
        </DialogActions>
      </Dialog>
      {demandConfirmUpdateModalOpen && <DemandConfirmUpdate setModalOpen={setDemandConfirmUpdateModalOpen} onConfirm={onConfirm} />}
    </Dialog>
  );
};

export default ArchitectureContent;

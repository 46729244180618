import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Addchart } from "@mui/icons-material";
import { Alert, Box, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";

import { selectCurrentAllocation, selectFolderById, useGetAccountSubscriptionsQuery, useGetVersionStatusQuery } from "fond/api";
import mixpanel from "fond/mixpanel";
import { StatusTypes } from "fond/project/redux";
import { Project, Report, Store, SubscriptionStatus } from "fond/types";
import { useAppSelector } from "fond/utils/hooks";
import { Actions, licenseCheck, permissionCheck } from "fond/utils/permissions";
import { NavigationButton, NavigationButtonIcon } from "fond/widgets/StackedNavigation/StackedNavigation.styles";

import ReportList from "./ReportList";

interface IProps {
  reports: Report[];
  project: Project;
  versionId: string;
  readOnly?: boolean;
}

const ReportPanel: React.FC<IProps> = ({ reports, project, versionId, readOnly = false }: IProps) => {
  const navigate = useNavigate();
  const currentFolder = useSelector((state: Store) => (project?.FolderID ? selectFolderById(state, project.FolderID) : undefined));
  const currentAllocation = useAppSelector(selectCurrentAllocation);
  const { data: subscriptions } = useGetAccountSubscriptionsQuery(currentAllocation?.Account.ID ?? skipToken);
  const { data: versionStatus } = useGetVersionStatusQuery(versionId, { skip: !versionId });

  const hasActiveSubscription = subscriptions?.some((subscription) => subscription.Status === SubscriptionStatus.Active);
  const hasValidLicense = licenseCheck(currentAllocation, Actions.PROJECT_CREATE_REPORT) && hasActiveSubscription;
  const canCreateReport =
    !readOnly &&
    versionStatus?.Status === StatusTypes.Complete &&
    !versionStatus?.IsDirty &&
    permissionCheck(project?.Permission.Level, Actions.PROJECT_CREATE_REPORT) &&
    !!hasValidLicense;

  const folderId = currentFolder && permissionCheck(currentFolder.Permission.Level, Actions.FOLDER_EDIT) ? currentFolder.ID : undefined;
  const handleClick = () => {
    if (canCreateReport) {
      mixpanel.track("Create report", "Report", "Report panel");
      navigate({ pathname: "/reports/create", search: new URLSearchParams({ folderId: folderId || "", versionId: versionId }).toString() });
    }
  };

  return (
    <>
      {!canCreateReport && (
        <Box mb={1}>
          <Alert severity="warning">
            <Typography variant="body3">Complete the design step before generating a new report</Typography>
          </Alert>
        </Box>
      )}
      <Typography my={1} fontSize={14} variant="h6">
        {reports.length === 0 ? "Report not generated" : "Generate report"}
      </Typography>
      <Typography variant="body3">Generate a business case analysis based on your design.</Typography>

      <NavigationButton onClick={handleClick} checked disabled={!canCreateReport} variant="outlined">
        <Box display="flex" alignItems="center">
          <NavigationButtonIcon size="small" disabled={!canCreateReport}>
            <Addchart />
          </NavigationButtonIcon>

          <Typography variant="body3" fontWeight="500" lineHeight="1.4" display="block" color={!canCreateReport ? "grey" : "inherit"}>
            Configure report
          </Typography>
        </Box>
      </NavigationButton>

      <ReportList reports={reports} />
    </>
  );
};

ReportPanel.displayName = "ReportPanel";
export default ReportPanel;

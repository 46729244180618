import React, { PropsWithChildren, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import mapboxgl, { ScaleControl } from "mapbox-gl";

import Footer from "fond/map/Footer";
import { MapCreator, refreshTileSource, setRequestTransformations } from "fond/map/Map";
import { MapListener } from "fond/map/MapListener";
import { MapContext } from "fond/map/MapProvider";
import { loadMap, unloadMap } from "fond/map/redux";
import Ruler from "fond/map/Ruler";
import { getMapStyle } from "fond/map/styles";
import { getCurrentProject } from "fond/project";
import { updateProjectView } from "fond/project/redux";
import store from "fond/store";
import * as turf from "fond/turf";
import { ErrorEventProps, Store, SystemOfMeasurement, View, ViewLocation } from "fond/types";
import { LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { isValidBoundingBox } from "fond/utils";
import { useAppDispatch } from "fond/utils/hooks";

import MapContent from "./MapContent";

import { Container } from "../../map/BaseMap.styles";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY || "";
type BBox = [[number, number], [number, number]];

interface IProps extends PropsWithChildren {
  layerConfigs?: Array<LayerConfig | SublayerConfig>;
  styles?: LayerStyle[];
  layerView: Record<string, boolean>;
}

const Map: React.FC<IProps> = ({ children, layerConfigs, layerView, styles }: IProps) => {
  const dispatch = useAppDispatch();
  const { map, setMap, mapStyle: style } = useContext(MapContext);
  const [distance, setDistance] = useState<string | undefined>();
  const containerRef = useRef<HTMLDivElement>(null);
  const project = useSelector((state: Store) => getCurrentProject(state.project));
  let scaleControl = useRef<ScaleControl>();

  /**
   * We only access the view.location value on mount as we don't
   * want to trigger a component render every time the map changes
   * zoom or positioning.
   *
   * We ignore any changes to view.location after mount.
   */
  let location: ViewLocation | undefined;
  useEffect(() => {
    location = store.getState().project.projects[project.ID]?.view.location;
  }, []);

  let mapListener: MapListener | null = null;

  useEffect(() => {
    return () => {
      setMap(undefined);
      mapListener = null;
      dispatch(unloadMap());
    };
  }, []);

  /**
   * Once the container is rendered create the Map
   */
  useEffect(() => {
    async function makeNewMap() {
      // Once the container is rendered create the Map
      if (containerRef.current) {
        let bbox: BBox | undefined;
        if (
          location?.camera == null &&
          location?.bbox != null &&
          // Be careful we don't crash Mapbox by passing it out-of-range coordinates.
          isValidBoundingBox(location?.bbox)
        ) {
          bbox = location.bbox;
        }
        const baseStyle = getMapStyle(style);
        const newMap = await MapCreator.createMap({
          container: containerRef.current,
          view: location?.camera,
          bbox: bbox,
          style: baseStyle.url,
        });
        newMap.dragRotate.disable();
        newMap.touchZoomRotate.disableRotation();
        newMap.on("load", () => {
          setMap(newMap);
          // Our Selenium tests assume that the Map instance is available at `window.map`.
          window.map = newMap;
        });

        newMap.on("error", async ({ error, source, sourceId }: ErrorEventProps) => {
          // Refresh the token and tiles if this is a vector tile authorisation error
          if (source?.type === "vector" && error?.status === 401) {
            await setRequestTransformations(newMap);
            refreshTileSource({ map: newMap, sourceId: sourceId, source: source });
          }
        });
      }
    }

    makeNewMap();
  }, [containerRef]);

  /**
   * Handles the changing of the map view
   */
  const handleViewChange = (newView: View) => {
    dispatch(updateProjectView(project.ID, newView));
  };

  /**
   * Monitor the map for initialisation
   */
  useEffect(() => {
    // Add user position marker after the map is loaded
    const addSourceForUserPosition = () => {
      map?.addSource("location-point", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: [],
          },
        },
      });
      map?.addSource("location-area", {
        type: "geojson",
        data: turf.circle([0, 0], 1, {}),
      });
    };

    if (map) {
      dispatch(loadMap(map));
      mapListener = new MapListener(map, handleViewChange);

      if (!map.getSource("location-point") && !map.getSource("location-area")) {
        addSourceForUserPosition();
      }

      map.on("styledataloading", () => {
        map.once("styledata", () => {
          addSourceForUserPosition();
        });
      });

      // Once map has loaded we can add any required controls
      setupScaleControl(project.SystemOfMeasurement);
    }

    return () => {
      map?.off("styledataloading", addSourceForUserPosition);
    };
  }, [map]);

  /**
   * Adds & Removes the scale control to the map based on the current
   * System of Measurements
   */
  const setupScaleControl = (unit: SystemOfMeasurement) => {
    if (map) {
      if (scaleControl.current != null && map.hasControl(scaleControl.current)) {
        map.removeControl(scaleControl.current);
      }

      const newScale = new ScaleControl({ maxWidth: 500, unit: unit });
      map.addControl(newScale, "bottom-right");
      scaleControl.current = newScale;
    }
  };

  return (
    <Container ref={containerRef} data-testid="map-container" id="mapContainer">
      {map && (
        <>
          {children}
          <Footer distance={distance} />
          <Ruler onChange={setDistance} />
          {layerConfigs && styles && <MapContent layerConfigs={layerConfigs} layerView={layerView} styles={styles} />}
        </>
      )}
    </Container>
  );
};

export default Map;

import React, { useContext } from "react";
import { DrawModeChangeEvent } from "@mapbox/mapbox-gl-draw";

import { PolygonIcon } from "fond/svg_icons";

import { MapContext } from "../MapProvider";

import { IButtonProps } from "./Toolbar";
import ToolbarButton from "./ToolbarButton";

const PolygonButton: React.FC<IButtonProps> = ({ mode }: IButtonProps) => {
  const { map, drawControl } = useContext(MapContext);

  const handleOnClick = () => {
    drawControl.current.changeMode("draw_polygon");
    map?.fire("draw.modechange", { type: "draw.modechange", mode: "draw_polygon" } as DrawModeChangeEvent);
  };

  return (
    <ToolbarButton title="Draw a Polygon" onClick={handleOnClick} active={mode === "draw_polygon"}>
      <PolygonIcon />
    </ToolbarButton>
  );
};

export default PolygonButton;

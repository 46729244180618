import { useContext, useEffect, useState } from "react";
import { Portal } from "react-portal";
import { useDispatch, useSelector } from "react-redux";
import { Close, LinearScale, LocationOn, TrendingFlat } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, IconButton, Snackbar } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";

import { LoadingButton } from "ui";

import { useGetPermissionsQuery } from "fond/api";
import { MapContext } from "fond/map/MapProvider";
import { getOne, selectComment, setEditId, updateComment } from "fond/redux/comments";
import { AppThunkDispatch, Store } from "fond/types";
import { SvgIcon } from "fond/widgets";

import { beginCommenting, endCommenting } from "../redux";

const customStyles = (theme: Theme) => {
  return createStyles({
    alert: {
      boxShadow: theme.shadows[1],
    },
    close: {
      padding: theme.spacing(0.5),
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  commentId: string | undefined;
}

const CommentFeatureEditor = ({ classes, commentId }: IProps) => {
  const dispatch: AppThunkDispatch = useDispatch();
  const comment = useSelector((state: Store) => getOne(state)(commentId));
  const { projectId } = useSelector((state: Store) => state.project);
  const editId = useSelector((state: Store) => state.comments.editId);
  const { drawControl, setDrawMode } = useContext(MapContext);
  const [error, setError] = useState<string | undefined>(undefined);
  const [saving, setSaving] = useState(false);
  const { refetch: refetchPermissions } = useGetPermissionsQuery({ id: projectId, type: "project" });

  useEffect(() => {
    dispatch(beginCommenting());
    return () => {
      tearDown();
    };
  }, []);

  useEffect(() => {
    if (editId && comment.Features.length > 0) {
      setDrawMode("draw_point");

      // Remove any existing drawn features
      drawControl.current.deleteAll();

      // Add the existing feature to the draw control
      drawControl.current.add(comment.Features[0]);

      // Select the current feature we are editing
      setDrawMode("simple_select", { featureIds: [comment.Features[0].properties?.id] });
    }
  }, [editId]);

  /**
   * Handles the submission of the Edit Comment form
   */
  const handleOnCommentSubmit = async () => {
    setSaving(true);

    dispatch(
      updateComment({
        rawContent: comment.RawContent,
        importance: comment.Importance || null,
        comment: comment,
        features: drawControl.current.getAll().features,
      })
    )
      .then((newComment) => {
        refetchPermissions();
        dispatch(setEditId(null));
        // Now that the feature has changed position (potentially)
        // we need to re-select it
        dispatch(selectComment({ commentID: comment.ID, features: newComment.Features }));
      })
      .catch((e) => {
        console.error("Edit Comment Failure", e);
        setError("There was an issue updating your comment, please try again.");
        setSaving(false);
      });
  };

  const tearDown = () => {
    setDrawMode("no_feature");
    dispatch(endCommenting());
  };

  const getActions = () => (
    <>
      <Box>
        <Button color="inherit" size="small" onClick={() => dispatch(setEditId(null))}>
          Cancel
        </Button>
      </Box>
      <Box>
        <LoadingButton variant="contained" color="primary" size="small" onClick={handleOnCommentSubmit} loading={saving}>
          Submit
        </LoadingButton>
      </Box>
    </>
  );

  const { Type: type } = comment;
  return (
    <Portal>
      {(type === "point" || type === "polygon" || type === "lineString" || type === "arrowLine") && (
        <Snackbar open anchorOrigin={{ vertical: "top", horizontal: "center" }} className={classes.alert}>
          <Box>
            {type === "point" && (
              <Alert severity="info" icon={<LocationOn />} action={getActions()}>
                <AlertTitle>Pin a location</AlertTitle>
                Pin the point on the map that you would like to comment on.
              </Alert>
            )}
            {type === "polygon" && (
              <Alert severity="info" icon={<SvgIcon icon="polygon" />} action={getActions()}>
                <AlertTitle>Highlight an area</AlertTitle>
                Highlight an area on the map that you would like to comment on.
              </Alert>
            )}
            {type === "lineString" && (
              <Alert severity="info" icon={<LinearScale />} action={getActions()}>
                <AlertTitle>Draw a line</AlertTitle>
                Draw a line on the map that you would like to comment on.
              </Alert>
            )}
            {type === "arrowLine" && (
              <Alert severity="info" icon={<TrendingFlat />} action={getActions()}>
                <AlertTitle>Draw an arrow</AlertTitle>
                Draw an arrow on the map that you would like to comment on.
              </Alert>
            )}
          </Box>
        </Snackbar>
      )}
      {error && (
        <Snackbar open anchorOrigin={{ vertical: "top", horizontal: "center" }} className={classes.alert}>
          <Alert
            severity="error"
            action={
              <IconButton aria-label="close" color="inherit" className={classes.close} onClick={() => setError(undefined)} size="large">
                <Close />
              </IconButton>
            }
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </Portal>
  );
};

export default withStyles(customStyles)(CommentFeatureEditor);

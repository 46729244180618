/* eslint react/forbid-prop-types: 0 */
import { Fragment, useState } from "react";
import { DragHandle as DragHandleIcon, MoreVert as MoreVertIcon } from "@mui/icons-material";
import { Button, Divider, FormControl, IconButton, InputAdornment, Menu, MenuItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import PropTypes from "prop-types";

import { cancelRule, deleteRule, editRule, saveRule, updateMSTRow, updateRule } from "fond/architecture/bom/state";
import { BOMTagAutocomplete, IntegerRange, MultiSelect, Numeric, Range, Text } from "fond/architecture/bom/widgets";
import { ValidationMessage } from "fond/form";
import { ChipInput } from "fond/form/fields";
import { formatUnit, getIn, setIn } from "fond/utils";

import { useArchitectureEditorContext } from "../context";

const useStyles = makeStyles((theme) => {
  return {
    heading: {
      marginTop: "1em",
      marginBottom: "1em",
    },

    columns: {
      display: "flex",
      width: "100%",
      alignItems: "start",
      backgroundColor: theme.palette.common.white,
      paddingTop: theme.spacing(1),
    },

    movingColumn: {
      display: "flex",
      width: "100%",
      alignItems: "start",
      border: "2px dashed",
      borderColor: theme.palette.primary.main,
      borderRadius: 4,
      // BackgroundColor is lightened theme.palette.primary.main
      backgroundColor: "hsla(207, 90%, 97%, 1)",
      paddingLeft: theme.spacing(3),
      paddingBottom: 2,
    },

    dragColumn: {
      flex: "0 0 5%",
      paddingTop: theme.spacing(2),
    },

    idColumn: {
      flex: "0 0 15%",
    },

    descriptionColumn: {
      flex: "1 0",
      marginLeft: theme.spacing(1),
    },

    categoryColumn: {
      flex: "0 0 15%",
      marginLeft: theme.spacing(1),
    },

    costColumn: {
      flex: "0 0 18%",
      marginLeft: theme.spacing(1),
    },

    editColumn: {
      flex: `0 1 ${theme.spacing(1.5 + 3 + 1.5)}`, // the paddings + size of the IconButton
      marginLeft: theme.spacing(1),
    },

    ruleEditorButtons: {
      textAlign: "right",
      width: "100%",
    },
    numberOfCablesHeading: {
      marginTop: "1em",
    },
    dragIcon: {
      color: theme.palette.action.active,
    },
  };
});

export function RuleEditor({ template, arch, categoryID, rule, ruleIndex, expanded, onEditRow, dispatch, validationResults = {}, isDragging }) {
  const classes = useStyles();

  const editRow = () => {
    dispatch(editRule(rule));
    onEditRow(rule.ID);
  };

  let disableMSTSave = false;

  if (categoryID === "connectorized-drop-hubs" && (!rule.Parameters.t1Hubs.length || !rule.Parameters.tailLengths.length)) {
    disableMSTSave = true;
  }

  if (template != null && template.ID === "mst/auto" && !expanded) {
    return (
      <MSTRows
        value={rule}
        categoryID={categoryID}
        onEdit={editRow}
        onDelete={() => dispatch(deleteRule(rule, categoryID))}
        dispatch={dispatch}
        ruleIndex={ruleIndex}
        template={template}
        validationResults={validationResults}
        isDragging={isDragging}
      />
    );
  }

  const basicParams = (
    <BasicParams
      makeConnector={(path) => {
        return {
          value: getIn(rule, path),
          onChange: (val) => {
            dispatch(updateRule(ruleIndex, setIn(rule, path, val), categoryID));
          },
        };
      }}
      onDelete={() => dispatch(deleteRule(rule, categoryID))}
      onEdit={editRow}
      expanded={expanded}
      validationResults={validationResults}
      isLength={template.IsLength}
      template={template}
      isDragging={isDragging}
    />
  );

  return (
    <div data-testid="rule">
      {expanded ? (
        <>
          {/*
          For the auto MST widget, the basic params are not used, because
          each row generated by the widget has its own basic params
        */}
          {template.ID !== "mst/auto" && basicParams}

          <Typography
            variant="h6"
            data-testid="heading"
            className={
              template.ID === "cable-installation-aer/restrict-by-number" || template.ID === "cable-installation-ug/restrict-by-number"
                ? classes.numberOfCablesHeading
                : classes.heading
            }
          >
            {template.Heading}
          </Typography>

          <TemplateParams
            template={template}
            arch={arch}
            onChange={(newRule) => dispatch(updateRule(ruleIndex, newRule, categoryID))}
            makeConnector={(path) => {
              if (path.length !== 2 || path[0] !== "Parameters") {
                throw new Error("Expected `path` to be ['Parameters', <something>]");
              }
              return {
                value: getIn(rule, path),
                onChange: (val) => {
                  dispatch(updateRule(ruleIndex, setIn(rule, path, val), categoryID));
                },
              };
            }}
            validationResults={validationResults.Parameters}
          />

          <div className={classes.ruleEditorButtons}>
            <Button
              onClick={() => {
                dispatch(cancelRule(rule, categoryID));
                onEditRow(null);
              }}
              data-testid="cancel-button"
              size="small"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                dispatch(saveRule(ruleIndex, categoryID));
                onEditRow(null);
              }}
              color="primary"
              data-testid="save-button"
              size="small"
              style={{ marginLeft: 8 }}
              disabled={disableMSTSave}
            >
              Apply & close rule
            </Button>
          </div>
        </>
      ) : (
        basicParams
      )}
    </div>
  );
}

RuleEditor.propTypes = {
  template: PropTypes.object,
  arch: PropTypes.object.isRequired,
  categoryID: PropTypes.string.isRequired,
  rule: PropTypes.object.isRequired,
  ruleIndex: PropTypes.number.isRequired,
  expanded: PropTypes.bool,
  onEditRow: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  validationResults: PropTypes.object,
  isDragging: PropTypes.bool,
};

RuleEditor.defaultProps = {
  template: null,
  expanded: false,
};

export function ColumnHeaders() {
  const classes = useStyles();

  return (
    <div className={classes.columns}>
      <div className={classes.dragColumn} />
      <div className={classes.idColumn}>ID</div>

      <div className={classes.descriptionColumn}>Description</div>
      <div className={classes.categoryColumn}>Category</div>

      <div className={classes.costColumn}>Cost</div>

      <div className={classes.editColumn} />
    </div>
  );
}

/*
 * Simple wrapper around ValidationMessage to only render it when
 * there is an error in validationResults for the path.
 */
function ConditionalValidationMessage({ validationResults = {}, path }) {
  const validationResult = getIn(validationResults, path);
  return (
    validationResult != null && (
      <div>
        <ValidationMessage state={validationResult.state} message={validationResult.message} />
      </div>
    )
  );
}

ConditionalValidationMessage.propTypes = {
  validationResults: PropTypes.object.isRequired,
  path: PropTypes.array.isRequired,
};

/*
 * Widget containing the ID, Descrription and Cost params for a row.
 */
export function BasicParams({ makeConnector, expanded, onEdit, onDelete, isLength, template, validationResults = {}, isDragging }) {
  const classes = useStyles();

  const [menuExpanded, setMenuExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [state] = useArchitectureEditorContext();

  return (
    <>
      {expanded && <ColumnHeaders />}
      <div className={isDragging ? classes.movingColumn : classes.columns}>
        <div className={classes.dragColumn}>
          {template != null && template.ID !== "mst/auto" && !expanded && <DragHandleIcon className={classes.dragIcon} data-testid="drag-handle" />}
        </div>

        <div className={classes.idColumn}>
          <Text {...makeConnector(["RowID"])} data-t-input="id" size="small" error={validationResults.RowID != null} />
          <ConditionalValidationMessage validationResults={validationResults} path={["RowID"]} />
        </div>

        <div className={classes.descriptionColumn}>
          <Text {...makeConnector(["Description"])} size="small" style={{ width: "100%" }} data-t-input="description" />
        </div>

        <div className={classes.categoryColumn}>
          <BOMTagAutocomplete {...makeConnector(["Tags"])} data-t-input="tags" />
        </div>

        <div className={classes.costColumn}>
          <Numeric
            {...makeConnector(["Cost"])}
            data-t-input="cost"
            error={validationResults.Cost != null}
            size="small"
            endAdornment={
              isLength ? <InputAdornment position="end">{state.systemOfMeasurement === "imperial" ? "per foot" : "per meter"}</InputAdornment> : null
            }
          />
          <ConditionalValidationMessage validationResults={validationResults} path={["Cost"]} />
        </div>

        <div className={classes.editColumn}>
          {!expanded && (
            <IconButton
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setMenuExpanded(true);
              }}
              data-testid="menu"
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </div>

        <Menu anchorEl={anchorEl} open={menuExpanded} onClose={() => setMenuExpanded(false)} data-testid="rule-menu">
          <MenuItem onClick={onEdit} data-testid="edit-button">
            Edit
          </MenuItem>
          <MenuItem onClick={onDelete} data-testid="delete-button">
            Delete
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}

BasicParams.propTypes = {
  makeConnector: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isLength: PropTypes.bool,
  validationResults: PropTypes.object,
  template: PropTypes.object,
  isDragging: PropTypes.bool,
};

BasicParams.defaultProps = {
  expanded: false,
  isLength: false,
};

/**
 * A mapping of parameter `Type`s to components. Each component should take
 * `value` and `onChange` props. `TemplateParams` will provide the appropriate
 * `value` for each parameter and hook up the `onChange` to update the
 * respective field.
 */

function RangeAdapter({ value, onChange, error, ...props }) {
  return <Range value={value} onChange={onChange} NumericInputProps={{ error: error }} {...props} />;
}
RangeAdapter.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
};
RangeAdapter.defaultProps = {
  error: false,
};

function IntegerRangeAdapter({ value, onChange, error, ...props }) {
  return <IntegerRange value={value} onChange={onChange} NumericInputProps={{ error: error }} {...props} />;
}
IntegerRangeAdapter.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
};
IntegerRangeAdapter.defaultProps = {
  error: false,
};

function NumericChipsAdapter({ value, onChange, systemOfMeasurement, ...props }) {
  return <ChipInput values={value} onCommit={onChange} {...props} />;
}
NumericChipsAdapter.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  systemOfMeasurement: PropTypes.string.isRequired,
};

const paramWidgets = {
  MultiSelect: MultiSelect,
  Numeric: Numeric,
  Range: RangeAdapter,
  IntegerRange: IntegerRangeAdapter,
  TextInput: Text,
  // A LabelRow is *just* a label, it doesn't have any other widget.
  LabelRow: () => null,

  NumericChips: NumericChipsAdapter,
};

const useTemplateParamsStyles = makeStyles((theme) => ({
  root: {
    "& + &": {
      marginTop: theme.spacing(3),
      display: "flex",
      alignItems: "center",
    },
  },
  formControl: {
    width: "100%",
  },
  label: {
    marginBottom: theme.spacing(0.5),
  },
  paramWidgetContainer: {
    maxWidth: 600,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

/**
 * Render the template-specific parameters for a rule.
 */
function TemplateParams({ template, arch, onChange, makeConnector, validationResults = {} }) {
  const classes = useTemplateParamsStyles();
  const [state] = useArchitectureEditorContext();

  return (
    <>
      {template.Parameters.map((parameter, i) => {
        const ParamWidget = paramWidgets[parameter.Type];
        if (ParamWidget == null) {
          throw new Error(`No parameter widget for type ${parameter.Type}`);
        }

        return (
          <Fragment key={i}>
            <div data-testid="parameter" className={classes.root}>
              <FormControl className={classes.formControl}>
                <label className={classes.label}>
                  <Typography variant="body2">{parameter.Label}</Typography>
                </label>

                <div className={classes.paramWidgetContainer}>
                  <ParamWidget
                    architecture={arch}
                    widgetSettings={parameter}
                    endAdornment={parameter.IsLength ? <InputAdornment position="end">{formatUnit(state.systemOfMeasurement)}</InputAdornment> : null}
                    {...makeConnector(["Parameters", parameter.ID])}
                    error={validationResults[parameter.ID] != null}
                    systemOfMeasurement={state.systemOfMeasurement}
                  />
                  <ConditionalValidationMessage validationResults={validationResults} path={[parameter.ID]} />
                </div>
              </FormControl>
            </div>
            {i < template.Parameters.length - 1 && <Divider className={classes.divider} />}
          </Fragment>
        );
      })}
    </>
  );
}

TemplateParams.propTypes = {
  template: PropTypes.object.isRequired,
  arch: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  makeConnector: PropTypes.func.isRequired,
  validationResults: PropTypes.object,
};

function MSTRows({ value, categoryID, onEdit, onDelete, dispatch, ruleIndex, template, validationResults = {} }) {
  return (
    <>
      {(value.Rows || []).map((mstRow, mstRowIndex) => {
        const rowValidationResults = _.get(validationResults, ["Rows", mstRowIndex]);

        return (
          <div data-testid="rule" key={mstRowIndex}>
            <BasicParams
              makeConnector={(path) => {
                return {
                  value: getIn(mstRow, path),
                  onChange: (val) => {
                    dispatch(updateMSTRow(ruleIndex, mstRowIndex, setIn(mstRow, path, val), categoryID));
                  },
                };
              }}
              expanded={false}
              onEdit={onEdit}
              onDelete={onDelete}
              validationResults={rowValidationResults}
              template={template}
            />
          </div>
        );
      })}
    </>
  );
}

MSTRows.propTypes = {
  value: PropTypes.shape({
    Rows: PropTypes.array.isRequired,
  }).isRequired,
  categoryID: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  ruleIndex: PropTypes.number.isRequired,
  validationResults: PropTypes.object,
  template: PropTypes.object,
};

import React from "react";
import { ColorResult, TwitterPicker } from "react-color";
import { Paper } from "@mui/material";

import { layerPalette } from "fond/utils/colors";

interface AreaFormColorPickerProps {
  value: string;
  onChange(color: string): void;
}

const AreaFormColorPicker: React.FC<AreaFormColorPickerProps> = ({ value, onChange }: AreaFormColorPickerProps) => {
  const handleOnChange = (result: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event.type === "change" && event.target.value.length === 6) || event.type === "click") {
      onChange(result.hex);
    }
  };

  return (
    <Paper sx={{ p: 1 }}>
      <TwitterPicker
        width="205px"
        triangle="hide"
        color={value}
        onChangeComplete={handleOnChange}
        styles={{
          default: {
            card: { boxShadow: "none" },
            input: { width: 134 },
          },
        }}
        colors={layerPalette}
      />
    </Paper>
  );
};

export default AreaFormColorPicker;

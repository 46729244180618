import React, { useContext } from "react";
import { Undo } from "@mui/icons-material";
import { Button, Divider, Typography } from "@mui/material";
import { FeatureCollection, Polygon } from "geojson";

import { MapContext } from "fond/map/MapProvider";
import { isAnyPolygon } from "fond/types/geojson";

import { AreaErrorType } from "../helper";

import { SubareaProperties } from "./AreaDrawPanel";
import AreaForm from "./AreaForm";

interface AreaListProps {
  errors: Record<string, AreaErrorType[]> | undefined;
  onChange(id: string): void;
  onReset(): void;
}

const AreaList: React.FC<AreaListProps> = ({ errors, onChange, onReset }: AreaListProps) => {
  const { map, drawControl } = useContext(MapContext);

  if (!map?.hasControl(drawControl.current)) return null;

  return (
    <>
      <Typography fontWeight="500" sx={{ my: 2 }}>
        Subareas
      </Typography>
      {(drawControl.current?.getAll() as FeatureCollection<Polygon, SubareaProperties>).features.filter(isAnyPolygon).map(({ properties }) => {
        if (properties) {
          const { id } = properties;
          return <AreaForm key={id} properties={properties} onChange={onChange} errors={errors?.[id]} />;
        }
        return null;
      })}
      <Divider sx={{ mt: 2 }} />
      <Button fullWidth sx={{ my: 0.5, justifyContent: "left" }} startIcon={<Undo sx={{ height: 20 }} />} onClick={onReset}>
        <Typography color={(theme) => theme.palette.common.black} fontWeight={500} fontSize={13}>
          Reset
        </Typography>
      </Button>
      <Divider />
    </>
  );
};

export default AreaList;

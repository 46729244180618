import React from "react";
import { useParams } from "react-router";

import useEntityViews from "fond/hooks/useEntityViews";
import mixpanel from "fond/mixpanel";
import { PageContent } from "fond/widgets";
import SkeletonLoadingGrid from "fond/widgets/SkeletonLoadingGrid";

import { DEFAULT_REPORT_LOADING_SKELETON, Page } from "../constants";
import { usePollReportStatusHandle, useReportData } from "../hooks";
import ReportNavigation from "../Navigation";

import ReportRevenuePageContent from "./ReportRevenuePageContent";

const ReportPage: React.FC = () => {
  useEntityViews();
  const { reportId } = useParams<"reportId">();

  const { report, isLoading } = useReportData(reportId);
  usePollReportStatusHandle(report);

  if (reportId) {
    mixpanel.track("Report", "Revenue", "Revenue viewed", { reportId: reportId });
  }

  return (
    <PageContent
      title="Revenue"
      mainContent={
        !isLoading && report ? <ReportRevenuePageContent report={report} /> : <SkeletonLoadingGrid items={DEFAULT_REPORT_LOADING_SKELETON} />
      }
      navigation={<ReportNavigation page={Page.REVENUE} />}
      testId="report-revenue-page"
    />
  );
};

export default ReportPage;

import * as React from "react";
import { useSelector } from "react-redux";
import { Autocomplete, TableCell, TextField } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { selectCurrentAllocation, useGetAccountSubscriptionsQuery } from "fond/api";
import { getAddressTypeValues } from "fond/project/addressTypes/helper";
import { DemandConfiguration, SubscriptionStatus } from "fond/types";
import { Actions, licenseCheck } from "fond/utils/permissions";

import { PropertyNameCell, TableRow } from "./propertiesPanel.styles";

interface IProps {
  /**
   * A collection of Address Types specified in the architectures demand model
   */
  addressTypes: string[] | null;
  /**
   * The Demand Configuration used to suggest Address Type options to the user.
   */
  demandConfigurations?: DemandConfiguration[];
  addressTypeField?: string | null;
  /**
   * The current feature properties being displayed
   */
  properties: Record<string, any>;
  /**
   * Callback function that handles the change in value
   */
  onChange(values: Record<string, string | number>): void;
}

const AddressPreferenceEdit: React.FC<IProps> = ({
  addressTypes,
  demandConfigurations,
  addressTypeField: typeField,
  properties,
  onChange,
}: IProps) => {
  const currentAllocation = useSelector(selectCurrentAllocation);
  const { data: subscriptions } = useGetAccountSubscriptionsQuery(currentAllocation?.Account.ID ?? skipToken);
  const hasActiveSubscription = subscriptions?.some((subscription) => subscription.Status === SubscriptionStatus.Active);
  // we only check license as properties aren't shown to users without permissions
  const hasValidLicense = licenseCheck(currentAllocation, Actions.PROJECT_EDIT) && hasActiveSubscription;
  const isEditable = hasValidLicense && addressTypes;
  const addressTypeField: string = typeField || "AddressType";
  const handleOnChange = (value: string | null) => {
    if (value === null) return;

    const updates: { NumFibers?: number; Type?: string; SubTypes?: string } = {
      [addressTypeField]: value,
    };

    if (demandConfigurations) {
      const demandModelValues = getAddressTypeValues(demandConfigurations, value);
      if (!demandModelValues) return;

      updates.NumFibers = demandModelValues.NumFibers;
      updates.Type = demandModelValues.Type;
      updates.SubTypes = demandModelValues.SubTypes;
    }

    onChange(updates);
  };

  return (
    <TableRow data-testid="feature-property-addresstype">
      <PropertyNameCell>{addressTypeField}</PropertyNameCell>
      <TableCell>
        {isEditable ? (
          <Autocomplete
            size="small"
            value={properties[addressTypeField]}
            onChange={(event, newValue) => {
              handleOnChange(newValue);
            }}
            blurOnSelect
            options={addressTypes || []}
            renderInput={(params) => <TextField {...params} size="small" sx={{ maxWidth: 150, background: "white" }} />}
          />
        ) : (
          properties.AddressType
        )}
      </TableCell>
    </TableRow>
  );
};

export default AddressPreferenceEdit;

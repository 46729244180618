import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

import { NO_PHASE } from "fond/constants";
import { FullReport, OkResponse, Report, ReportConfiguration as TReportConfiguration, SchedulesDataItem, SomeRequired, Store } from "fond/types";
import { searchCollectionByKey } from "fond/utils/search";

import { apiSlice } from "./apiSlice";

export type GetReportsResponse = {
  Reports: Report[];
};

export const reportsAdapter = createEntityAdapter<Report>({
  selectId: (entity: Report): string => entity.ID,
});
const reportsInitialState = reportsAdapter.getInitialState();

/**
 * Transform the get report response:
 * 1. Filter out the "All" or "Horizon" or both Phases that we don't know how to process yet.
 * 2. Add "NO_PHASE" to the end of the BuildOrderMap.Phases if CoveragePassings is not 1(100%).
 */
export const transformGetReportResponse = (response: FullReport): FullReport => {
  const { Schedules, CoveragePassings, BuildOrderMap, ...rest } = response;
  const excludePhase = (schedule: SchedulesDataItem) => !["Horizon", "All"].includes(schedule.Phase);
  const excludeAllPhase = (schedule: SchedulesDataItem) => schedule.Phase !== "All";
  const report: FullReport = {
    ...rest,
    CoveragePassings: CoveragePassings,
    Schedules: Schedules
      ? {
          Hhp: Schedules.Hhp.filter(excludePhase),
          Hhc: Schedules.Hhc.filter(excludePhase),
          Revenue: Schedules.Revenue.filter(excludeAllPhase),
          Cost: Schedules.Cost.filter(excludePhase),
          Cashflow: Schedules.Cashflow.filter(excludeAllPhase),
        }
      : Schedules,
    BuildOrderMap: {
      ...BuildOrderMap,
      Phases: CoveragePassings !== 1 && BuildOrderMap?.Phases ? [...BuildOrderMap.Phases, NO_PHASE] : BuildOrderMap?.Phases,
    },
  };
  return report;
};

/**
 * Reports API Slice
 */
export const reportSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getReport: build.query<FullReport, string>({
      query: (reportId) => `/v2/reports/${reportId}`,
      providesTags: (result) => (result ? [{ type: "Report", id: result.ID }] : []),
      transformResponse: transformGetReportResponse,
    }),
    getReports: build.query({
      query: () => "/v2/reports",
      transformResponse: (response: GetReportsResponse) => reportsAdapter.setAll(reportsInitialState, response.Reports),
      providesTags: (result) =>
        result
          ? [...result.ids.map((id) => ({ type: "Report" as const, id: id })), { type: "Report", id: "LIST" }]
          : [{ type: "Report", id: "LIST" }],
    }),
    createReport: build.mutation<
      Report,
      {
        Name: string;
        Description: string;
        FolderID: string | null;
        VersionID: string;
        ReportConfiguration: Partial<TReportConfiguration>;
      }
    >({
      query: ({ Name, Description, FolderID, VersionID, ReportConfiguration }) => ({
        url: "/v2/reports",
        method: "POST",
        body: {
          Name,
          Description,
          FolderID,
          VersionID,
          ReportConfiguration,
        },
      }),
      invalidatesTags: [{ type: "Report", id: "LIST" }],
    }),
    createServicesReport: build.mutation<
      Report,
      {
        Name: string;
        Description: string;
        FolderID: string | null;
        VersionID: string;
      }
    >({
      query: (args) => ({
        url: "/v2/reports",
        method: "POST",
        body: {
          ...args,
          Type: "financial_analytics_imported",
        },
      }),
      invalidatesTags: [{ type: "Report", id: "LIST" }],
    }),
    generateReport: build.mutation<FullReport, string>({
      query: (reportId) => ({
        url: `/v2/reports/${reportId}/generate`,
        method: "POST",
      }),
      onQueryStarted: async (reportId, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(reportSlice.util.upsertQueryData("getReport", reportId, data));
        } catch {
          // Do nothing
        }
      },
    }),
    importReport: build.mutation<FullReport, { reportId: string; formData: FormData }>({
      query: ({ reportId, formData }) => ({
        url: `/v2/reports/${reportId}/import`,
        method: "POST",
        body: formData,
        formData: true,
      }),
      onQueryStarted: async ({ reportId }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(reportSlice.util.upsertQueryData("getReport", reportId, data));
        } catch {
          // Do nothing
        }
      },
    }),
    updateReport: build.mutation<Report, SomeRequired<FullReport, "ID">>({
      query: ({ ID, ...reportData }) => ({
        url: `/v2/reports/${ID}`,
        method: "PATCH",
        body: reportData,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Report", id: arg.ID }],
      onQueryStarted: async ({ ID, ...reportData }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          reportSlice.util.updateQueryData("getReports", ID, (draft) => {
            Object.assign(draft, reportData);
          })
        );
        const reportsPatchResult = dispatch(
          reportSlice.util.updateQueryData("getReports", undefined, (reports) => {
            const reportToUpdate = reports.entities[ID];
            if (reportToUpdate) {
              reportsAdapter.updateOne(reports, { id: ID, changes: reportData });
            }
          })
        );
        queryFulfilled
          .then(({ data }) => {
            dispatch(
              reportSlice.util.updateQueryData("getReport", ID, (draft: FullReport) => {
                Object.assign(draft, data);
              })
            );
          })
          .catch(() => {
            patchResult.undo();
            reportsPatchResult.undo();
          });
      },
    }),

    deleteReport: build.mutation<OkResponse, SomeRequired<Report, "ID">>({
      query: ({ ID, ...report }) => ({
        url: `/v2/reports/${ID}`,
        method: "DELETE",
        body: report,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Report", id: arg.ID }],
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const {
  useCreateReportMutation,
  useCreateServicesReportMutation,
  useGenerateReportMutation,
  useGetReportsQuery,
  useLazyGetReportsQuery,
  useGetReportQuery,
  useLazyGetReportQuery,
  useUpdateReportMutation,
  useDeleteReportMutation,
  useImportReportMutation,
} = reportSlice;

/**
 * Selectors
 */
const selectReportsResult = reportSlice.endpoints.getReports.select(undefined);
const selectReportsData = createSelector(selectReportsResult, (result) => result.data);

export const { selectAll: selectAllReports, selectById: selectReportById } = reportsAdapter.getSelectors(
  (state: Store) => selectReportsData(state) ?? reportsInitialState
);

/**
 * Returns all reports with the specified ParentID
 */
export const selectReportsByParentId = createSelector(
  [selectAllReports, (state: Store, parentId: string | null | undefined) => parentId],
  (reports, parentId): Report[] => {
    return reports.filter((report) => (parentId === undefined && !report.Folder?.ID) || report.Folder?.ID === parentId);
  }
);

export const selectReportsMatchingSearchKey = createSelector(
  [selectAllReports, (_: Store, searchKey: string) => searchKey],
  (reports, searchKey): Report[] => {
    return searchCollectionByKey(reports, searchKey, "Name");
  }
);

export const selectReportsByVersionId = createSelector(
  [selectAllReports, (_: Store, versionId: string | null | undefined) => versionId],
  (reports, versionId): Report[] => {
    return reports.filter((report) => report.Version?.ID === versionId);
  }
);

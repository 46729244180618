import { Button as MuiButton, styled } from "@mui/material";

export const Button = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active?: boolean }>(({ active = false, disabled, theme }) => ({
  width: 32,
  height: 32,
  minWidth: 32,
  border: "none",
  padding: 0,
  color: active ? "white" : "black",
  backgroundColor: active ? theme.palette.primary.main : "none",
  marginLeft: 1,
  marginRight: 1,
  "& svg": {
    width: 20,
  },
  "&:hover": {
    backgroundColor: active ? theme.palette.primary.main : "none",
  },
}));

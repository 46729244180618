import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Description, Folder as FolderIcon, FolderShared, InsertChart, InsertDriveFileOutlined, LocationCity } from "@mui/icons-material";
import { Typography } from "@mui/material";

import { selectCurrentAccount } from "fond/api";
import { Folder, ProjectPreview, Report, Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";

import { clearSharedAccount, updateFilter, updateSharedAccount } from "../redux";

import { ListItemButton, RowName } from "./Search.styles";

interface IProps extends React.HTMLAttributes<HTMLLIElement> {
  option: (ProjectPreview & { path: string[] }) | Folder | (Report & { path: string[] });
  onClick: () => void;
}

const SearchSuggestionsRow: React.FC<IProps> = ({ option, onClick, ...props }) => {
  const currentUsername = useSelector((state: Store) => state.cognito.user?.username);
  const currentAccount = useSelector(selectCurrentAccount);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleFolderClick = (id: string) => () => {
    const optionInCurrentAccount = option.Account.ID === currentAccount?.ID;
    dispatch(optionInCurrentAccount ? clearSharedAccount : updateSharedAccount(option.Account.ID));
    dispatch(updateFilter(optionInCurrentAccount ? "workspace" : "shared"));

    onClick();
    navigate(`/folders/${id}`);
  };

  if (option.EntityType === "project") {
    return (
      <ListItemButton key={option.ID} data-testid="project-search-suggestion" onClick={() => navigate(`/project/${option.ID}`)} {...props}>
        <div>
          {option.HasCustomLayerConfig ? (
            <InsertDriveFileOutlined color="primary" data-testid="project-custom-layer-icon" />
          ) : (
            <Description color="primary" data-testid="project-icon" />
          )}
          <RowName variant="h6">{option.ProjectName}</RowName>
        </div>
        <Typography variant="caption">{[option.Account.Name, ...option.path].join(" > ")}</Typography>
      </ListItemButton>
    );
  }

  if (option.EntityType === "report") {
    return (
      <ListItemButton key={option.ID} data-testid="project-search-suggestion" onClick={() => navigate(`/reports/${option.ID}`)} {...props}>
        <div>
          <InsertChart color="tertiary" data-testid="report-icon" />
          <RowName variant="h6">{option.Name}</RowName>
        </div>
        <Typography variant="caption">{[option.Account.Name, ...option.path].join(" > ")}</Typography>
      </ListItemButton>
    );
  }

  if (option.EntityType === "folder" && option.MultiProject) {
    return (
      <ListItemButton key={option.ID} data-testid="city-planner-search-suggestion" onClick={() => navigate(`/cities/${option.ID}`)} {...props}>
        <div>
          <LocationCity color="primary" data-testid="city-planner-icon" />
          <RowName variant="h6">{option.Name}</RowName>
        </div>
      </ListItemButton>
    );
  }

  return (
    <ListItemButton key={option.ID} data-testid="folder-search-suggestion" onClick={handleFolderClick(option.ID)} {...props}>
      <div>
        {option.Permission.Level === "manage" && option.Creator === currentUsername ? (
          <FolderIcon color="secondary" data-testid="owner-folder-icon" />
        ) : (
          <FolderShared color="secondary" data-testid="shared-folder-icon" />
        )}
        <RowName variant="h6">{option.Name}</RowName>
      </div>
    </ListItemButton>
  );
};

export default SearchSuggestionsRow;

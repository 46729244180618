import { createContext, useCallback, useContext, useEffect, useReducer, useRef } from "react";
import { useDispatch, useStore } from "react-redux";
import { cloneDeep, get, pick, set } from "lodash";
import PropTypes from "prop-types";

import { apiSlice, multiProjectsSlice, versionsSlice } from "fond/api";
import { architecturesSlice, selectAllArchitectures, selectArchitectureById } from "fond/api/architecturesSlice";
import { bomSlice } from "fond/api/bomSlice";
import { convertBomValuesToFeet, sanitizeBom, validate as validateBOM } from "fond/architecture/bom/state";
import mixpanel from "fond/mixpanel";
import { addNotification } from "fond/notifications/redux";
import { closeArchitectureModal } from "fond/project/redux";
import { FlexNapFeature } from "fond/types";
import { convertFeetToMeters, enumerate, getIn, iterItems, metersToFeetDisplay, setIn } from "fond/utils";
import { accountModuleCheck, Actions } from "fond/utils/permissions";

import { getPreferredRules } from "./architecturePreferredRules";
import { applyBomRestrictions } from "./bomRules";
import { getDefaultArchBOM } from "./defaultArch";
import { fieldRules as baseFieldRules } from "./fieldRules";

/**
 * We have two architecture representations:
 *
 * - The "UI" architecture
 *   - This is how architectures look when they are saved. A UI architecture
 *     conforms to the schema in `ui_architecture_schema.json`.
 * - The "Widget" architecture
 *   - This is how architectures look to the architecture editor while it is
 *     editing them. Structurally it's the same as the UI architecture but the
 *     field types match how the UI widgets work rather than matching the desired
 *     output format. For example numeric values that are drawn as text inputs
 *     will be strings. Lists of numbers that are (currently) also drawn as text
 *     inputs are also strings (eg. "1,2,3,4").
 *
 * We use widgetRepresentation.fromArchitecture(arch) to transform a UI arch
 * to a widget arch, and widgetRepresentation.toArchitecture(arch) to transform
 * a widget arch to a UI arch.
 *
 * `fieldType` just exists to facilitate the transformations.
 */
const spareType = {
  AbsoluteValue: "number",
  PercentValue: "number",
};

const topologyType = {
  ParallelCableThreshold: "length",
  LoopingCableThreshold: "length",
};

const fieldTypes = {
  Name: "string",
  Description: "string",
  Tier1: {
    Cables: {
      Sizes: "list_of_numbers",
    },
    Hubs: {
      Sizes: "list_of_numbers",
      Spare: spareType,
      Type: "string",
    },
    DropRules: {
      DropLength: "length",
      PolesPerDrop: "number",
      PitsPerDrop: "number",
    },
  },
  Tier2: {
    ConnectorizedDropTerminals: {
      MaxTailLength: "length",
      TailsPerSplice: "number",
    },
    Cables: {
      Sizes: "list_of_numbers",
      Spare: spareType,
      Type: "string",
    },
    Hubs: {
      Sizes: "list_of_numbers",
      Spare: spareType,
      UnsplitPorts: "number",
    },
    Topology: topologyType,
  },
  Tier3: {
    Cables: {
      Sizes: "list_of_numbers",
      Spare: spareType,
    },
    Topology: topologyType,
    Hubs: {
      Sizes: "list_of_numbers",
    },
  },
};

/**
 * Note about strings:
 *
 * We can't store empty strings in dictionaries in DynamoDB, and the Material
 * UI text fields (perhaps React text fields in general) don't like being
 * passed null values, so we turn null strings into empty strings. Currently
 * Name and Description are the only "string" values that can ever be null (and
 * Name can only be null on initial creation, it should not be possible to save
 * an architecture without a Name).
 *
 * Possibly it could make more sense to do this conversion on the server, but
 * we already have a tool for transforming arch objects like this here anyway.
 */

export const widgetRepresentation = {
  toArchitecture: (state, systemOfMeasurement, { fieldType = fieldTypes, bomCategories }) => {
    // eslint-disable-next-line no-underscore-dangle
    function _toArch(ob, obFieldType) {
      if (obFieldType === "number") {
        return parseFloat(ob);
      } else if (obFieldType === "length") {
        if (systemOfMeasurement === "imperial") {
          return convertFeetToMeters(ob);
        }
        return parseFloat(ob);
      } else if (obFieldType === "string") {
        return ob === "" ? null : ob;
      } else if (obFieldType === "list_of_numbers" || typeof obFieldType === "undefined") {
        return ob;
      } else if (typeof obFieldType === "object") {
        // TODO-devex-4985-2 obFieldType used to be fieldTypes (with an s) - I wonder if that was
        // just wrong this whole time.
        let arch = cloneDeep(ob);
        for (let [k, v] of iterItems(obFieldType)) {
          if (k in arch) {
            arch[k] = _toArch(arch[k], v);
          }
        }
        return arch;
      }
      throw new Error(`Unrecognised field type: ${obFieldType}`);
    }

    const arch = _toArch(state, fieldType);
    if (arch.BOM != null) {
      arch.BOM = sanitizeBom(arch.BOM, bomCategories, systemOfMeasurement);
    }
    // Remove the keys that shouldn't be passed.
    delete arch.hasUpdatedFlexNap;
    delete arch.hasUpdatedDemandModel;
    return arch;
  },

  fromArchitecture: (arch, systemOfMeasurement, { fieldType = fieldTypes, bomCategories }) => {
    // eslint-disable-next-line no-underscore-dangle
    function _fromArch(ob, obFieldType) {
      if (obFieldType === "number") {
        // Don't crash on null numeric fields. For example, TailsPerSplice may
        // be null on architectures where connectorized drop terminals are
        // disabled.
        if (ob != null) {
          return ob.toString();
        }
        return "";
      } else if (obFieldType === "length") {
        if (ob != null) {
          if (systemOfMeasurement === "imperial") {
            return metersToFeetDisplay(ob).toString();
          }
          return ob.toString();
        }
        return "";
      } else if (obFieldType === "string") {
        return ob || "";
      } else if (obFieldType === "list_of_numbers" || typeof obFieldType === "undefined") {
        return ob;
      } else if (typeof obFieldType === "object") {
        let state = cloneDeep(ob);
        for (let [k, v] of iterItems(obFieldType)) {
          if (k in state) {
            state[k] = _fromArch(state[k], v);
          }
        }
        return state;
      }
      throw new Error(`Unrecognised fieldType: ${obFieldType}`);
    }
    const uiArch = _fromArch(arch, fieldType);
    uiArch.HasAttemptedSave = false;
    if (uiArch.BOM != null && systemOfMeasurement === "imperial") {
      uiArch.BOM = convertBomValuesToFeet(uiArch.BOM, bomCategories);
    }
    return uiArch;
  },
};

/**
 * These are used to determine whether the architecture list needs to be
 * scrolled to put the selected architecture in view.
 */
export const ListOperationTypes = {
  load: "load",
  select: "select",
  delete: "delete",
  create: "create",
};

export function getInitialState() {
  return {
    account: null,

    // Modules that the account has access to.
    accountModules: null,

    fieldRules: baseFieldRules,

    selectedTabIndex: 0,

    // These are used to control which bom category is selected, and if a row is being edited, which one.
    // They are stored in the architecture state (as opposed to the bom tab state) because
    // we need to be able to update these when the save action occurs so that
    // we can automatically switch to the bom tab, select the category,
    // and begin editing a row if there is any validation errors in the rows.
    selectedBomCategoryID: null,
    editingBomRowID: null,

    // For the current architecture, what is the highest index of a tab the
    // user has edited? For new architectures, we only allow the user to navigate
    // between tabs with indexes up to `highestConfiguredTabIndex`.
    highestConfiguredTabIndex: 0,

    lastListOperation: null,
    architectures: null,

    bomCategories: null,

    selectedArchitectureID: null,
    projectArchitecture: null,

    // The architecture currently being edited. Length values will be in the selected system of measurement.
    // i.e. If systemOfMeasurement is imperial, the lengths will be displayed in feet
    widgetArchitecture: null,
    // The results of validating `widgetArchitecture`
    validationResults: {},

    isLoading: true,
    isSaving: false,
    scrollToFieldPath: null,
    scrollToFieldPathNum: 0,
    archHasChanged: false,

    // Is the "Replace project architecture?" dialog open? There's no data
    // attached to this; the dialog is either open if
    // `confirmReplaceProjectArch` is truthy else it isn't open.
    confirmReplaceProjectArch: false,

    // Is the user being asked to discard changes by selecting a different arch
    // while there are unsaved changes to the current arch? If so, this will be
    // set to the action that triggered the confirmation
    // (ie. {type: 'selectArchitecture, index: <some index>}).
    confirmDiscardChanges: null,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case "loadSuccess": {
      const canEditFlexNap = accountModuleCheck(action.accountModules, Actions.ARCHITECTURE_ENABLE_FLEXNAP);
      const preference = canEditFlexNap ? FlexNapFeature : undefined;
      return updateValidationResults({
        ...state,
        account: action.account,
        accountModules: action.accountModules,
        project: action.project,
        architectures: action.architectures,
        selectedArchitectureID: action.projectArchitecture?.ID,
        widgetArchitecture: action.widgetArchitecture,
        projectArchitecture: action.projectArchitecture,
        highestConfiguredTabIndex:
          action.projectArchitecture != null && action.projectArchitecture.IsConfigured
            ? lastTabIndex(action.projectArchitecture, canEditFlexNap)
            : 0,
        lastListOperation: ListOperationTypes.load,
        isLoading: false,
        fieldRules: getPreferredRules(preference) || action.fieldRules,
        bomCategories: action.bomCategories,
        systemOfMeasurement: action.systemOfMeasurement,
      });
    }
    case "selectArchitecture": {
      return {
        ...state,
        ...updateValidationResults({
          ...state,
          ...action,
        }),
      };
    }
    case "createArch": {
      const defaultArch = getDefaultArchBOM();
      const widgetArchitecture = widgetRepresentation.fromArchitecture(defaultArch, state.systemOfMeasurement, {
        bomCategories: state.bomCategories,
      });
      return updateValidationResults({
        ...state,
        lastListOperation: ListOperationTypes.create,
        widgetArchitecture: widgetArchitecture,
        highestConfiguredTabIndex: 0,
        selectedTabIndex: 0,
        selectedArchitectureID: null,
      });
    }
    case "updateArchitecture": {
      let newArchitecture = state.widgetArchitecture;
      action.updateActions.forEach((item) => {
        newArchitecture = setIn(newArchitecture, item.path, item.value);
      });
      return updateValidationResults({
        ...state,
        widgetArchitecture: newArchitecture,
        archHasChanged: true,
      });
    }
    case "saveBegin": {
      return {
        ...state,
        isSaving: true,
      };
    }
    case "saveFailed": {
      return { ...state, isSaving: false };
    }
    case "saveComplete": {
      let newState = {
        ...state,
        selectedArchitectureID: action.selectedArchitectureID,
        isSaving: false,
        archHasChanged: false,
        widgetArchitecture: {
          ...action.architecture,
          ID: action.architecture.ID,
          CreatedAt: action.architecture.CreatedAt,
          Creator: action.architecture.Creator,
        },
      };

      if (action.selectedArchitectureID && action.selectedArchitectureID === state.projectArchitecture?.ID) {
        newState.projectArchitecture = widgetRepresentation.toArchitecture(newState.widgetArchitecture, state.systemOfMeasurement, {
          bomCategories: state.bomCategories,
        });
      }
      return newState;
    }
    case "selectTab": {
      // If we selected the BOM tab due to an error we need to also select the category and begin editing the row.
      let selectedBomCategoryID = null;
      let editingBomRowID = null;
      if (action.scrollToFieldPath != null && action.scrollToFieldPath[0] === "BOM") {
        selectedBomCategoryID = action.scrollToFieldPath[1];
        editingBomRowID = action.scrollToFieldPath[2];
      }

      return {
        ...state,
        // Only update the highestConfiguredTabIndex if were selecting a tab
        // that hasn't been configured yet (next button).
        highestConfiguredTabIndex: state.highestConfiguredTabIndex < action.tabIndex ? action.tabIndex : state.highestConfiguredTabIndex,
        selectedTabIndex: action.tabIndex,
        selectedBomCategoryID: selectedBomCategoryID,
        editingBomRowID: editingBomRowID,
        scrollToFieldPath: action.scrollToFieldPath,
        scrollToFieldPathNum: action.scrollToFieldPath != null ? state.scrollToFieldPathNum + 1 : state.scrollToFieldPathNum,
        widgetArchitecture: {
          ...state.widgetArchitecture,
          // If on the final tab, IsConfigured changes from false to true.
          IsConfigured:
            state.widgetArchitecture.IsConfigured ||
            action.tabIndex === lastTabIndex(state.widgetArchitecture, accountModuleCheck(state.accountModules, Actions.ARCHITECTURE_ENABLE_FLEXNAP)),
        },
      };
    }
    case "setApplyDialogOpen": {
      return {
        ...state,
        confirmReplaceProjectArch: action.value,
      };
    }
    case "showDiscardDialog": {
      return {
        ...state,
        confirmDiscardChanges: action,
      };
    }
    case "closeDiscardDialog": {
      return {
        ...state,
        confirmDiscardChanges: null,
      };
    }
    case "copyArch": {
      return {
        ...state,
        lastListOperation: "create",
        archHasChanged: true,
      };
    }
    case "selectBomCategory": {
      return { ...state, selectedBomCategoryID: action.categoryID };
    }
    case "editBomRow": {
      return { ...state, editingBomRowID: action.rowID };
    }
    default: {
      return state;
    }
  }
}

const ArchitectureEditorContext = createContext();

/**
 * Architecture editor components should use this hook to access the ArchitectureEditorContext.
 *
 * Usage:
 *
 * function MyComponent() {
 *   const [state, dispatch] = useArchitectureEditorContext();
 *   // `state` is the architecture editor context's state (initialised
 *   // with the `getInitialState()` function above,
 *   // and updated via the `reducer` function also above.
 *   // Use the `dispatch` function as you would a Redux dispatch function,
 *   // to dispatch actions defined below
 * }
 */
export const useArchitectureEditorContext = () => useContext(ArchitectureEditorContext);

// To avoid a name conflict below.
const defaultGetInitialState = getInitialState;

export function ArchitectureEditorContextProvider({
  children,

  // Tests can use getInitialState to override the initial state, and onRender
  // to get a hold of the latest state.
  // eslint-disable-next-line @typescript-eslint/no-shadow
  getInitialState = defaultGetInitialState,
  onRender,
}) {
  // https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c
  const [state, dispatch] = useReducer(reducer, null, getInitialState);

  // Use a mutable `stateRef.current` so the `enhancedDispatch` function will
  // always return the latest state. Putting `[state]` as dependencies in the
  // `useCallback` for `enhancedDispatch` isn't adequate since that will
  // recreate new `enhancedDispatch`es, leaving old references to the function
  // around that will still return the old state.
  const stateRef = useRef(null);
  stateRef.current = state;

  const reduxDispatch = useDispatch();
  const reduxGetState = useStore().getState;

  /**
   * Provide something analogous to redux-thunk to users of the context. That
   * is, users can dispatch functions that take arguments `dispatch` and
   * `getState`. We also pass a third argument, `reduxDispatch` for actions
   * that wish to dispatch real redux actions. For example a user of this
   * context might use the context's `dispatch` for managing its internal
   * state, but use `reduxDispatch` when the user hits save & close and we want
   * to communicate back to the global Redux store to close the window and
   * display the snackbar notification etc.
   */
  const enhancedDispatch = useCallback(
    (action) => {
      if (typeof action === "function") {
        action(enhancedDispatch, () => stateRef.current, reduxDispatch, reduxGetState);
      } else {
        dispatch(action);
      }
    },
    [reduxDispatch, reduxGetState]
  );

  useEffect(() => {
    if (onRender != null) {
      onRender(state, dispatch);
    }
  });

  const reducerHook = [state, enhancedDispatch];

  return <ArchitectureEditorContext.Provider value={reducerHook}>{children}</ArchitectureEditorContext.Provider>;
}

ArchitectureEditorContextProvider.propTypes = {
  children: PropTypes.node,
  getInitialState: PropTypes.func,
  onRender: PropTypes.func,
};

ArchitectureEditorContextProvider.defaultProps = {
  children: null,
  onRender: () => null,
};

// Actions

export function load(account, accountModules, architecture, bomArchRestrictions, systemOfMeasurement) {
  return (dispatch, getState, reduxDispatch, reduxGetState) => {
    mixpanel.track("Opened architecture modal");
    Promise.all([
      reduxDispatch(bomSlice.endpoints.getBomTemplateCategories.initiate(undefined)),
      reduxDispatch(architecturesSlice.endpoints.getArchitectures.initiate(undefined)),
    ]).then(([categoriesResponse, archsResponse]) => {
      dispatch({
        account: account,
        accountModules: accountModules,
        type: "loadSuccess",
        systemOfMeasurement: systemOfMeasurement,
        widgetArchitecture: architecture
          ? widgetRepresentation.fromArchitecture(architecture, systemOfMeasurement, {
              bomCategories: categoriesResponse.data.Categories,
            })
          : null,
        projectArchitecture: architecture,
        fieldRules: applyBomRestrictions(baseFieldRules, bomArchRestrictions ?? []),
        bomCategories: categoriesResponse.data.Categories,
      });
    });
  };
}

export function selectArchitecture(id, { isConfirmed = false } = {}) {
  return (dispatch, getState, reduxDispatch, reduxGetState) => {
    const state = getState();
    const architecture = selectArchitectureById(reduxGetState(), id);
    if (state.archHasChanged && !isConfirmed) {
      // Ask the user for confirmation if they select a different
      // architecture while they have unsaved changes.
      dispatch({
        type: "showDiscardDialog",
        ID: id,
      });
    } else {
      let widgetArchitecture;
      if (id === state.projectArchitecture?.ID) {
        widgetArchitecture = widgetRepresentation.fromArchitecture(state.projectArchitecture, state.systemOfMeasurement, {
          bomCategories: state.bomCategories,
        });
      } else {
        widgetArchitecture = widgetRepresentation.fromArchitecture(architecture, state.systemOfMeasurement, {
          bomCategories: state.bomCategories,
        });
      }
      dispatch({
        type: "selectArchitecture",
        selectedArchitectureID: id,
        widgetArchitecture: widgetArchitecture,
        lastListOperation: ListOperationTypes.select,
        highestConfiguredTabIndex: widgetArchitecture.IsConfigured
          ? lastTabIndex(widgetArchitecture, accountModuleCheck(state.accountModules, Actions.ARCHITECTURE_ENABLE_FLEXNAP))
          : 0,
        selectedTabIndex: widgetArchitecture.IsConfigured ? Math.min(widgetArchitecture.NumberOfTiers, state.selectedTabIndex) : 0,
        archHasChanged: false,
      });
    }
  };
}

/**
 * @param {*} updateActions a list of {path: path, value: value}
 */
export function updateArchitecture(updateActions) {
  return { type: "updateArchitecture", updateActions: updateActions };
}

export function selectTab(tabIndex, scrollToFieldPath = null) {
  return {
    type: "selectTab",
    tabIndex: tabIndex,
    scrollToFieldPath: scrollToFieldPath,
  };
}

export function nextTab() {
  return (dispatch, getState) => {
    const state = getState();
    if (
      state.selectedTabIndex < lastTabIndex(state.widgetArchitecture, accountModuleCheck(state.accountModules, Actions.ARCHITECTURE_ENABLE_FLEXNAP))
    ) {
      dispatch(selectTab(state.selectedTabIndex + 1));
    }
  };
}

export function create() {
  return { type: "createArch" };
}

export function deleteArchitecture() {
  return (dispatch, getState, reduxDispatch, reduxGetState) => {
    const { selectedArchitectureID, widgetArchitecture } = getState();
    reduxDispatch(architecturesSlice.endpoints.deleteArchitecture.initiate(widgetArchitecture))
      .then(() => {
        const architectures = selectAllArchitectures(reduxGetState());
        const currentIndex = architectures.findIndex((arch) => arch.ID === selectedArchitectureID);
        const nextArchitecture = architectures[currentIndex > 0 ? currentIndex - 1 : 1];
        // Select the first architecture after we perform the delete.
        dispatch(selectArchitecture(nextArchitecture?.ID));
      })
      .catch(() => {
        reduxDispatch(addNotification("Sorry, we couldn't delete this architecture. Please try again."));
      });
  };
}

export function closeApplyDialog() {
  return { type: "setApplyDialogOpen", value: false };
}

export function confirmDiscardDialog() {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(selectArchitecture(state.confirmDiscardChanges.ID, { isConfirmed: true }));
    dispatch(closeDiscardDialog());
  };
}

export function closeDiscardDialog() {
  return { type: "closeDiscardDialog" };
}

/**
 * Dispatch this to save an architecture. If `project` is provided, it means
 * we're saving a project architecture, else we're saving a base architecture.
 * If we go to save a project architecture and we've selected a different
 * architecture, this function will instead display a confirmation dialog and
 * return. The confirmation dialog should then call `save` again with
 * `{isConfirmed: true}` to confirm the save.
 * @param { versionId: string | null, projectId: string | null }
 */
export function save(
  { versionId, projectId, type } = { versionId: null, projectId: null, type: "project" },
  { isConfirmed = false, needRefreshMLC = false } = {}
) {
  return (dispatch, getState, reduxDispatch) => {
    const state = getState();
    let { widgetArchitecture } = state;

    // Update attempted save so validate knows that this has been done in order to only show ArchName error after save
    widgetArchitecture.HasAttemptedSave = true;
    dispatch(updateArchitecture([{ path: ["HasAttemptedSave"], value: true }]));

    const { hasError, errorAction } = getErrorInfo(state);

    if (!hasError) {
      // If we are replacing the project arch with a different arch, ask the
      // user to confirm (if they haven't confirmed already). Otherwise, just
      // save it.
      if (versionId && state.projectArchitecture && state.selectedArchitectureID !== state.projectArchitecture.ID && !isConfirmed) {
        dispatch({ type: "setApplyDialogOpen", value: true });
        return;
      }

      dispatch({ type: "saveBegin" });
      widgetArchitecture = {
        ...widgetArchitecture,
        IsConfigured: true,
      };

      mixpanel.track("Saved architecture selection", {
        architectureId: widgetArchitecture.ID,
      });

      const saveArch = async () => {
        const response = await reduxDispatch(
          saveArchitecture(widgetArchitecture, { versionId: versionId, projectId: projectId, type: type, bomCategories: state.bomCategories }, state)
        );
        // The create/update base architecture routes nest the response, whereas the update version architecture doesn't.
        // TODO: unnest the base architecture routes.
        return { architecture: response.data?.Architecture ?? response.data };
      };

      saveArch()
        .then(({ architecture }) => {
          if (versionId) {
            reduxDispatch(addNotification("Project architecture updated."));
            reduxDispatch(closeArchitectureModal());

            if (type === "project") {
              if (widgetArchitecture.hasUpdatedDemandModel) {
                reduxDispatch(versionsSlice.endpoints.reapplyDemandModel.initiate({ projectId, versionId }));
              }
              if (needRefreshMLC) {
                reduxDispatch(
                  apiSlice.util.invalidateTags([
                    { type: "Version", id: versionId },
                    { type: "MapLayerConfig", id: versionId },
                    { type: "Layers", id: versionId },
                  ])
                );
              }
            } else if (type === "multi_project") {
              reduxDispatch(multiProjectsSlice.endpoints.getMultiProject.initiate(projectId));
            }
          } else if (widgetArchitecture.ID != null) {
            reduxDispatch(addNotification("Architecture saved."));
          } else {
            reduxDispatch(addNotification("Architecture added."));
          }

          const tt = widgetRepresentation.fromArchitecture(architecture, state.systemOfMeasurement, {
            bomCategories: state.bomCategories,
          });

          dispatch({
            type: "saveComplete",
            selectedArchitectureID: state.selectedArchitectureID || architecture.ID,
            architecture: widgetRepresentation.fromArchitecture(architecture, state.systemOfMeasurement, {
              bomCategories: state.bomCategories,
            }),
          });
        })
        .catch((error) => {
          reduxDispatch(addNotification("We encountered a problem saving this architecture. Please try again."));
          dispatch({ type: "saveFailed" });
        });
    } else {
      // errorAction only sets the error in the tabs, so if anything is set, it will revert back to that tab
      if (errorAction != null) {
        dispatch(errorAction);
      }
      reduxDispatch(addNotification("Sorry, we can't save this architecture. Please fix any errors and try again."));
    }
  };
}

/**
 * Makes the actual FOND Service request to save an architecture, returning a
 * Promise. Used by `save` and `copyArch`.
 */
function saveArchitecture(widgetArchitecture, { type, versionId, bomCategories }, state) {
  const arch = transformEmptyStrings(
    widgetRepresentation.toArchitecture(widgetArchitecture, state.systemOfMeasurement, { bomCategories: bomCategories })
  );

  if (versionId != null && type === "project") {
    return versionsSlice.endpoints.updateVersionArchitecture.initiate({ versionId: versionId, architecture: arch });
  } else if (type === "multi_project") {
    // Multiproject versionId and projectId are the same, since they dont have versions.
    return multiProjectsSlice.endpoints.updateMultiProject.initiate({
      ID: versionId,
      Architecture: pick(arch, [
        "BOM",
        "DefaultPlacement",
        "Demand",
        "Description",
        "IsConfigured",
        "Name",
        "NumberOfTiers",
        "Tier1",
        "Tier2",
        "Tier3",
        "HasAttemptedSave",
        "IsFlexNap",
      ]),
    });
  } else if (widgetArchitecture.ID != null) {
    return architecturesSlice.endpoints.updateArchitecture.initiate(arch);
  } else {
    // Newly created architectures must be associated with an account
    return architecturesSlice.endpoints.createArchitecture.initiate({ AccountID: state.account?.ID, ...arch });
  }
}

/**
 * @param {string} categoryID - The ID of the category that is selected. Set to null to deselect a category.
 */
export function selectBomCategory(categoryID) {
  return {
    type: "selectBomCategory",
    categoryID: categoryID,
  };
}

/**
 * @param {number} rowIndex - The index of the row within it's category. Set to null to not be editing any row.
 */
export function editBomRow(rowID) {
  return {
    type: "editBomRow",
    rowID: rowID,
  };
}

function transformEmptyStrings(ob) {
  if (ob === "") {
    return null;
  } else if (Array.isArray(ob)) {
    return ob.map(transformEmptyStrings);
  } else if (ob != null && typeof ob === "object") {
    let newOb = {};
    for (let [k, v] of iterItems(ob)) {
      // Exempt demand model's address type if it's set to empty string
      if (k !== "AddressType") newOb[k] = v;
      else newOb[k] = transformEmptyStrings(v);
    }
    return newOb;
  } else {
    return ob;
  }
}

// Helper function for `save` above.
export function getErrorInfo(state) {
  const errors = validate(state.widgetArchitecture, state, state.systemOfMeasurement);
  let errorAction = null;
  let hasError = false;

  // General Tab validation is handled differently due to the architecture structure
  if (errors.Name != null) {
    hasError = true;

    return {
      hasError: hasError,
      errorAction: selectTab(0, ["Name"]),
    };
  } else if (errors.NumberOfTiers != null) {
    hasError = true;

    return {
      hasError: hasError,
      errorAction: selectTab(0, ["NumberOfTiers"]),
    };
  }

  for (let [i, tabId] of enumerate(getArchitectureTabIds(state.widgetArchitecture))) {
    if (errors[tabId] != null) {
      const error = getError(errors[tabId]);

      if (error != null) {
        errorAction = selectTab(i, [tabId, ...error.path]);
        hasError = true;
        break;
      }
    }
  }

  return { hasError, errorAction };
}

/**
 * Return a list of tab IDs (as used by the ArchitectureEditor component) for
 * the given architecture.
 */
function getArchitectureTabIds(widgetArchitecture) {
  if (widgetArchitecture.NumberOfTiers === 2) {
    return ["General", "Tier1", "Tier2", "Demand", "BOM"];
  } else if (widgetArchitecture.NumberOfTiers === 3) {
    return ["General", "Tier1", "Tier2", "Tier3", "Demand", "BOM"];
  } else {
    throw new Error(`Unexpected number of tiers: ${widgetArchitecture.NumberOfTiers}`);
  }
}

export function copyArch() {
  return (dispatch, getState, reduxDispatch) => {
    const { hasError, errorAction } = getErrorInfo(getState());

    if (!hasError) {
      dispatch({ type: "copyArch" });
      const state = getState();
      const selectedArch = {
        ...state.widgetArchitecture,
        ID: null,
        Name: `Copy of ${state.widgetArchitecture.Name}`,
      };

      dispatch((dispatch1, getState1) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const state = getState1();

        reduxDispatch(saveArchitecture(selectedArch, { bomCategories: state.bomCategories }, state)).then(
          (response) => {
            // The create and update architecture routes currently return a nested object, whereas the
            // update version architecture route returns a flat one.
            const architecture = response.data?.Architecture ?? response.data;
            dispatch({
              type: "saveComplete",
              selectedArchitectureID: architecture.ID,
              architecture: widgetRepresentation.fromArchitecture(architecture, state.systemOfMeasurement, {
                bomCategories: state.bomCategories,
              }),
            });
            reduxDispatch(addNotification("Architecture copied."));
          },
          (error) => {
            reduxDispatch(addNotification("We encountered a problem saving this architecture. Please try again."));
            dispatch({ type: "saveFailed" });
          }
        );
      });
    } else {
      // errorAction only sets the error in the tabs, so if anything is set, it will revert back to that tab
      if (errorAction != null) {
        dispatch(errorAction);
      }
      reduxDispatch(addNotification("Sorry, we can't copy this architecture because it currently has errors. Please fix any errors and try again."));
    }
  };
}

export function setEditingBomRule(rule) {
  return { type: "setEditingBomRule", rule: rule };
}

// Utils
function updateValidationResults(state) {
  if (state.widgetArchitecture != null) {
    return {
      ...state,
      validationResults: validate(state.widgetArchitecture, state, state.systemOfMeasurement),
    };
  } else {
    return state;
  }
}

/**
 * @param arch the architecture to validate
 * @param state the top-level state of the architecture panel
 *   We use `state.fieldRules` to determine which validation rules to apply to
 *   the architecture, and `state.bomCategories` to figure out how to validate
 *   the BOM rules. `state` is also passed to the rule evaluation functions.
 *
 * Rule evaluation functions will be called with three arguments: the first
 * being the value of the particular field being checked, the second being
 * `arch`, and the third being `state`.
 *
 * Example (see __tests__/validation.test.js):

    const arch = {
      Tier1: {
        DropRules: {
          DropLength: "u"
        }
      }
    };

    const rules = {
      Tier1: {
        DropRules: {
          DropLength: [
            {
              condition: val => isNaN(Number(val)),
              state: 'error',
              message: 'Must be a number'
            }
          ]
        }
      }
    };

    >>> validate(arch, {fieldRules: rules})
    {
      Tier1: {
        DropRules: {
          DropLength: {
            state: 'error',
            message: 'Must be a number'
          }
        }
      }
    }

 */
export function validate(arch, state, systemOfMeasurement) {
  function doValidate(ob, rules, path = [], output = {}) {
    if (Array.isArray(rules)) {
      // Note: we are relying on the fact that lodash `get` returns `undefined` rather
      // than throwing an exception if the path does not exist. Otherwise we
      // would crash trying to get the possibly-nonexistent Tier 3 values for
      // 2-tier archs, even though the Tier 3 rules are smart enough not to look
      // at the values.
      const val = get(ob, path);
      for (let rule of rules) {
        if (rule.condition(val, arch, state, systemOfMeasurement)) {
          set(output, path, {
            state: rule.state,
            message: typeof rule.message === "function" ? rule.message(val, arch, state, systemOfMeasurement) : rule.message,
          });
          break;
        }
      }
    } else {
      for (let [k, v] of iterItems(rules)) {
        doValidate(ob, v, [...path, k], output);
      }
      return output;
    }
  }

  let validationResult = doValidate(arch, state.fieldRules);
  if (arch.BOM != null) {
    validationResult.BOM = validateBOM(arch.BOM, state.bomCategories);
  }

  return validationResult;
}

/**
 * Returns the first error (not warning) in the `errors` object (as returned by `validate` above).
 *
 * eg:

    const errors = {
      Tier1: {
        DropRules: {
          DropLength: {
            state: 'error',
            message: 'Must be a number'
          }
        }
      }
    };

    >>> getError(output)
    {
      path: ['Tier1', 'DropRules', 'DropLength'],
      error: {
        state: 'error',
        message: 'Must be a number'
      }
    }
 */
export function getError(errors, path = []) {
  const errs = getIn(errors, path);
  if (errs.state != null) {
    if (errs.state === "error") {
      return { path: path, error: errs };
    } else {
      return null;
    }
  } else {
    for (let k of Object.keys(errs)) {
      const e = getError(errors, [...path, k]);
      if (e != null) {
        return e;
      }
    }
    return null;
  }
}

export function lastTabIndex(widgetArchitecture, hasFlexNapModule = false) {
  // For accounts with the FlexNAP module, architecture does not have the bom tab.
  if (hasFlexNapModule) {
    return widgetArchitecture.NumberOfTiers + 1;
  } else {
    return widgetArchitecture.NumberOfTiers + 2;
  }
}

import { Paper, Select as BaseSelect, styled, TextField as BaseTextField } from "@mui/material";
import { red } from "@mui/material/colors";

export const TextField = styled(BaseTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: theme.palette.common.white,
    height: 28,
    paddingRight: 0,
  },
  "& .MuiOutlinedInput-input": {
    fontSize: 12,
    fontWeight: 500,
    padding: theme.spacing(0.5),
    color: theme.palette.text.primary,
  },
  "& .MuiInputAdornment-root p": {
    fontSize: 12,
  },
}));

export const Select = styled(BaseSelect<string>)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    backgroundColor: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 500,
    padding: theme.spacing(0.5),
  },
}));

export const Container = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "error",
})<{ error: boolean }>(({ theme, error }) => ({
  display: "flex",
  flexDirection: "column",
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: theme.palette.divider,
  backgroundColor: error ? red[50] : theme.palette.background.default,
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  padding: theme.spacing(1),
}));

Container.defaultProps = {
  elevation: 0,
};

import React, { useState } from "react";
import { Add, AttachFile, Edit, GridView, InfoOutlined, List, ZoomIn } from "@mui/icons-material";
import { Box, Button, ButtonGroup, IconButton, ToggleButtonGroup, Typography } from "@mui/material";

import { AttachmentModal } from "fond/attachments";
import { Project } from "fond/types";
import { useAppSelector } from "fond/utils/hooks";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { NonIdealState } from "fond/widgets";

import { ToggleButton } from "./propertiesPanel.styles";

interface IProps {
  project: Project;
}

const FeatureAttachments: React.FC<IProps> = ({ project }) => {
  const attachments = useAppSelector((state) => state.attachments.items);

  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [view, setView] = useState<"gallery" | "list">("list");
  const [selectedFileIndex, setSelectFileIndex] = useState<number | null>(null);

  return (
    <Box
      data-testid="feature-attachments-tab-content"
      flex={1}
      display="flex"
      flexDirection="column"
      overflow="hidden auto"
      className="customScrollbars"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" p={1.5}>
        <Button
          disabled={!permissionCheck(project.Permission.Level, Actions.PROJECT_UPLOAD_ATTACHMENT)}
          onClick={() => setShowAttachmentModal(true)}
          startIcon={<Add />}
          variant="contained"
          color="primary"
        >
          Upload file
        </Button>
        <ToggleButtonGroup
          data-testid="view-mode-toggle-buttons"
          value={view}
          onChange={(_, newView) => {
            if (newView) {
              setView(newView);
            }
          }}
          exclusive
          size="small"
        >
          <ToggleButton value="gallery">
            <GridView fontSize="small" />
          </ToggleButton>
          <ToggleButton value="list">
            <List fontSize="small" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {attachments && attachments.length > 0 ? (
        <Box flex={1} display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" justifyContent="space-between" py={1} px={1.5} borderTop={1} borderBottom={1} borderColor="divider">
            <Typography variant="body3">
              {attachments.length} {attachments.length > 1 ? "files" : "file"}
            </Typography>
            <ButtonGroup>
              <IconButton size="small" disabled={selectedFileIndex === null}>
                <ZoomIn />
              </IconButton>
              <IconButton size="small" disabled={selectedFileIndex === null}>
                <Edit />
              </IconButton>
              <IconButton size="small" disabled={selectedFileIndex === null}>
                <InfoOutlined />
              </IconButton>
            </ButtonGroup>
          </Box>
          <Box flex={1}>
            {view === "list" && (
              <Box height="100%">
                {/**
                 * TODO: Add list view of feature attachments
                 */}
              </Box>
            )}
            {view === "gallery" && (
              <Box height="100%">
                {/**
                 * TODO: Add grid view of feature attachments
                 */}
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <NonIdealState icon={<AttachFile />} title="" description="Selected feature has no attachments" />
      )}
      {showAttachmentModal && <AttachmentModal isOpen onClose={() => setShowAttachmentModal(false)} attachmentEntityType="Feature" />}
    </Box>
  );
};

export default FeatureAttachments;

import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DriveFileMoveOutlined as FolderIcon } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { selectCurrentAccount } from "fond/api";
import { clearSharedAccount, updateFilter, updateSharedAccount } from "fond/projects/redux";
import { Project, Report } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";

import { IconButton } from "./topbar.styles";

interface IProps {
  entity: Project | Report;
}

const ViewEntityFolderButton: React.FC<IProps> = ({ entity }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentAccount = useSelector(selectCurrentAccount);

  const entityType = entity.EntityType;
  const folderId = entityType === "project" ? entity.FolderID : entity.Folder?.ID;

  const openFolder = () => {
    const entityInCurrentAccount = currentAccount?.ID === entity.Account.ID;
    if (entityInCurrentAccount) {
      dispatch(updateFilter("workspace"));
      dispatch(clearSharedAccount);
    } else {
      dispatch(updateFilter("shared"));
      dispatch(updateSharedAccount(entity.Account.ID));
    }

    if (folderId) {
      navigate(`/folders/${folderId}`);
    } else {
      const path = entityInCurrentAccount ? "/workspace/" : "/shared/";
      navigate(path);
    }
  };

  return (
    <Tooltip title={`Go to this ${entityType}'s folder`}>
      <IconButton size="small" onClick={() => openFolder()} data-testid={`view-${entityType}-folder-btn`}>
        <FolderIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};

export default ViewEntityFolderButton;

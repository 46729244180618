import React, { useMemo } from "react";
import { ListAlt, MonetizationOnRounded, QuestionMark } from "@mui/icons-material";
import { BoxProps, Tooltip } from "@mui/material";
import { pick } from "lodash";

import { Configuration } from "fond/types";
import { LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";

import { IconStack } from "./IconStack";
import { StyleIcon } from "./StyleIcon";

import { Container, NullGeomIcon, UnknownGeomIcon } from "./InferredIcon.styles";

interface IProps extends BoxProps {
  entity: LayerConfig | SublayerConfig | LayerStyle;
  config: Configuration;
}

const InferredIcon: React.FC<IProps> = ({ entity, config, ...containerProps }: IProps) => {
  // Get the styles of the current entity
  const styles = useMemo((): LayerStyle[] => {
    if (entity.Type === "STYLE") return [entity];

    // SUBLAYER also requires the styles from the parent LAYER.
    if (entity.Type === "SUBLAYER") {
      const layer = config.Data.entities[entity.ParentID] as LayerConfig;
      return [
        ...(Object.values(pick(config.Data.entities, layer.Styles)) as LayerStyle[]),
        ...(Object.values(pick(config.Data.entities, entity.Styles)) as LayerStyle[]),
      ];
    } else if (entity.Type === "LAYER") {
      return Object.values(pick(config.Data.entities, entity.Styles)) as LayerStyle[];
    }
    return [];
  }, [config.Data.entities, entity]);

  const getIcon = () => {
    if (entity.Type === "LAYER" && entity.GeometryType === "Null") return <NullGeomIcon />;
    if (entity.Type === "LAYER" && entity.GeometryType === null)
      return (
        <Tooltip title="This layer is empty.  Features of any geometry type may be imported into this layer.">
          <UnknownGeomIcon color="secondary" />
        </Tooltip>
      );
    if (entity.Type === "LAYER" && entity.SubType === "COST_TO_SERVE") return <MonetizationOnRounded color="primary" sx={{ fontSize: 16 }} />;
    if ((entity.Type === "LAYER" && entity.Children.length === 0) || entity.Type === "SUBLAYER" || entity.Type === "STYLE")
      return <StyleIcon styles={styles} />;

    return <IconStack layer={entity} config={config} />;
  };

  return <Container {...containerProps}>{getIcon()}</Container>;
};

export default InferredIcon;

import React, { PropsWithChildren } from "react";
import { Tooltip } from "@mui/material";

import { Button } from "./toolbar.styles";

interface IProps {
  active?: boolean;
  disabled?: boolean;
  title: string;
  onClick(): void;
}

const ToolbarButton: React.FC<PropsWithChildren<IProps>> = ({
  active = false,
  disabled = false,
  children,
  title,
  onClick,
}: PropsWithChildren<IProps>) => {
  return (
    <Tooltip title={title}>
      <span>
        <Button onClick={onClick} active={active} disabled={disabled}>
          {children}
        </Button>
      </span>
    </Tooltip>
  );
};

export default ToolbarButton;

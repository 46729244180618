import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import MapboxDraw, { DrawCombineEvent, DrawCreateEvent, DrawDeleteEvent, DrawUncombineEvent, DrawUpdateEvent } from "@mapbox/mapbox-gl-draw";
import { Alert, Box, Button, FormHelperText, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import chroma from "chroma-js";
import { Feature, FeatureCollection, MultiPolygon, Polygon } from "geojson";
import { SnapPolygonMode } from "mapbox-gl-draw-snap-mode";

import { useGetMultiProjectQuery, useLazyGetBoundaryFeatureCountQuery, useUpdateMultiProjectMutation } from "fond/api";
import { CITY_PLANNER_AREA_MAX } from "fond/constants";
import { MapContext } from "fond/map/MapProvider";
import { feature as toFeature, featureCollection, multiPolygon } from "fond/turf";
import { MultiProjectArea, MultiProjectAreaImportMethod, Store } from "fond/types";
import { isAnyPolygon, isPolygon } from "fond/types/geojson";
import { makeUuid } from "fond/utils";
import { layerPalette } from "fond/utils/colors";
import { generateUnusedName } from "fond/utils/naming";
import { formatNumber } from "fond/utils/number";
import { useStackedNavigationContext } from "fond/widgets";
import StackedNavigationHeader from "fond/widgets/StackedNavigation/StackedNavigationHeader";

import { AreaErrorType, clipOutside, intersect, setFeatureProperty, snappingOptions, validateAreaFeatures } from "../helper";
import directSelectMode from "../modes/directSelect";
import polygonCutMode from "../modes/polygonCut";

import styles from "./areaDrawStyles";
import AreaList from "./AreaList";

export type SubareaProperties = {
  id: string;
  name: string;
  minCount: number | null;
  exactCount: number | null;
  importMethod: MultiProjectAreaImportMethod;
  color: string;
};

export type SubareaFeature = Feature<Polygon | MultiPolygon, SubareaProperties>;

const AreaDrawPanel: React.FC = () => {
  const multiProjectId = useSelector((state: Store) => state.project.projectId);
  const { data: multiProject } = useGetMultiProjectQuery(multiProjectId);
  const { goBack, clear } = useStackedNavigationContext();
  const { map, drawControl, setDrawMode } = useContext(MapContext);
  const [updateMultiProject] = useUpdateMultiProjectMutation();
  const [getBoundaryFeatureCount, { isFetching }] = useLazyGetBoundaryFeatureCountQuery();
  const [listKey, setListKey] = useState(makeUuid());
  const [errors, setErrors] = useState<Record<string, AreaErrorType[]>>();

  useEffect(() => {
    drawControl.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {},
      modes: {
        ...MapboxDraw.modes,
        draw_polygon: SnapPolygonMode,
        direct_select: directSelectMode,
        ...polygonCutMode,
      },
      userProperties: true,
      ...snappingOptions,
      styles: styles,
    });
  }, [map, drawControl]);

  useEffect(() => {
    return () => {
      setDrawMode("no_feature");
    };
  }, [setDrawMode]);

  /**
   * Get the multiproject Subareas & convert them to multiple polygons ready to load as
   * the initial draw features.
   */
  const initialFeatures = useMemo(
    () =>
      multiProject?.Areas.map((area) =>
        toFeature(
          area.Boundary,
          { id: area.ID, name: area.Name, importMethod: area.ImportMethod, exactCount: 0, minCount: null, color: area.Style.Color },
          { id: `${area.ID}` }
        )
      ) || [],
    [multiProject?.Areas]
  );

  /**
   * Updates the existing subareas feature-state, allowing for
   * styles to change based on edit status.
   */
  const setExistingBoundaryVisibility = useCallback(
    (visibile: boolean) => {
      multiProject?.Areas.forEach((area) => {
        map?.setFeatureState({ id: area.ID, source: "multiProject-source" }, { isEditing: !visibile });
      });
    },
    [map, multiProject?.Areas]
  );

  // Validate the multiproject areas
  const validate = useCallback(() => {
    const validationStatus = validateAreaFeatures(drawControl.current.getAll().features as SubareaFeature[]);
    setErrors(validationStatus);
  }, [drawControl]);

  /**
   * Clips the feature being drawn or updated so that it does not:
   * 1) Overlap any other subareas (we apply a small buffer)
   * 2) Extend beyond the city boundary
   */
  const clipFeature = useCallback(
    (feature: Feature<Polygon | MultiPolygon>): FeatureCollection => {
      // Don't allow feature to overlap other subareas
      // Note: We buffer the overlaps slightly, providing a 1 meter gap between boundaries
      let newFeatures = intersect(feature, drawControl.current.getAll().features || []);

      // Don't allow feature to extend beyond city boundary
      if (multiProject?.Boundary) {
        return clipOutside(
          newFeatures.features[0] as Feature<Polygon | MultiPolygon>,
          toFeature(multiProject.Boundary),
          drawControl.current.getAll().features
        );
      }

      // No modification made
      return featureCollection([feature, ...drawControl.current.getAll().features]);
    },
    [drawControl, multiProject?.Boundary]
  );

  /**
   * When a polygon is drawn we make sure that it does not intersect with any other existing
   * boundaries.
   */
  const handleOnCreate = useCallback(
    (event: DrawCreateEvent) => {
      if (isPolygon(event.features[0])) {
        const feature = event.features[0];
        const color = layerPalette?.[drawControl.current.getAll().features.length - 1] || chroma.random().hex();

        feature.properties = {
          id: String(feature.id),
          name: generateUnusedName("Subarea", drawControl.current.getAll().features.map((feat) => feat.properties?.name || "") || []),
          importMethod: "area_select_underground",
          minCount: null,
          exactCount: 0,
          color: color,
        } as SubareaProperties;

        drawControl.current.set(clipFeature(feature));

        // Get the prem counts for the new feature
        map?.fire("draw.updatePremCount", { features: [feature] });
      }
    },
    [clipFeature, drawControl, map]
  );

  /**
   * When a polygon is modifed we make sure that it does not intersect with any other existing
   * boundaries.
   */
  const handleOnUpdate = useCallback(
    (event: DrawUpdateEvent) => {
      if (isAnyPolygon(event.features[0]) && (event.action === "drag_complete" || event.action === "move") && event.features[0].id) {
        drawControl.current.set(clipFeature(event.features[0]));

        // Since we have potentially modified the polygons we need to exit & re-enter
        // direct_select mode, otherwise mapbox-gl-draw will throw errors related to dragging vertices
        drawControl.current.changeMode("simple_select");
        drawControl.current.changeMode("direct_select", { featureId: event.features[0].id });

        // Get the prem counts for the new feature
        map?.fire("draw.updatePremCount", { features: event.features });
      } else if (event.action === "cut_feature") {
        // Get the prem counts for the new feature
        map?.fire("draw.updatePremCount", { features: event.features });
      }
    },
    [clipFeature, drawControl, map]
  );

  /**
   * When the combine or uncombine event is fired we rename
   * the created features based on the original features & update feature counts.
   */
  const handleOnCombine = useCallback(
    (event: DrawUncombineEvent | DrawCombineEvent) => {
      event.createdFeatures.forEach(({ id }, index) => {
        setFeatureProperty(drawControl.current, String(id), "id", id);
        if (index > 0 && id) {
          const newName = generateUnusedName(
            event.createdFeatures[0].properties?.name,
            drawControl.current.getAll().features.map((feat) => feat.properties?.name || "") || []
          );
          setFeatureProperty(drawControl.current, String(id), "name", newName);
        }
      });

      // Get the prem counts for the new feature
      map?.fire("draw.updatePremCount", { features: event.createdFeatures });
    },
    [drawControl, map]
  );

  /**
   * When the maps style data changes (e.g. user changes to mono view)
   */
  const handleOnStyleChange = useCallback(() => {
    setExistingBoundaryVisibility(false);
  }, [setExistingBoundaryVisibility]);

  const handleOnDelete = useCallback(
    (_: DrawDeleteEvent) => {
      validate();
      // Refresh the list of subareas
      setListKey(makeUuid());
    },
    [validate]
  );

  const handleBack = () => {
    goBack();
  };

  const onReset = () => {
    drawControl.current.deleteAll();
    initialFeatures.forEach((feat) => {
      drawControl.current.add(feat);
    });

    // Request the prem count information for the existing features
    map?.fire("draw.updatePremCount", { features: drawControl.current.getAll().features });
  };

  /**
   * Handles importing the currently draw features and updating the multiProject
   * with the new area boundaries.
   */
  const handleOnImport = () => {
    const importFeatures = drawControl.current.getAll().features.filter(isAnyPolygon) as Feature<Polygon | MultiPolygon, SubareaProperties>[];

    const areas: Partial<MultiProjectArea>[] = importFeatures.map((feat) => {
      const newFeature = isPolygon(feat) ? multiPolygon([feat.geometry.coordinates]).geometry : (feat.geometry as MultiPolygon);

      return {
        Name: feat.properties.name,
        Boundary: newFeature,
        ImportMethod: feat.properties.importMethod,
        Style: {
          Color: feat.properties.color,
        },
      };
    });

    updateMultiProject({ ID: multiProjectId, Areas: areas }).then(() => {
      setDrawMode("no_feature");
      clear();
    });
  };

  /**
   * Callback function that is called when the user created a new feature
   * or updates existing features.
   */
  const handleOnUpdatePremCount = useCallback(
    async ({ features }: { features: Feature<Polygon | MultiPolygon>[] }) => {
      // Get the boundary information to be requested
      const boundaries = features.filter(isAnyPolygon).map(({ id, geometry: { type, coordinates } }) => ({
        id: id || "",
        geometry: { type, coordinates },
      }));
      try {
        const data = await getBoundaryFeatureCount({ boundaries: boundaries }).unwrap();
        if (data.FeatureCounts) {
          Object.keys(data.FeatureCounts).forEach((id) => {
            const { ExactCount, MinCount } = data.FeatureCounts[id].Addresses;
            setFeatureProperty(drawControl.current, id, "exactCount", ExactCount);
            setFeatureProperty(drawControl.current, id, "minCount", MinCount);
          });

          validate();

          // Refresh the list of subareas
          setListKey(makeUuid());
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [drawControl, getBoundaryFeatureCount, validate]
  );

  /**
   * Callback for when the form fields for an area are changed by the user
   */
  const handleOnAreaFieldsChange = (id: string) => {
    validate();
  };

  useEffect(() => {
    // Hide the existing boundary that we are about to edit
    setExistingBoundaryVisibility(false);

    // Clean up when exiting drawing mode
    return () => {
      setExistingBoundaryVisibility(true);
    };
  }, [drawControl, setExistingBoundaryVisibility]);

  useEffect(() => {
    map?.on("draw.create", handleOnCreate);
    map?.on("draw.combine", handleOnCombine);
    map?.on("draw.uncombine", handleOnCombine);
    map?.on("draw.update", handleOnUpdate);
    map?.on("draw.delete", handleOnDelete);
    map?.on("draw.updatePremCount", handleOnUpdatePremCount);
    map?.on("styledata", handleOnStyleChange);

    return () => {
      map?.off("draw.create", handleOnCreate);
      map?.off("draw.combine", handleOnCombine);
      map?.off("draw.uncombine", handleOnCombine);
      map?.off("draw.update", handleOnUpdate);
      map?.off("draw.delete", handleOnDelete);
      map?.off("draw.updatePremCount", handleOnUpdatePremCount);
      map?.off("styledata", handleOnStyleChange);
    };
  }, [handleOnCombine, handleOnCreate, handleOnUpdate, handleOnDelete, handleOnStyleChange, handleOnUpdatePremCount, map]);

  /**
   * Handle the initialization of drawing boundaries
   */
  useEffect(() => {
    if (drawControl.current) {
      // Set the default draw mode to simple_select
      setDrawMode("simple_select");

      // If an existing boundary exists we are entering "edit" mode
      // so add the boundary as a drawn feature & hide the underlying read only version
      if ((multiProject?.Areas || []).length > 0) {
        initialFeatures.forEach((feat) => {
          drawControl.current.add(feat);
        });
      }

      // Request the prem count information for the existing features
      map?.fire("draw.updatePremCount", { features: drawControl.current.getAll().features });
    }
  }, [initialFeatures, map, multiProjectId, multiProject?.Areas, multiProject?.Boundary, setDrawMode, setExistingBoundaryVisibility, drawControl]);

  return (
    <>
      <StackedNavigationHeader onBackButtonClick={handleBack} title="Draw on map" />
      <Typography fontWeight="500">Draw Tool</Typography>
      <Box data-testid="draw-panel" sx={{ py: 2 }}>
        <Alert severity="info" data-testid="layer-alert">
          Draw the subareas using the draw polygon tool. You can also split or carve the boundary to your desired number of subareas.
          <br />
          <br />
          Note: subareas must not exceed {formatNumber(CITY_PLANNER_AREA_MAX)} prems
        </Alert>

        <AreaList key={listKey} onReset={onReset} onChange={handleOnAreaFieldsChange} errors={errors} />

        <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
          <Box>{errors && <FormHelperText error>Invalid areas found.</FormHelperText>}</Box>
          <Box>
            <Button color="primary" size="small" onClick={handleBack}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={handleOnImport}
              sx={{ ml: 1, px: 2 }}
              data-testid="finish-button"
              disabled={isFetching || errors !== undefined}
            >
              Finish
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AreaDrawPanel;

import React, { useCallback, useContext, useEffect, useState } from "react";
import { DrawModeChangeEvent } from "@mapbox/mapbox-gl-draw";
import { Box, Paper } from "@mui/material";

import { MapContext } from "../MapProvider";

import CombineButton from "./CombineButton";
import UncombineButton from "./UncombineButton";
import { CutButton, DeleteButton, PolygonButton, SelectButton, SnappingButton } from ".";

export interface IButtonProps {
  // The current draw mode
  mode: string | null;
}

const Toolbar: React.FC = () => {
  const { map, drawControl, isDrawing } = useContext(MapContext);
  const [mode, setMode] = useState<string | null>(null);

  useEffect(() => {
    if (isDrawing) {
      setMode(drawControl.current.getMode());
    }
  }, [isDrawing, drawControl]);

  /**
   * Handles the changing of the current drawmode
   */
  const handleModeChange = useCallback(
    (event: DrawModeChangeEvent) => {
      setMode(event.mode);
    },
    [setMode]
  );

  useEffect(() => {
    map?.on("draw.modechange", handleModeChange);

    return () => {
      map?.off("draw.modechange", handleModeChange);
    };
  }, [map, handleModeChange]);

  if (!isDrawing) return null;

  return (
    <Box sx={{ position: "absolute", top: 5, left: 5, display: "flex", zIndex: 999 }}>
      <Paper sx={{ backgroundColor: "white" }}>
        <SelectButton mode={mode} />
        <PolygonButton mode={mode} />
        <DeleteButton />
      </Paper>
      <Paper sx={{ backgroundColor: "white", ml: 1 }}>
        <CutButton />
        <CombineButton />
        <UncombineButton />
        <SnappingButton />
      </Paper>
    </Box>
  );
};

export default Toolbar;

import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import {
  InsertChart as InsertChartIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import { Box, Button, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";

import { Report } from "fond/types";

interface IProps {
  reports: Report[];
}

const NUM_OF_REPORTS_PER_PAGE = 3;

const ReportList: React.FC<IProps> = ({ reports }: IProps) => {
  const navigate = useNavigate();
  const [visibleReports, setVisibleReports] = useState(NUM_OF_REPORTS_PER_PAGE);

  useEffect(() => {}, [reports]);

  const showViewMoreButton = useMemo(() => {
    return reports.length > NUM_OF_REPORTS_PER_PAGE && visibleReports < reports.length;
  }, [reports, visibleReports]);

  const showViewLessButton = useMemo(() => {
    return reports.length > NUM_OF_REPORTS_PER_PAGE && visibleReports === reports.length;
  }, [reports, visibleReports]);

  const handleViewMore = () => {
    setVisibleReports((prev) => prev + NUM_OF_REPORTS_PER_PAGE);
  };

  const handleViewLess = () => {
    setVisibleReports(NUM_OF_REPORTS_PER_PAGE);
  };

  if (reports.length === 0) {
    return null;
  }

  return (
    <Box mt={1}>
      <Typography variant="body3">Here is a list of reports associated with this design that you can access:</Typography>
      <List>
        {reports.slice(0, Math.min(visibleReports, reports.length)).map((report: Report) => (
          <ListItem key={report.ID} disablePadding sx={{ borderBottom: (theme) => `1px solid ${theme.custom.borderColor.divider}` }}>
            <ListItemButton
              sx={{ paddingX: 0, paddingY: 0.5 }}
              onClick={() => {
                navigate({ pathname: `/reports/${report.ID}` });
              }}
            >
              <ListItemIcon>
                <InsertChartIcon fontSize="small" color="tertiary" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body3">{report.Name}</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {showViewMoreButton && (
        <Button variant="text" onClick={handleViewMore}>
          <Typography variant="body3" color="primary">
            View more
          </Typography>
          <KeyboardArrowDownIcon />
        </Button>
      )}
      {showViewLessButton && (
        <Button variant="text" onClick={handleViewLess}>
          <Typography variant="body3" color="primary">
            View less
          </Typography>
          <KeyboardArrowUpIcon />
        </Button>
      )}
    </Box>
  );
};

export default ReportList;

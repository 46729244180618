import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Link as LinkIcon } from "@mui/icons-material";
import { Button } from "@mui/material";

import { useGetVersionQuery } from "fond/api";
import useEntityViews from "fond/hooks/useEntityViews";
import mixpanel from "fond/mixpanel";
import { PageContent } from "fond/widgets";
import SkeletonLoadingGrid from "fond/widgets/SkeletonLoadingGrid";

import { DEFAULT_REPORT_LOADING_SKELETON, Page } from "../constants";
import { usePollReportStatusHandle, useReportData } from "../hooks";
import Navigation from "../Navigation";

import ReportOverviewPageContent from "./ReportOverviewPageContent";

const ReportOverview: React.FC = () => {
  useEntityViews();
  const { reportId } = useParams<"reportId">();

  const { report, isLoading } = useReportData(reportId);
  usePollReportStatusHandle(report);
  const { data: version } = useGetVersionQuery(report?.Version?.ID ?? "", { skip: !report?.Version?.ID });

  if (reportId) {
    mixpanel.track("Report", "Overview", "Overview viewed", { reportId: reportId });
  }

  return (
    <PageContent
      title="Overview"
      action={
        version?.Project && (
          <Button component={Link} to={`/project/${version.Project}`} startIcon={<LinkIcon />}>
            Design project
          </Button>
        )
      }
      mainContent={
        !isLoading && report && report.Status !== null ? (
          <ReportOverviewPageContent report={report} />
        ) : (
          <SkeletonLoadingGrid items={DEFAULT_REPORT_LOADING_SKELETON} />
        )
      }
      navigation={<Navigation page={Page.OVERVIEW} />}
      testId="report-overview-page"
    />
  );
};

export default ReportOverview;

import * as React from "react";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router";

import { selectFolderById, useGetFoldersQuery } from "fond/api";
import { Navigation } from "fond/projects";
import { CityPlannerList } from "fond/projects/CityPlanner";
import { Store } from "fond/types";
import { PageContent } from "fond/widgets";

import ViewButton from "./ViewButton";

interface RouteParams {
  folderId: string;
}

/**
 * A top level component used for displaying a city planner folder.
 */
const CityPlannerFolder: React.FC = () => {
  const { isFetching: isFoldersFetching } = useGetFoldersQuery(undefined);
  const { folderId } = useParams<keyof RouteParams>();

  const currentFolder = useSelector((state: Store) => (folderId ? selectFolderById(state, folderId) : undefined));

  /**
   * If folderId is not null but currentFolder is null, it indicates that the current user has no access to the multi project,
   * redirect to projects landing page.
   */
  if (folderId && !currentFolder && !isFoldersFetching) {
    return <Navigate to="/workspace/" />;
  }

  return (
    <PageContent
      title={`${currentFolder?.MultiProject?.Name ?? ""}`}
      action={<ViewButton folder={currentFolder} />}
      mainContent={<CityPlannerList />}
      navigation={<Navigation />}
      testId="multi-project-content"
    />
  );
};

export default CityPlannerFolder;

import MapboxDraw, { MapboxDrawOptions } from "@mapbox/mapbox-gl-draw";
import Difference from "@turf/difference";
import { Feature, FeatureCollection, MultiPolygon, Polygon } from "geojson";

import { CITY_PLANNER_AREA_MAX } from "fond/constants";
import { featureCollection, polygon as toPolygon } from "fond/turf";
import { isAnyPolygon, isMultiPolygon, isPolygon } from "fond/types/geojson";

import { SubareaFeature } from "./AreaPanel/AreaDrawPanel";

export type AreaErrorType = "prem_count_exceeded" | "name_duplication";

// Default snapping options used for drawing within city planner.
export const snappingOptions: Pick<MapboxDrawOptions, "snap" | "snapOptions"> = {
  snap: true,
  snapOptions: {
    snapPx: 15,
    snapToMidPoints: true,
    snapVertexPriorityDistance: 0.0025,
  },
};

/**
 * Prevents the polygon overlapping any existing features.
 */
export const intersect = (polygon: Feature<Polygon | MultiPolygon>, all: Feature[]): FeatureCollection => {
  const newFeatures: Feature[] = [];
  let diffPoly: Feature<Polygon | MultiPolygon> = { ...polygon };

  all
    .filter(isAnyPolygon)
    .filter((feature) => feature.id !== polygon.id)
    .forEach((feature) => {
      if (isAnyPolygon(feature)) {
        const diff = Difference(diffPoly, feature);
        if (diff) diffPoly = diff;
      }
      newFeatures.push(feature);
    });

  return featureCollection([{ ...diffPoly, id: polygon.id }, ...(newFeatures || [])]);
};

/**
 * Prevents a polygon from extending beyond another polygon
 */
export const clipOutside = (
  polygon: Feature<Polygon | MultiPolygon>,
  boundaryPolygon: Feature<Polygon | MultiPolygon>,
  all: Feature[]
): FeatureCollection => {
  const newFeatures: Feature[] = all.filter(isAnyPolygon).filter((feature) => feature.id !== polygon.id);
  let diffPoly: Feature<Polygon | MultiPolygon> | null = Difference(polygon, boundaryPolygon);

  diffPoly = diffPoly ? Difference(polygon, diffPoly) : polygon;

  return featureCollection([{ ...(diffPoly as Feature), id: polygon.id }, ...newFeatures]);
};

/**
 * Converts a MultiPolygon into multiple Polygon features
 */
export const uncombine = (feature: Feature<MultiPolygon | Polygon>): Feature<Polygon>[] => {
  if (isMultiPolygon(feature)) {
    const polygons = feature.geometry.coordinates.map((coord) => toPolygon(coord, { ...feature.properties }));
    return polygons;
  } else if (isPolygon(feature)) {
    return [feature];
  }
  return [];
};

/**
 * Updates a property value on a feature currently being drawn
 */
export const setFeatureProperty = (drawControl: MapboxDraw, id: string, property: string, value: any): void => {
  if (drawControl) {
    drawControl.setFeatureProperty(id, property, value);

    // We refresh the features to force a redraw
    drawControl.set(drawControl.getAll());
  }
};

/**
 * Validates the subarea features currently drawn on the map:
 *
 * - Number of prem counts cannot exceed <CITY_PLANNER_AREA_MAX>
 * - Names of areas must be unique
 *
 */
export const validateAreaFeatures = (features: SubareaFeature[]): Record<string, AreaErrorType[]> | undefined => {
  const validation: Record<string, AreaErrorType[]> = {};
  features.filter(isAnyPolygon).forEach((feat) => {
    const areaErrors: AreaErrorType[] = [];
    const names = features.filter(({ id }) => id !== feat.id).map(({ properties }) => properties?.name);

    if (feat.properties) {
      const { id, exactCount, minCount, name } = feat.properties;
      const count = minCount || exactCount || 0;
      if (count > CITY_PLANNER_AREA_MAX) {
        areaErrors.push("prem_count_exceeded");
      }

      if (names.includes(name)) {
        areaErrors.push("name_duplication");
      }

      if (areaErrors.length > 0) {
        validation[id] = areaErrors;
      }
    }
  });

  if (Object.keys(validation).length === 0) return undefined;
  return validation;
};

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Box } from "@mui/material";

import { selectAllVersionGroupConfigs, selectAllVersionLayers } from "fond/api";
import { Layout } from "fond/layout";
import LayoutProvider from "fond/layout/LayoutProvider";
import MapProvider from "fond/map/MapProvider";
import { PageMenu } from "fond/project/pageMenu";
import { loadMultiProject, setLayersVisibility } from "fond/project/redux";
import { TopBar } from "fond/topBar";
import { Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import { BlockSpinner } from "fond/widgets";

interface RouteParams {
  cityId: string;
}

const CityPlannerPage: React.FC = () => {
  const { cityId } = useParams<keyof RouteParams>();
  const dispatch = useAppDispatch();
  const [loaded, setLoaded] = useState(false);
  const layerConfigs = useSelector((state: Store) => selectAllVersionLayers(state, cityId));
  const groupConfigs = useSelector((state: Store) => selectAllVersionGroupConfigs(state, cityId));

  useEffect(() => {
    dispatch(loadMultiProject({ uuid: cityId })).then(async () => {
      setLoaded(true);
    });
  }, [dispatch, cityId]);

  /**
   * When the layers and group load we inject any missing items into
   * the layer visibility record to allow for correct layer visibility toggling.
   */
  useEffect(() => {
    if (layerConfigs && groupConfigs && loaded) {
      dispatch(setLayersVisibility({ projectId: cityId, layerConfigs: layerConfigs, groupConfigs: groupConfigs }));
    }
  }, [dispatch, layerConfigs, groupConfigs, cityId, loaded]);

  return (
    <MapProvider>
      <LayoutProvider type="multiProject">
        <Box display="flex" height="100%" width="100%" flexDirection="column">
          {loaded ? (
            <>
              <Box>
                <TopBar />
                <PageMenu type="multiProject" />
              </Box>
              <Box display="flex" flexDirection="column" height="100%" position="relative">
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  <Box position="relative" flexGrow={1}>
                    <Layout type="multiProject" />
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <Box display="flex" height="100%">
              <BlockSpinner />
            </Box>
          )}
        </Box>
      </LayoutProvider>
    </MapProvider>
  );
};

export default CityPlannerPage;

import * as React from "react";
import { PropsWithChildren, useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import mapboxgl from "mapbox-gl";

import { EditMode, ErrorEventProps, SystemOfMeasurement } from "../types";

import BaseRuler from "./BaseRuler";
import Footer from "./Footer";
import { MapCreator, refreshTileSource, setRequestTransformations } from "./Map";
import { MapContext } from "./MapProvider";
import { getMapStyle } from "./styles";

import { Container } from "./BaseMap.styles";

const useCustomStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      "& .mapboxgl-ctrl.mapboxgl-ctrl-attrib": {
        backgroundColor: "transparent",
        "& .mapboxgl-ctrl-attrib-button": {
          display: "none",
        },
      },
      "& .mapboxgl-ctrl-bottom-right": {
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(1),
      },
      "& .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib": {
        fontSize: 11,
      },
      "& .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-scale": {
        position: "absolute",
        bottom: -30,
        right: 150,
        height: 15,
        display: "flex",
        alignItems: "center",
        backgroundColor: "transparent",
        borderColor: "rgb(232, 234, 237)",
        borderWidth: 1,
        color: "rgb(232, 234, 237)",
      },
    },
    footer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: `${theme.palette.background.sidebar.primary}a6`,
      color: "rgb(232, 234, 237)",
      fontSize: 12,
      height: theme.spacing(3),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    coords: {
      cursor: "pointer",
    },
    popover: {
      backgroundColor: theme.palette.background.sidebar.primary,
      color: theme.palette.common.white,
      fontSize: 11,
    },
  })
);

interface BasicMapProps {
  bbox: [[number, number], [number, number]] | null;
  editMode: EditMode;
  systemOfMeasurement: SystemOfMeasurement;
}

/**
 * A basic map component.
 *
 * This is an alternative to the Map component that isn't tied to a particular project.
 *
 * @param children Child map components.
 * @param bbox     The bounding box to zoom this map to.
 * @param editMode The current edit mode of the map, e.g. measuring, none.
 */
const BaseMap: React.FC<PropsWithChildren<BasicMapProps>> = ({ children, bbox, editMode, systemOfMeasurement }: PropsWithChildren<BasicMapProps>) => {
  const classes = useCustomStyles();
  const { map, setMap, mapStyle: style } = useContext(MapContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const [distance, setDistance] = useState<string | undefined>();

  useEffect(() => {
    async function makeNewMap() {
      if (!containerRef.current) {
        return;
      }

      const baseStyle = getMapStyle(style);
      const newMap = await MapCreator.createMap({
        container: containerRef.current,
        style: baseStyle.url,
        bbox: bbox ?? undefined,
        attributionControl: false,
      });

      // The default attribution for a smaller map is compact which adds an annoying icon w/ a hover.
      // Force the larger attribution instead.
      newMap.addControl(new mapboxgl.AttributionControl({ compact: false }));

      // As of MAG-758 we have decided we don't want these features, especially
      // as touchpad users can activate them by mistake and now there is no way
      // to revert.
      newMap.dragRotate.disable();
      newMap.touchZoomRotate.disableRotation();
      newMap.on("load", () => {
        setMap(newMap);
      });

      newMap.on("error", async ({ error, source, sourceId }: ErrorEventProps) => {
        // Refresh the token and tiles if this is a vector tile authorisation error
        if (source?.type === "vector" && error?.status === 401) {
          await setRequestTransformations(newMap);
          refreshTileSource({ map: newMap, sourceId: sourceId, source: source });
        }
      });
    }

    makeNewMap();
  }, [containerRef]);

  return (
    <Container data-testid="basic-map-container" id="mapContainer">
      <Box height="100%" ref={containerRef} />
      {map && children}
      <Footer distance={distance} />
      <BaseRuler editMode={editMode} onChange={setDistance} systemOfMeasurement={systemOfMeasurement} />
    </Container>
  );
};

export default BaseMap;

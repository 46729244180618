import { getVersionBOM, regenerateCost } from "fond/api";
import { makeAsyncActionCreator, makeAsyncActions, makeAsyncReducer } from "fond/async/redux";
import { addNotification } from "fond/notifications/redux";
import { loadProject } from "fond/project/redux";
import { AppThunkDispatch, Store } from "fond/types";
import { reduceReducers, sum } from "fond/utils";

export const actions = {
  LOAD: makeAsyncActions("BOM/LOAD"),
  GENERATE: makeAsyncActions("BOM/GENERATE"),
};

export const load = makeAsyncActionCreator(
  actions.LOAD,
  function getInitiateArgs() {
    return {};
  },

  async function execute(getState: () => Store, versionId: string) {
    if (versionId != null) {
      return (await getVersionBOM(versionId)).json();
    } else {
      return null;
    }
  }
);

export const generate = makeAsyncActionCreator(
  actions.GENERATE,
  function getInitiateArgs() {
    return {};
  },

  function execute(getState: () => Store, versionId: string) {
    return regenerateCost(versionId);
  },

  function success(dispatch: AppThunkDispatch, getState: () => Store) {
    dispatch(loadProject({ uuid: getState().project.projectId }));
    dispatch(addNotification("Regenerating BoM."));
  }
);

const initialState = {
  bom: null,
  generateStatus: null, // AsyncOperationState
  loadStatus: null, // AsyncOperationState
};

export const reducer = reduceReducers(
  function reducer(state = initialState, action: any) {
    switch (action.type) {
      case actions.GENERATE.SUCCESS:
        return state;
      case actions.LOAD.SUCCESS:
        return { bom: addTotals(action.data) };
      default:
        return state;
    }
  },
  makeAsyncReducer(actions.LOAD, "loadStatus"),
  makeAsyncReducer(actions.GENERATE, "generateStatus")
);

function addTotals(bom: any) {
  const groups = bom.groups.map((group: any) => {
    return {
      ...group,
      total: sum(group.ruleOutput.map((r: any) => parseFloat(r.total))),
    };
  });
  return {
    groups: groups,
    total: sum(groups.map((g: any) => g.total)),
  };
}

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Paper } from "@mui/material";

import { selectFolderById, selectProjectsByParentId, useGetMultiProjectsQuery } from "fond/api";
import SearchBar from "fond/projects/SearchBar";
import { Store } from "fond/types";

import ProjectList from "../ProjectList/ProjectList";

interface RouteParams {
  folderId: string;
}

/**
 * A main page content displaying city planner projects.
 * Includes a DataGrid supporting column sorting and pagination
 */
const CityPlannerList: React.FC = () => {
  const { folderId } = useParams<keyof RouteParams>();
  const folder = useSelector((state: Store) => (folderId ? selectFolderById(state, folderId) : undefined));
  const { isLoading, isFetching } = useGetMultiProjectsQuery(undefined);
  const items = useSelector((state: Store) => (folderId ? selectProjectsByParentId(state, folderId) : undefined)) || [];
  const [searchText, setSearchText] = useState("");

  /**
   * Callback function that is passed the filter text as it changes
   */
  const handleOnFilterChange = (value: string) => {
    setSearchText(value.toUpperCase());
  };

  return (
    <>
      <Box my={3}>
        <SearchBar onChange={handleOnFilterChange} placeholder="Filter projects" />
      </Box>
      <Paper square>
        <ProjectList searchText={searchText} items={isLoading ? null : items} folder={folder} isLoading={isFetching} />
      </Paper>
    </>
  );
};

export default CityPlannerList;

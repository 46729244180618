import React from "react";
import { useParams } from "react-router";

import useEntityViews from "fond/hooks/useEntityViews";
import mixpanel from "fond/mixpanel";
import { PageContent } from "fond/widgets";
import SkeletonLoadingGrid from "fond/widgets/SkeletonLoadingGrid";

import { DEFAULT_REPORT_LOADING_SKELETON, Page } from "../constants";
import { usePollReportStatusHandle, useReportData } from "../hooks";
import ReportNavigation from "../Navigation";

import ReportCostPageContent from "./ReportCostPageContent";

const ReportPage: React.FC = () => {
  useEntityViews();
  const { reportId } = useParams<"reportId">();

  const { report, isLoading } = useReportData(reportId);
  usePollReportStatusHandle(report);

  if (reportId) {
    mixpanel.track("Report", "Cost", "Cost Viewed", { reportId: reportId });
  }

  return (
    <PageContent
      title="Cost"
      mainContent={!isLoading && report ? <ReportCostPageContent report={report} /> : <SkeletonLoadingGrid items={DEFAULT_REPORT_LOADING_SKELETON} />}
      navigation={<ReportNavigation page={Page.COST} />}
      testId="report-cost-page"
    />
  );
};

export default ReportPage;

import update from "immutability-helper";
import _ from "lodash";

import { actionLookup, actionRef } from "fond/actionref";
import * as api from "fond/api";
import { apiSlice, layersSlice, multiProjectsSlice, projectSlice, refetchVersionStatus, selectCurrentVersionStatus, versionsSlice } from "fond/api";
import { AsyncOperationState, makeAsyncReducer } from "fond/async/redux";
import { generateBuildOrderMapConfiguration } from "fond/cityPlanner/configuration";
import { inputLayerGroups, LayerIds, solverParameterToLayerId } from "fond/layers";
import { getInitialLayerToggles } from "fond/layers/functions";
import { clearFeatures, viewDesign } from "fond/map/redux";
import { MAP_STYLES } from "fond/map/styles";
import mixpanel from "fond/mixpanel";
import { addNotification } from "fond/notifications/redux";
import { getCurrentProject, getCurrentProjectData, getCurrentProjectLayerFeatureTotals, getCurrentProjectView } from "fond/project";
import * as polygon from "fond/project/polygon/redux";
import { ProjectAction } from "fond/project/reduxActions";
import { UploadSources } from "fond/project/upload";
import * as turf from "fond/turf";
import { enumerate, projectUsesVectorTiles, reduceReducers, setIn, toBBox, updateIn } from "fond/utils";
import { isVisible } from "fond/utils/configurations";
import { getRestoredFeature, prepareFeatureCollectionForDraw } from "fond/utils/geojson";
import * as localStorage from "fond/utils/localStorage";

export { getCurrentProject, getCurrentProjectData, getCurrentProjectLayerFeatureTotals, getCurrentProjectView, ProjectAction };

export const StatusTypes = {
  NotReady: "NotReady",
  Idle: "Idle",
  Running: "Running",
  Complete: "Complete",
  Cancelling: "Cancelling",
  Cancelled: "Cancelled",
  Terminated: "Terminated",
};

export const UploadEventTypes = {
  begin: "begin",
  progress: "progress",
  uploadComplete: "uploadComplete",
  complete: "complete",
  error: "error",
  abort: "abort",
};

export const POLYGON_SELECT_UPLOAD_ID = "polygon-select";

export function uploadBegin(projectId, uploadId, makeRequest, onSuccess, onError = null) {
  const baseArgs = {
    type: ProjectAction.UPLOAD_EVENT,
    projectId: projectId,
    uploadId: uploadId,
  };

  return (dispatch, getState) => {
    mixpanel.track("Began upload", { uploadId: uploadId });

    makeRequest({
      xhrEvents: {
        onProgress: (event) => {
          dispatch(progress((event.loaded / event.total) * 100));
        },
        onUploadComplete: () => {
          dispatch(uploadComplete());
        },
        onComplete: async (event, responseText) => {
          dispatch(success(responseText));
          if (onSuccess != null) {
            dispatch(onSuccess(responseText));
          }
        },
        onError: (event, responseText) => {
          onError?.();
          dispatch(error(event, responseText));
        },
        onAbort: () => dispatch(abort()),
      },
    });

    dispatch({ ...baseArgs, uploadEventType: UploadEventTypes.begin });

    function progress(percent) {
      return {
        ...baseArgs,
        uploadEventType: UploadEventTypes.progress,
        percent: percent,
      };
    }

    function uploadComplete() {
      return {
        ...baseArgs,
        uploadEventType: UploadEventTypes.uploadComplete,
      };
    }

    /**
     * @param {String (UploadSources)} source
     * @param {String} responseText
     */
    function success(responseText) {
      return (dispatch1) => {
        mixpanel.track("Completed upload", { uploadId: uploadId });
        dispatch1({
          ...baseArgs,
          uploadEventType: UploadEventTypes.complete,
          responseText: responseText,
        });
      };
    }

    function error(event, responseText) {
      let message = null;

      try {
        const errorJson = JSON.parse(responseText);
        message = errorJson.message;
      } catch {
        // Do nothing.
      }

      return (dispatch1) => {
        mixpanel.track("Failed upload", { uploadId: uploadId });
        dispatch1({
          ...baseArgs,
          uploadEventType: UploadEventTypes.error,
          failureMessage: message,
        });
      };
    }

    function abort() {
      return (dispatch1) => {
        mixpanel.track("Aborted upload", { uploadId: uploadId });
        dispatch1({
          ...baseArgs,
          uploadEventType: UploadEventTypes.abort,
        });
      };
    }
  };
}

/**
 * Begin a regular layer upload (ie. from the Upload modal).
 *
 * @param {string} projectId - we're uploading layers to this project
 * @param {string} versionId - the version to upload layers to.
 * @param {string} layerGroupId - eg. "inAddress", "inExchange", "spanPole"
 * @param {mapping of layer ids to lists of `File`s} layers
 *   Eg. {inSpan: [File1, File2, File3], inPole: [File4, File5, File6]}
 * @param {bool} convertToDualSided - as the same suggests. Only makes sense when uploading streets.
 */
export function beginLayerUpload(
  projectId,
  versionId,
  layerGroupId,
  layers,
  convertToDualSided = null,
  selectedAddressTypeField = "AddressType",
  onSuccessCallback
) {
  function makeRequest({ xhrEvents }) {
    api.uploadVersionLayers({
      versionId: versionId,
      layers: layers,
      source: UploadSources.localFile,
      convertToDualSided: convertToDualSided,
      xhrEvents: xhrEvents,
      addressTypeField: selectedAddressTypeField,
    });
  }
  function onSuccess() {
    return async (dispatch, getState) => {
      await dispatch(apiSlice.util.invalidateTags([{ type: "VersionStatus", id: versionId }], [{ type: "Version", id: versionId }]));

      const state = getState();
      if (state.project.uploadPopupLayerGroupId === layerGroupId) {
        dispatch(closeUploadPanel(layerGroupId));
      }
      if (layerGroupId === "spanPole") {
        dispatch(beginEditing("spanPole"));
      }

      if (onSuccessCallback) onSuccessCallback();

      dispatch(addNotification("Upload complete."));
      setTimeout(() => dispatch(polygon.resetUpload(projectId, layerGroupId)), 1500);

      const result = dispatch(versionsSlice.endpoints.getVersion.initiate(versionId));
      const version = (await result)?.data;
      result.unsubscribe();

      dispatch(viewDesign(version?.BoundingBox, version?.SelectedArea));

      // refetch layers once upload is completed so that layer attributes can be immediately displayed on feature popup
      dispatch(layersSlice.endpoints.getLayers.initiate(versionId, { forceRefetch: true }));
    };
  }
  return uploadBegin(projectId, layerGroupId, makeRequest, onSuccess);
}

export function beginPolygonSelectUpload(projectId, versionId, layers, uploadedArea = null, convertToDualSided = null) {
  const uploadId = POLYGON_SELECT_UPLOAD_ID;
  function makeRequest({ xhrEvents }) {
    api.uploadVersionLayers({
      versionId: versionId,
      layers: layers,
      source: UploadSources.polygonSelect,
      uploadedArea: uploadedArea,
      convertToDualSided: convertToDualSided,
      xhrEvents: xhrEvents,
    });
  }
  function onSuccess() {
    return async (dispatch, getState) => {
      // We preemptively update the getProject data to ensure the UploadedArea isn't stale.  This avoids
      // any flashing between the old and new polygons as the Project is re-fetched.
      await dispatch(
        apiSlice.util.updateQueryData("getVersion", versionId, (draft) => {
          draft.UploadedArea = uploadedArea;
        })
      );
      dispatch(addNotification("Upload complete."));
      setTimeout(() => dispatch(polygon.resetUpload(projectId, uploadId)), 1500);
      dispatch(
        apiSlice.util.invalidateTags([
          { type: "Layers", id: versionId },
          { type: "FeatureTotals", id: versionId },
          { type: "Version", id: versionId },
          { type: "VersionStatus", id: versionId },
        ])
      );
    };
  }
  function onError() {
    return async (dispatch) => {
      // Polygon drawing mode is being closed when upload begins.
      // If the upload failed, drawing mode must be reopened with the previous area selected
      dispatch(polygon.selectDrawExistingOption(uploadedArea));
    };
  }
  return uploadBegin(projectId, uploadId, makeRequest, onSuccess, onError);
}

async function isUpdateRequired(dispatch, state, newProjectID) {
  /*
   * We only need to update the project if:
   * - it is not currently loaded
   * - it is loaded but it is out of date
   */
  const currentStatus = selectCurrentVersionStatus(state);
  if (!currentStatus) {
    return true;
  }

  // Update the project if the version status has changed since last time
  const status = await refetchVersionStatus(dispatch, state.project.versionId);
  return status?.StatusCode !== currentStatus?.StatusCode;
}
/**
 * An asynchronous action creator for loading multiprojects. We load multiprojects
 * this way rather than just directly via RTK so that we can load then into the redux
 * store in the same expected manner as other projects.
 */
export function loadMultiProject({ uuid }) {
  return async (dispatch) => {
    let project;
    try {
      const result = dispatch(multiProjectsSlice.endpoints.getMultiProject.initiate(uuid));
      project = (await result)?.data;
      result.unsubscribe();
    } catch (err) {
      console.error(err);
      dispatch({ type: ProjectAction.LOAD_PROJECT_FAILURE, error: err });
    }

    if (project) {
      dispatch({
        type: ProjectAction.LOAD_PROJECT_SUCCESS,
        project: {
          ...project,
          // Calculate the bounding box which will be used to zoom to the design on first load
          BoundingBox: project.Boundary ? toBBox(turf.bbox(project.Boundary)) : null,
          EntityType: "multi_project",
        },
      });
      dispatch({ type: ProjectAction.SET_VERSION, versionId: uuid });

      // Since MultiProjects dont have a root configuration stored on the backend
      // we instead inject a client side copy instead.
      const upsert = generateBuildOrderMapConfiguration(project);

      await dispatch(versionsSlice.util.upsertQueryData("getVersionConfig", uuid, upsert));
    }
    return project;
  };
}

/**
 * An asynchronous action creator for loading projects. Will check if the
 * current project is already the project we need to avoid unnecessary fetches.
 */
export function loadProject({ uuid, forceRefetch = false }) {
  return async (dispatch, getState) => {
    dispatch({
      type: ProjectAction.LOAD_PROJECT,
      projectId: uuid,
    });

    if (forceRefetch || (await isUpdateRequired(dispatch, getState(), uuid))) {
      let project;
      try {
        const result = dispatch(projectSlice.endpoints.getProject.initiate(uuid, { forceRefetch: true }));
        project = (await result)?.data;
        result.unsubscribe();
      } catch (err) {
        console.error(err);
        dispatch({ type: ProjectAction.LOAD_PROJECT_FAILURE, error: err });
        return;
      }

      if (project.code === "" || project.code === "ValidationError") {
        dispatch({ type: ProjectAction.LOAD_PROJECT_NOT_FOUND });
      } else {
        dispatch({
          type: ProjectAction.LOAD_PROJECT_SUCCESS,
          project: { ...project, EntityType: "project" },
        });
        await dispatch(loadProjectData(project));
        // eslint-disable-next-line consistent-return
        return project;
      }
    } else {
      dispatch({ type: ProjectAction.LOAD_PROJECT_SUCCESS_CACHED });
    }
  };
}

export function loadFilesSuccessAction(projectFiles, layerConfig, groupConfig) {
  const layers = {};

  layerConfig.forEach((item) => {
    if (item.Type === "LAYER") {
      layers[item.Key] = projectFiles[item.Key] || { Features: turf.featureCollection([]), Sources: null };
    }
  });

  if (projectFiles["edits/hub"] != null) {
    // New layer id
    layers[LayerIds.hub] = projectFiles["edits/hub"];
  } else if (projectFiles["edits/hub.geojson"] != null) {
    // Transitional layer id
    layers[LayerIds.hub] = projectFiles["edits/hub.geojson"];
  }

  return {
    type: ProjectAction.LOAD_PROJECT_DATA_SUCCESS,
    data: {
      layers: layers,
      // Remember this is just `true` if the file exists else `false`.
      splice_tables: projectFiles["tier_3_solve/outputs/splice_tables.json"],
    },
    layerConfig: layerConfig,
    groupConfig: groupConfig,
  };
}

export function loadProjectData(project) {
  return async (dispatch, getState) => {
    try {
      let projectFiles;

      const { versionId } = getState().project;
      const activeImports = getState().imports?.[versionId];

      if (projectUsesVectorTiles(project)) {
        // If we are using vector tiles, we don't need to download the full geojson.
        projectFiles = {};
      } else {
        // If not using vector tiles then we must get the full geojson.
        const result = dispatch(versionsSlice.endpoints.getVersionLegacyExport.initiate(versionId, { forceRefetch: true }));
        projectFiles = (await result)?.data;
        result.unsubscribe();
      }

      let layerConfigs = {};
      let groupConfigs = {};
      if (versionId) {
        const versionConfig = (await dispatch(versionsSlice.endpoints.getVersionConfig.initiate(versionId)))?.data;
        layerConfigs = Object.values(versionConfig.Data.entities).filter((entity) => entity.Type === "LAYER" || entity.Type === "SUBLAYER");
        groupConfigs = Object.values(versionConfig.Data.entities).filter((entity) => entity.Type === "GROUP");
      }

      // Automatically open the import modal if this project has no layer configurations.
      if (project.HasCustomLayerConfig && layerConfigs.length === 0 && activeImports == null) {
        dispatch(openImportModal());
      }

      dispatch(loadFilesSuccessAction(projectFiles, layerConfigs, groupConfigs));
    } catch (err) {
      dispatch({ type: ProjectAction.LOAD_PROJECT_DATA_FAILURE, error: err });
    }
  };
}

export function polygonSelectUpload(convertToDualSided = false) {
  return (dispatch, getState) => {
    dispatch({ type: ProjectAction.POLYGON_SELECT_UPLOAD });
    const state = getState();
    const { projectId, versionId } = state.project;

    const { selectedData } = state.project.polygon;
    let files = {};

    function addLayer(layerId) {
      if (files.layerId == null) {
        files[layerId] = [new File([JSON.stringify(selectedData[layerId])], `${layerId}.geojson`)];
      }
    }

    if (state.project.polygon.selectedLayers[LayerIds.inParcel]) {
      addLayer(LayerIds.inParcel);
      addLayer(LayerIds.inAddress);
    }
    if (state.project.polygon.selectedLayers[LayerIds.inStreet]) {
      addLayer(LayerIds.inStreet);

      if (convertToDualSided) {
        addLayer(LayerIds.inParcel);
      }
    }

    if (state.project.polygon.selectedLayers[LayerIds.inSpan]) {
      addLayer(LayerIds.inSpan);
      addLayer(LayerIds.inPole);
    }

    dispatch(closePolygonSelect());
    dispatch(beginPolygonSelectUpload(projectId, versionId, files, state.project.polygon.selectedArea, convertToDualSided));
  };
}

export function dismissLoadProjectError(navigate) {
  return () => {
    navigate("/");
  };
}

export function updateProjectView(projectId, view) {
  return { type: ProjectAction.UPDATE_PROJECT_VIEW, projectId: projectId, view: view };
}

export function toggleLayerVisibility(projectId, layerId, versionConfig) {
  mixpanel.track("Toggled layer visibility", { projectId: projectId, layerId: layerId });
  return { type: ProjectAction.TOGGLE_LAYER_VISIBILITY, projectId: projectId, layerId: layerId, versionConfig: versionConfig };
}

export function setLayerVisibility(projectId, layerIds, visible) {
  return { type: ProjectAction.SET_LAYER_VISIBILITY, projectId: projectId, layerIds: layerIds, visible: visible };
}

export function setLayersVisibility({ projectId, layerConfigs, groupConfigs, overwrite = false }) {
  let layers = getInitialLayerToggles({
    layers: Object.values(layerConfigs),
    groups: Object.values(groupConfigs),
  });

  if (!overwrite) {
    const current = localStorage.getItem(`state.project.projects[${projectId}].view`, {})?.layers || {};
    layers = { ...layers, ...current };
  }

  return { type: ProjectAction.SET_LAYERS_VISIBILITY, projectId: projectId, layers: layers };
}

export function openUploadPanel(layerGroupId, isComplete) {
  mixpanel.track("Opened upload panel", { layerGroupId: layerGroupId });
  return {
    type: ProjectAction.OPEN_UPLOAD_PANEL,
    layerGroupId: layerGroupId,
    isComplete: isComplete,
  };
}

export function closeUploadPanel(layerId) {
  mixpanel.track("Closed upload panel", { layerId: layerId });
  return { type: ProjectAction.CLOSE_UPLOAD_PANEL, layerId: layerId };
}
export function dismissUploadError(layerId) {
  return { type: ProjectAction.DISMISS_UPLOAD_ERROR, layerId: layerId };
}

export function openArchitectureModal() {
  mixpanel.track("Opened architecture modal");
  return { type: ProjectAction.OPEN_ARCHITECTURE_MODAL };
}

export function closeArchitectureModal() {
  mixpanel.track("Closed architecture modal");
  return { type: ProjectAction.CLOSE_ARCHITECTURE_MODAL };
}

export function startRefreshMLC() {
  mixpanel.track("Started refreshing mlc");
  return { type: ProjectAction.START_REFRESFH_MLC };
}

export function finishRefreshMLC() {
  mixpanel.track("Finished refreshing mlc");
  return { type: ProjectAction.FINISH_REFRESH_MLC };
}

export function polygonSelect(params) {
  return {
    type: ProjectAction.BEGIN_POLYGON_SELECT,
    layerIds: params.layerIds,
  };
}

actionLookup.polygonSelect = function (params) {
  return (dispatch, getState) => {
    dispatch(polygonSelect(params));
  };
};

export function openConfirmModal(action, message) {
  return {
    type: ProjectAction.OPEN_CONFIRM_MODAL,
    confirmMessage: message,
    action: action,
  };
}

export function openImportModal() {
  return { type: ProjectAction.OPEN_IMPORT_MODEL };
}

export function closeImportModal() {
  return { type: ProjectAction.CLOSE_IMPORT_MODEL };
}

export function beginPolygonSelect(layerIds = [LayerIds.inStreet, LayerIds.inParcel]) {
  return async (dispatch, getState) => {
    mixpanel.track("Began polygon select", { layerIds: layerIds });

    const state = getState().project;
    const { data } = state.projects[state.projectId];
    const action = actionRef("polygonSelect", {
      // inAddress is the layer ID in the auto design panel, but inParcel is what
      // the polygon select logic expects.
      layerIds: layerIds.map((id) => (id === LayerIds.inAddress ? LayerIds.inParcel : id)),
    });

    // We're about to enter polygon select for one or more layers. If any of them
    // already have data for this project, defer the action of entering polygon select
    // till after the user confirms. Otherwise, enter immediately.
    if (!layerIds.some((layerId) => hasLayer(data, layerId))) {
      dispatch(action);
    } else {
      dispatch(openConfirmModal(action, "replaceLayer"));
    }
  };
}
export function closePolygonSelect() {
  return (dispatch) => {
    polygon.teardownDraw();
    dispatch({ type: ProjectAction.CLOSE_POLYGON_SELECT });
  };
}

export function uploadSuccess() {
  return { type: ProjectAction.UPLOAD_SUCCESS };
}

export function confirmLayerOperation() {
  mixpanel.track("Confirmed layer operation");
  return (dispatch, getState) => {
    const { action } = getState().project.confirm;
    dispatch({ type: "CONFIRM_LAYER_OPERATION" });
    dispatch(action);
  };
}

function _beginPolygonSelect(state, action) {
  const { project, data } = state.projects[state.projectId];
  return {
    ...state,
    editMode: EditMode.polygonSelect,
    polygon: polygon.getBeginState(state.polygon, project, data, action.layerIds),
  };
}

export function cancelLayerOperation() {
  mixpanel.track("Confirmed layer operation");
  return { type: ProjectAction.CANCEL_LAYER_OPERATION };
}

export function beginEditing(layerGroupId) {
  return (dispatch, getState) => {
    const state = getState().project;

    const action = (dispatch1) => {
      mixpanel.track("Began editing", { layerGroupId: layerGroupId });
      dispatch1({
        type: ProjectAction.BEGIN_EDITING,
        layerGroupId: layerGroupId,
      });
    };

    if (state.editMode === "edit") {
      if (layerGroupId === state.editingLayerGroupId) {
        // do nothing
      } else if (state.editing.isDirty) {
        dispatch(openConfirmModal(action, "unsavedEdits"));
      } else {
        dispatch(action);
      }
    } else {
      dispatch(action);
    }
  };
}

export function editingSetDirty() {
  return { type: ProjectAction.EDITING_SET_DIRTY };
}

export function editingRevert() {
  return { type: ProjectAction.EDITING_REVERT };
}

export function setFeatureEditorConfirm(data) {
  return { type: ProjectAction.SET_FEATURE_EDITING_CONFIRM, payload: data };
}

// This is very similar to calling makeAsyncActionCreator, but that function
// isn't (yet) clever enough to allow us to dispatch an extra action (in this
// case `fileUploaded`) on success.
export function saveEditing(featureCollection, featuresToClear) {
  return async (dispatch, getState) => {
    mixpanel.track("Saved editing");
    const state = getState();
    const { projectId, versionId } = state.project;
    const layerGroupId = state.project.editingLayerGroupId;

    let layers = {};
    for (let layer of inputLayerGroups[layerGroupId].layers) {
      const fc = turf.featureCollection(
        featureCollection.features.filter((f) => {
          return f.properties.layerId === layer.id;
        })
      );

      // Reinstate any ids we clobbered to make Mapbox work.
      const restoredFeatureCollection = turf.featureCollection(fc.features.map(getRestoredFeature));
      layers[layer.id] = [new File([JSON.stringify(restoredFeatureCollection)], `${layer.id}.geojson`)];
    }

    function makeRequest({ xhrEvents }) {
      api.uploadVersionLayers({
        versionId: versionId,
        layers: layers,
        source: UploadSources.edit,
        xhrEvents: xhrEvents,
      });
    }

    function onSuccess() {
      return function (dispatch1) {
        dispatch1(clearFeatures(featuresToClear));
        dispatch1(closeEditing());
        dispatch1(addNotification("Upload complete."));
        dispatch(
          apiSlice.util.invalidateTags([
            { type: "Layers", id: versionId },
            { type: "VersionStatus", id: versionId },
          ])
        );
        setTimeout(() => dispatch1(polygon.resetUpload(projectId, layerGroupId)), 1500);
      };
    }

    dispatch(uploadBegin(projectId, layerGroupId, makeRequest, onSuccess));
  };
}

export function beginCommenting() {
  mixpanel.track("Begin commenting");
  return { type: ProjectAction.BEGIN_COMMENTING };
}

export function endCommenting() {
  mixpanel.track("End commenting");
  return { type: ProjectAction.END_COMMENTING };
}

export function beginMeasuring() {
  mixpanel.track("Begin measuring");
  return { type: ProjectAction.BEGIN_MEASURING };
}

export function endMeasuring() {
  mixpanel.track("End measuring");
  return { type: ProjectAction.END_MEASURING };
}

export function closeEditing() {
  mixpanel.track("Closed editing");
  return { type: ProjectAction.CLOSE_EDITING };
}

export function closeProject() {
  return { type: ProjectAction.CLOSE_PROJECT };
}

export function selectFeature(feature, point, isFromFeaturesPopup = false) {
  return (dispatch, getState) => {
    mixpanel.track("Opened map popup");
    const state = getState();
    const project = state.project.projects[state.project.projectId];

    // If a point was not passed use turf to determine an appropriate lngLat
    // For example the Feature Table can select a feature from the table.
    let lngLat = point;
    if (!lngLat) {
      const bestPoint = turf.pointOnFeature(feature);
      lngLat = {
        lng: bestPoint.geometry.coordinates[0],
        lat: bestPoint.geometry.coordinates[1],
      };
    }

    // Unless the comment is selected from FeaturesPopup, reset the draggable popup states.
    if (!isFromFeaturesPopup) {
      dispatch(setDraggedPopupPosition(null));
    }

    const UsesVectorTiles = projectUsesVectorTiles(project.project);

    if (UsesVectorTiles) {
      const { properties } = feature;
      const { layerId, featureId } = properties;
      dispatch({
        type: ProjectAction.SELECT_FEATURE,
        feature: feature,
        featureId: featureId,
        featureProperties: properties,
        layerId: layerId,
        lngLat: lngLat,
      });
    } else {
      /**
       * TODO: Once all projects are using vector tiles we should remove the old
       * dispatch of SELECT_FEATURE
       */
      const { id, properties } = feature;
      const { featureId, layerId } = properties;
      const layer = project.data.layers[layerId];
      if (layer != null) {
        dispatch({
          type: ProjectAction.SELECT_FEATURE,
          /* The `feature` we got as the parameter to `selectFeature` was
           * retrieved by the `queryRenderedFeatures` method on the map. For some
           * reason this object messes with some of the types of the properties
           * (ie. `null` becomes the string "null"). However, this feature does need to
           * come directly from Mapbox to avoid the page to crash if the user clicks
           * on a feature before the layer is imported
           */
          feature: feature,
          featureId: featureId,
          featureProperties: properties,
          layerId: layerId,
          lngLat: lngLat,
        });
      }
    }
  };
}

export function openFeatureSelectionPopup(features, lngLat) {
  return (dispatch, getState) => {
    mixpanel.track("Opened map feature selection popup");
    dispatch({
      type: ProjectAction.OPEN_FEATURE_SELECTION_POPUP,
      features: features,
      lngLat: lngLat,
    });
    dispatch({
      type: ProjectAction.SET_DRAGGED_POPUP_POSITION,
      draggedPopupPosition: null,
    });
  };
}

export function unselectFeature() {
  return { type: ProjectAction.UNSELECT_FEATURE };
}

export function unselectComment() {
  return { type: ProjectAction.UNSELECT_COMMENT };
}

export const setActiveLayerGroupId = (layerGroupId) => {
  return { type: ProjectAction.SET_ACTIVE_LAYER_GROUP_ID, payload: layerGroupId };
};

/**
 * Sets which features on the map should be highlighted
 */
export function highlightFeatures(payload) {
  return { type: ProjectAction.SET_HIGHLIGHT_FEATURES, payload: payload };
}

/**
 * Saves the properties of a particular feature to the server.
 *
 * @param {String} layerId the layer ID (eg. "inAddress")
 * @param {number | string | null} featureId the id of the feature.
 * @param {Object} properties the feature's properties dictionary
 */
export function updateFeatureProperties(layerId, featureId, properties) {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({ type: ProjectAction.UPDATE_FEATURE_PROPERTIES }); // TODO: this should trigger a spinner in the feature popup

    api
      .updateFeatureProperties({
        featureId: featureId,
        properties: properties,
      })
      .then(() => {
        dispatch(apiSlice.util.invalidateTags([{ type: "VersionStatus", id: state.project.versionId }]));
        dispatch({
          type: ProjectAction.UPDATE_FEATURE_PROPERTIES_SUCCESS,
          projectId: state.project.projectId,
          layerId: layerId,
          properties: properties,
          featureId: featureId,
        });
        dispatch(addNotification("Feature updated."));
      })
      .catch(() => {
        dispatch({
          type: ProjectAction.UPDATE_FEATURE_PROPERTIES_FAILURE,
          properties: properties,
        });
      });
  };
}

export function setVersion(versionId) {
  return { type: ProjectAction.SET_VERSION, versionId: versionId };
}

export function repositionHubs() {
  return beginEditing(inputLayerGroups.hub.id);
}

export function revertHubs() {
  return async (dispatch, getState) => {
    const { versionId } = getState().project;
    const response = await api.revertMovedHubs(versionId);
    dispatch(apiSlice.util.invalidateTags([{ type: "VersionStatus", id: versionId }]));
    dispatch({ type: ProjectAction.REVERT_HUBS, response: response });
  };
}

export const EMPTY_GEOJSON = {
  type: "FeatureCollection",
  features: [],
};

export const EditMode = {
  none: "none",
  polygonSelect: "polygonSelect",
  edit: "edit",
  comment: "comment",
  measure: "measure",
};

export const Modals = {
  confirm: "confirm",
  architecture: "architecture",
  import: "import",
};

const projectLoadState = {
  projectRenameStatus: null, // null | 'error'
  modal: null, // Modals entry

  confirm: {
    message: null,
    action: null,
  },

  polygon: polygon.initialState,

  // Mode is not remembered; if the user exits a project and re-enters it,
  // they will no longer be in an edit mode.
  editMode: EditMode.none,

  editing: {
    isDirty: false,
    confirm: null,
  },

  uploadPopupLayerGroupId: null, // which upload panel is open if any
  editingLayerGroupId: null, // which layer is being edited, if any
  activeLayerGroupId: null,
};

export const initialState = {
  loadProjectStatus: null, // AsyncOperationState
  loadDataStatus: null, // AsyncOperationState

  isCreatingProject: false, // is a new project currently being created?
  projectId: null,
  /*
    {
      'project-id': {
        project: <project payload>,
        data: <mapping of layer ids to layers>,
        view: {
          location: (
            {
              bbox: [[minx, miny], [maxx, maxy]]
            }
            <or>
            {
              camera: {
                center: {lng: number, lat: number},
                zoom: number
              }
            }
          ),
          layers: <mapping of layer ids to boolean values denoting whether the layer is visible>
          style: <MAP_STYLES entry>
        },
        uploads: {
          // Map of layer IDs to upload objects. If there's no upload happening
          // for a layer, the layer ID need not exist in this mapping.
          inAddress: {
            status: AsyncOperationState,
            percent: number
          },
          ...
        }
      }
    }
  */
  projects: {},
  selectedFeature: null,
  selectedComment: null,
  panels: localStorage.getItem("state.project.panels", []),
  draggedPopupPosition: null,
  // The current version the project map is focused on
  versionId: undefined,
  ...projectLoadState,
};

export function hasLayer(data, layerId) {
  if (data == null) {
    return false;
  }
  const dataLayer = data.layers[layerId];
  return dataLayer != null && dataLayer.features != null && dataLayer.features.length > 0;
}

const accordionSteps = [
  (architecture, _status, _reportStepComplete) => architecture != null,
  (_architecture, status, _reportStepComplete) => status?.Status !== StatusTypes.NotReady,
  (_architecture, status, _reportStepComplete) => status?.Status === StatusTypes.Complete && !status.IsDirty,
  (_architecture, status, reportStepComplete) => status?.Status === StatusTypes.Complete && reportStepComplete,
];

/**
 * Return the max panel index users can navigate to.
 */
export function getProjectArchitecturePanelIndex(architecture, status, reportStepComplete) {
  for (let [i, step] of enumerate([...accordionSteps].reverse())) {
    // Return the largest step number with status complete.
    if (step(architecture, status, reportStepComplete)) {
      return Math.min(accordionSteps.length - i, accordionSteps.length - 1);
    }
  }
  return 0;
}

export function getAccordionStepClassSets(architecture, status, reportStepComplete) {
  const completed = accordionSteps.map((step) => step(architecture, status, reportStepComplete));
  return accordionSteps.map((_step, i) => {
    return {
      completed: completed[i],
      current: !completed[i] && (i === 0 || completed[i - 1]),
      disabled: i > 0 && !completed[i - 1] && !completed[i],
    };
  });
}

export function canUpdateDesign(state, status, quality) {
  return (
    (state.editing.hubsMoved || status?.IsDirty || (quality && status?.WorkflowQuality && status.WorkflowQuality !== quality)) &&
    state.editMode === EditMode.none
  );
}

export function setDraggedPopupPosition(section) {
  return { type: ProjectAction.SET_DRAGGED_POPUP_POSITION, section: section };
}

function mainReducer(state, action) {
  if (state == null) {
    return initialState;
  }

  switch (action.type) {
    case ProjectAction.LOAD_PROJECT:
      return {
        ...state,
        loadProjectStatus: AsyncOperationState.executing,
        loadDataStatus: null,
        projectId: action.projectId,
        ...projectLoadState,
      };
    case ProjectAction.LOAD_PROJECT_SUCCESS:
      return {
        ...state,
        loadProjectStatus: AsyncOperationState.success,
        loadDataStatus: AsyncOperationState.executing,
        projectId: action.project.ID,
        projects: {
          ...state.projects,
          [action.project.ID]: {
            ...state.projects[action.project.ID],
            project: action.project,
            view: {
              location: {
                bbox: action.project.BoundingBox || null,
              },
              style: MAP_STYLES.map,
              loading: false,
              ...localStorage.getItem(`state.project.projects[${action.project.ID}].view`, {}),
            },
            // If we are reloading a project (because it changed since last
            // time we opened it), make sure to clear the data and the layer
            // config, which we reload (along with `view.layers`) in
            // LOAD_PROJECT_DATA_SUCCESS. Otherwise components get confused
            // thinking we have up-to-date data when we don't. Especially
            // problematic if we have `data` and `layerConfig` but not
            // `view.layers`.
            data: null,
            uploads: {},
            highlightedFeatures: [],
          },
        },
      };
    case ProjectAction.LOAD_PROJECT_SUCCESS_CACHED:
      return {
        ...state,
        loadProjectStatus: null,
        loadDataStatus: null,
      };
    case ProjectAction.LOAD_PROJECT_FAILURE:
      return {
        ...state,
        loadProjectStatus: AsyncOperationState.failure,
      };
    case ProjectAction.LOAD_PROJECT_NOT_FOUND:
      return {
        ...state,
        loadProjectStatus: AsyncOperationState.notFound,
      };
    case ProjectAction.CLOSE_PROJECT:
      return update(state, {
        projectId: { $set: null },
        versionId: { $set: null },
        loadProjectStatus: { $set: null },
        selectedFeature: { $set: null },
        selectedComment: { $set: null },
        featureSelectionPopup: { $set: null },
        draggedPopupPosition: { $set: null },
      });
    case ProjectAction.LOAD_PROJECT_DATA_SUCCESS:
      if (action.layerConfig == null) {
        throw new Error("LOAD_PROJECT_DATA_SUCCESS must have a layerConfig");
      }

      if (action.groupConfig == null) {
        throw new Error("LOAD_PROJECT_DATA_SUCCESS must have a groupConfig");
      }

      let layers = {};
      if (action.data.layers != null) {
        _.forEach(action.data.layers, (featureCollection, layerId) => {
          if (featureCollection.Features != null) {
            layers[layerId] = {
              ...prepareFeatureCollectionForDraw(_.cloneDeep(featureCollection.Features), layerId),
              sources: featureCollection.Sources,
            };
          }
        });
      }

      return {
        ...state,
        projects: {
          ...state.projects,
          [state.projectId]: {
            ...state.projects[state.projectId],
            data: {
              ...action.data,
              layers: layers,
            },
            view: {
              ...state.projects[state.projectId].view,
              layers: getInitialLayerToggles({
                layers: action.layerConfig,
                groups: action.groupConfig,
              }),
              ...localStorage.getItem(`state.project.projects[${state.projectId}].view`, {}),
            },
          },
        },
        loadDataStatus: AsyncOperationState.success,
      };
    case ProjectAction.REVERT_HUBS:
      // On reverting hubs:
      // - Reset the cabinet and closure data layer
      // - Reset the project InputFiles to reflect that closure and cabinet are no longer updated
      // - Close the editing panel and reset its state
      const hubs = action.response.Hubs;
      prepareFeatureCollectionForDraw(hubs, LayerIds.hub);

      return {
        ...updateIn(state, ["projects", state.projectId], (project) => {
          return {
            ...project,
            data: setIn(project.data, ["layers", LayerIds.hub], hubs),
          };
        }),
        editMode: EditMode.none,
        editing: {
          ...state.editing,
          isDirty: false,
          hubsMoved: false,
        },
      };
    case ProjectAction.LOAD_PROJECT_DATA_FAILURE:
      return {
        ...state,
        loadDataStatus: AsyncOperationState.failure,
      };
    case ProjectAction.UPDATE_PROJECT_VIEW:
      return updateIn(state, ["projects", action.projectId, "view"], (view) => {
        return {
          ...view,
          ...action.view,
        };
      });
    case ProjectAction.TOGGLE_LAYER_VISIBILITY:
      return setIn(
        state,
        ["projects", action.projectId, "view"],
        toggleLayer(action.versionConfig, state.projects[action.projectId].view, action.layerId)
      );
    case ProjectAction.SET_LAYER_VISIBILITY:
      return setIn(state, ["projects", action.projectId, "view"], setLayers(state.projects[action.projectId].view, action.layerIds, action.visible));
    case ProjectAction.SET_LAYERS_VISIBILITY:
      return setIn(state, ["projects", action.projectId, "view", "layers"], action.layers);
    case ProjectAction.UPLOAD_EVENT:
      if (action.uploadEventType === UploadEventTypes.begin) {
        return setIn(state, ["projects", action.projectId, "uploads", action.uploadId], {
          status: AsyncOperationState.executing,
          percent: 0,
          uploadComplete: false,
          failureMessage: null, // wipe the failureMessage clean each time the upload begins
        });
      } else if (action.uploadEventType === UploadEventTypes.progress) {
        return setIn(state, ["projects", action.projectId, "uploads", action.uploadId, "percent"], action.percent);
      } else if (action.uploadEventType === UploadEventTypes.uploadComplete) {
        return setIn(state, ["projects", action.projectId, "uploads", action.uploadId, "uploadComplete"], true);
      } else if (action.uploadEventType === UploadEventTypes.complete) {
        const responseJson = JSON.parse(action.responseText);
        const newState = setIn(state, ["projects", action.projectId, "uploads", action.uploadId, "status"], AsyncOperationState.success);
        return {
          ...updateIn(newState, ["projects", newState.projectId], (project) => {
            let newLayers = {};
            _.forEach(responseJson.Layers, (featureCollection, solverParameter) => {
              const layerId = solverParameterToLayerId(solverParameter);
              newLayers[layerId] = {
                ...prepareFeatureCollectionForDraw(featureCollection, layerId),
                sources: [action.uploadId === POLYGON_SELECT_UPLOAD_ID ? UploadSources.polygonSelect : UploadSources.localFile],
              };
            });

            return {
              ...project,
              project: {
                ...project.project,
              },
              data: {
                ...project.data,
                layers: {
                  ...project.data?.layers,
                  ...newLayers,
                },
              },
            };
          }),
          // We don't distinguish between an upload event from the upload modal
          // vs. an upload event from polygon select. So we will end up calling
          // the polygon reducer for uploads from the upload modal, but that's a
          // harmless no-op.
          polygon: polygon.reducer(newState.polygon, action),
        };
      } else if (action.uploadEventType === UploadEventTypes.error || action.uploadEventType === UploadEventTypes.abort) {
        let newState = setIn(state, ["projects", action.projectId, "uploads", action.uploadId, "status"], AsyncOperationState.failure);
        newState = setIn(newState, ["projects", action.projectId, "uploads", action.uploadId, "failureMessage"], action.failureMessage);
        return newState;
      } else {
        throw new Error(`Unknown uploadEventType: ${action.uploadEventType}`);
      }
    case ProjectAction.UPDATE_DATA.SUCCESS: {
      const project = { ...action.project, EntityType: "project" };
      const newState = setIn(state, ["projects", action.project.ID, "project"], project);
      if (action.loadingFiles) {
        return { ...newState, loadDataStatus: AsyncOperationState.executing };
      } else {
        return newState;
      }
    }
    case ProjectAction.RUN_SOLVE.SUCCESS:
      return {
        ...state,
        editing: {
          ...state.editing,
          hubsMoved: false,
        },
      };

    case ProjectAction.OPEN_UPLOAD_PANEL: {
      return {
        ...state,
        uploadPopupLayerGroupId: action.layerGroupId,
        // If the user clicks to upload a file while a design is already complete,
        // they'll be confronted with the "Are you sure you want to replace the design"
        // dialog first, and they'll only get here after they confirm that. So there's
        // no need to have the extra "You have uploaded this layer, do you want to replace?"
        // step.
        isReplacing: action.isComplete,
      };
    }
    case ProjectAction.CLOSE_UPLOAD_PANEL:
      return {
        ...setIn(state, ["projects", state.projectId, "uploads", action.layerId], null),
        uploadPopupLayerGroupId: null,
      };
    case ProjectAction.DISMISS_UPLOAD_ERROR:
      return setIn(state, ["projects", state.projectId, "uploads", action.layerId], null);

    case ProjectAction.OPEN_ARCHITECTURE_MODAL:
      return {
        ...state,
        modal: Modals.architecture,
      };
    case ProjectAction.CLOSE_ARCHITECTURE_MODAL:
      return { ...state, modal: null };
    case ProjectAction.START_REFRESFH_MLC:
      return {
        ...state,
        mlc: {
          isDirty: true,
        },
      };
    case ProjectAction.FINISH_REFRESH_MLC:
      return {
        ...state,
        mlc: {
          isDirty: false,
        },
      };
    case ProjectAction.OPEN_IMPORT_MODEL:
      return {
        ...state,
        modal: Modals.import,
      };
    case ProjectAction.CLOSE_IMPORT_MODEL:
      return { ...state, modal: null };
    case ProjectAction.OPEN_CONFIRM_MODAL:
      return {
        ...state,
        modal: Modals.confirm,
        confirm: {
          message: action.confirmMessage,
          action: action.action,
        },
      };
    case ProjectAction.BEGIN_POLYGON_SELECT:
      return _beginPolygonSelect(state, action);
    case ProjectAction.POLYGON_SELECT_UPLOAD:
      return setIn(state, ["polygon", "polygonState"], "upload");
    case ProjectAction.CLOSE_POLYGON_SELECT:
      return {
        ...state,
        editMode: EditMode.none,
        polygon: polygon.initialState,
      };
    case ProjectAction.RESET_UPLOAD: {
      return {
        ...setIn(state, ["projects", action.projectId, "uploads", action.layerId], null),
      };
    }
    case ProjectAction.BEGIN_EDITING:
      return {
        ...state,
        editMode: EditMode.edit,
        editingLayerGroupId: action.layerGroupId,
        editing: {
          ...state.editing,
          upload: null,
          isDirty: false,
        },
      };
    case ProjectAction.EDITING_SET_DIRTY: {
      return update(state, { editing: { isDirty: { $set: true } } });
    }
    case ProjectAction.EDITING_REVERT:
    case ProjectAction.SAVE_EDITING.SUCCESS: {
      return update(state, { editing: { isDirty: { $set: false } } });
    }

    case ProjectAction.SAVE_EDITING.SUCCESS_TIMEOUT_EXPIRED:
    case ProjectAction.CLOSE_EDITING:
      return {
        ...state,
        editMode: EditMode.none,
        editing: {
          ...state.editing,
          isDirty: false,
          hubsMoved: state.editingLayerGroupId === inputLayerGroups.hub.id,
          confirm: null,
        },
      };

    case ProjectAction.SET_FEATURE_EDITING_CONFIRM:
      return setIn(state, ["editing", "confirm"], action.payload);

    case ProjectAction.BEGIN_COMMENTING:
      return {
        ...state,
        editMode: EditMode.comment,
      };
    case ProjectAction.END_COMMENTING:
      return {
        ...state,
        editMode: EditMode.none,
      };
    case ProjectAction.BEGIN_MEASURING:
      return {
        ...state,
        editMode: EditMode.measure,
      };
    case ProjectAction.END_MEASURING:
      return {
        ...state,
        editMode: EditMode.none,
      };
    case ProjectAction.CONFIRM_LAYER_OPERATION:
    case ProjectAction.CANCEL_LAYER_OPERATION:
      return {
        ...state,
        confirm: null,
        modal: null,
      };

    case ProjectAction.SELECT_FEATURE:
      return update(state, {
        selectedFeature: {
          $set: {
            feature: action.feature,
            featureId: action.featureId,
            featureProperties: action.featureProperties,
            layerId: action.layerId,
            lngLat: action.lngLat,
            isSaving: false,
            error: null,
          },
        },
        selectedComment: { $set: null },
        featureSelectionPopup: { $set: null },
        projects: {
          [state.projectId]: {
            highlightedFeatures: { $set: [action.feature] },
          },
        },
      });
    case ProjectAction.UNSELECT_FEATURE:
      if (state.projectId) {
        return update(state, {
          selectedFeature: { $set: null },
          featureSelectionPopup: { $set: null },
          projects: {
            [state.projectId]: {
              highlightedFeatures: { $set: [] },
            },
          },
        });
      } else {
        return update(state, {
          selectedFeature: { $set: null },
          featureSelectionPopup: { $set: null },
        });
      }
    case ProjectAction.SELECT_COMMENT:
      return update(state, {
        selectedComment: {
          $set: {
            commentID: action.payload.commentID,
            lngLat: action.payload.lngLat,
            mapSelection: action.payload.mapSelection,
            showPopup: action.payload.showPopup,
          },
        },
        featureSelectionPopup: { $set: null },
        projects: {
          [state.projectId]: {
            highlightedFeatures: { $set: action.payload.highlightedFeatures },
          },
        },
      });
    case ProjectAction.UNSELECT_COMMENT:
      if (state.projectId) {
        return update(state, {
          selectedComment: { $set: null },
          featureSelectionPopup: { $set: null },
          editMode: { $set: EditMode.none },
          projects: {
            [state.projectId]: {
              highlightedFeatures: { $set: [] },
            },
          },
        });
      } else {
        return state;
      }
    case ProjectAction.OPEN_FEATURE_SELECTION_POPUP:
      return update(state, {
        selectedFeature: { $set: null },
        selectedComment: { $set: null },
        featureSelectionPopup: {
          $set: {
            features: action.features,
            lngLat: action.lngLat,
          },
        },
        projects: {
          [state.projectId]: {
            highlightedFeatures: { $set: [] },
          },
        },
      });
    case ProjectAction.SET_HIGHLIGHT_FEATURES:
      return update(state, {
        projects: {
          [action.payload.projectId]: {
            highlightedFeatures: { $set: action.payload.features },
          },
        },
      });
    case ProjectAction.UPDATE_FEATURE_PROPERTIES_SUCCESS:
      return updateIn(state, ["projects", action.projectId], (project) => {
        const featureIndex = project.data.layers[action.layerId].features.findIndex((f) => f.properties.featureId === action.featureId);
        return {
          ...project,
          data: updateIn(project.data, ["layers", action.layerId, "features", featureIndex, "properties"], (properties) => ({
            ...properties,
            ...action.properties,
          })),
        };
      });
    case ProjectAction.UPDATE_FEATURE_PROPERTIES_FAILURE: {
      // TODO: update some state to result in a error modal appearing
      return state;
    }
    case ProjectAction.SET_VERSION:
      return {
        ...state,
        versionId: action.versionId,
      };
    case ProjectAction.REPOSITION_HUBS:
      return {
        ...state,
        editMode: EditMode.edit,
        editingLayerGroupId: inputLayerGroups.hub.id,
      };
    case ProjectAction.UPDATE_SYSTEM_OF_MEASUREMENT.SUCCESS: {
      return setIn(state, ["projects", action.data.ID, "project", "SystemOfMeasurement"], action.data.SystemOfMeasurement);
    }
    case ProjectAction.SET_DRAGGED_POPUP_POSITION: {
      return {
        ...state,
        draggedPopupPosition: action.section,
      };
    }
    case ProjectAction.SET_ACTIVE_LAYER_GROUP_ID: {
      return {
        ...state,
        activeLayerGroupId: action.payload,
      };
    }
    default:
      if (action.type.startsWith("PROJECT/POLYGON/")) {
        return {
          ...state,
          polygon: polygon.reducer(state.polygon, action),
        };
      } else {
        return state;
      }
  }
}

export const reducer = reduceReducers(mainReducer, makeAsyncReducer(ProjectAction.UPDATE_SYSTEM_OF_MEASUREMENT, ["updateSystemOfMeasurementStatus"]));

function toggleLayer(versionConfig, projectView, layerId) {
  // eslint-disable-next-line no-param-reassign
  projectView = projectView || { layers: {} };
  const visible = versionConfig ? isVisible(versionConfig, { id: layerId, layerView: projectView.layers }) : true;

  return {
    ...projectView,
    layers: {
      ...projectView.layers,
      // If the layer doesn't exist in the projectView, check the version configuration
      // to determine whether to hide/show the layer.
      [layerId]: !((projectView.layers || {})[layerId] ?? visible),
    },
  };
}

function setLayers(projectView, layerIds, visible) {
  // eslint-disable-next-line no-param-reassign
  projectView = projectView || { layers: {} };

  const updates = { ...projectView.layers };
  layerIds.forEach((id) => {
    updates[id] = visible;
  });

  return {
    ...projectView,
    layers: updates,
  };
}

export function isSolveActive(state) {
  return isRunningSolve(selectCurrentVersionStatus(state)?.Status) || state.runStatus === AsyncOperationState.executing;
}

export function isRunningSolve(status) {
  return status && _.includes([StatusTypes.Running, StatusTypes.Cancelling], status);
}

export function getRunningStepIndex(status) {
  /**
    If solve is not started, return -1
    If solve is running, return the index (zero-based) of the running step
    If solve is complete, return the index (zero-based) of the last step, plus one.
  */
  const workflow = getWorkflow(status);

  if (status?.Status === StatusTypes.Complete) {
    return workflow.length;
  } else {
    return _.findIndex(workflow, (w) => w.Status === StatusTypes.Running);
  }
}

export function getWorkflow(status) {
  return status?.Workflow;
}

import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { usePrevious } from "react-use";
import { Lock } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import classNames from "classnames";

import Architecture from "fond/architecture/Architecture";
import { ArchitecturePanel, AreaDrawPanel, AreaPanel, BoundaryDrawPanel, BoundaryPanel, CarvePanel } from "fond/cityPlanner";
import { closeArchitectureModal, Modals, openArchitectureModal } from "fond/project/redux";
import { ArchitectureBase, MultiProject, Store } from "fond/types";
import { enumerate } from "fond/utils";
import { useAppDispatch } from "fond/utils/hooks";
import * as localStorage from "fond/utils/localStorage";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { NonIdealState, StackedNavigation } from "fond/widgets";

/**
 * Status check for each step that determines if the step has been completed
 */
const accordionSteps = [
  (multiProject: MultiProject) => multiProject.Architecture !== null,
  (multiProject: MultiProject) => multiProject.Boundary !== null,
  (multiProject: MultiProject) => multiProject.Areas.length > 0,
  (multiProject: MultiProject) => multiProject.Areas.some((area) => area.Project?.ID),
];

const getAccordionStepClassSets = (multiProject: MultiProject) => {
  const completed = accordionSteps.map((step) => step(multiProject));
  return accordionSteps.map((_, i) => {
    return {
      completed: completed[i],
      current: !completed[i] && (i === 0 || completed[i - 1]),
      disabled: i > 0 && !completed[i - 1],
    };
  });
};

const getStartingIndex = (multiProject: MultiProject) => {
  for (let [i, step] of enumerate(accordionSteps)) {
    if (!step(multiProject)) {
      return i;
    }
  }
  return accordionSteps.length - 1;
};

type IProps = {
  /**
   * The project currently being viewed / edited
   */
  multiProject: MultiProject;
  /**
   * The current architecture.
   */
  architecture: ArchitectureBase | null;
  /**
   * Flag indicating if the project is read only
   */
  readOnly: boolean;
};

const cityPlannerStackedNavigationPanels: Array<{ id: string; name: string; component: React.ReactNode; useDefaultHeader?: boolean }> = [
  {
    id: "boundaryDraw",
    name: "Boundary",
    component: <BoundaryDrawPanel />,
    useDefaultHeader: false,
  },
  {
    id: "areaDraw",
    name: "Subareas",
    component: <AreaDrawPanel />,
    useDefaultHeader: false,
  },
];

const AutoDesignPanel: React.FC<IProps> = ({ architecture, multiProject, readOnly }: IProps) => {
  const dispatch = useAppDispatch();
  const modal = useSelector((state: Store) => state.project.modal);
  const stepClassSets = getAccordionStepClassSets(multiProject);
  const previousArchitecture = usePrevious(architecture);
  const accordionLocalStorageKey = `state.project.city[${multiProject?.ID}].accordion`;
  const [activeIndex, setActiveIndex] = useState(
    localStorage.getItem(accordionLocalStorageKey)?.activeIndex ?? (multiProject ? getStartingIndex(multiProject) : 0)
  );
  const setAccordionIndex = useCallback(
    (index: number) => {
      setActiveIndex(index);
      localStorage.setItem(accordionLocalStorageKey, { activeIndex: index });
    },
    [accordionLocalStorageKey]
  );

  useEffect(() => {
    // When the user first applies an architecture to a multiProject we move to the next step.
    // We don't ever move between steps after that.
    if (!previousArchitecture && !!architecture && activeIndex === 0) {
      setAccordionIndex(1);
    }
  }, [activeIndex, architecture, previousArchitecture, setAccordionIndex]);

  /**
   * Handles the expanding of the clicked according item if that item is not disabled.
   */
  const handleOnClickIndex = (index: number) => {
    if (index !== activeIndex) {
      const maxIndex = getStartingIndex(multiProject);
      if (index <= maxIndex) {
        setAccordionIndex(index);
      }
    }
  };

  const handleOnChangeArchitectureClick = () => dispatch(openArchitectureModal());
  const handleOnArchitectureModalClose = () => dispatch(closeArchitectureModal());

  const accordionPanes = [
    {
      header: "Architecture",
      id: "architecture",
      content: (
        <div style={{ textAlign: "left" }}>
          <ArchitecturePanel multiProject={multiProject} readOnly={readOnly} onChangeClick={handleOnChangeArchitectureClick} />
        </div>
      ),
    },
    {
      header: "City",
      id: "city",
      content: <BoundaryPanel multiProject={multiProject} />,
    },
    {
      header: "Subareas",
      id: "subareas",
      content: <AreaPanel multiProject={multiProject} />,
    },
    {
      header: "Projects",
      id: "projects",
      content: <CarvePanel multiProject={multiProject} />,
    },
  ];

  if (!multiProject) return null;

  return (
    <Box className="auto-design-panel" data-testid="city-planner-design-panel">
      {!permissionCheck(multiProject.Permission?.Level, Actions.PROJECT_EDIT) ? ( // if user has access and no license, more granular "locks" are applied per component
        <NonIdealState size="small" icon={<Lock />} title="View only access" description="You only have view access to this project" />
      ) : (
        <Box className="auto-design-panel__inner">
          <StackedNavigation
            rootComponent={
              <div className={classNames("accordion", "customScrollbars")}>
                {accordionPanes.map((pane, i) => {
                  return (
                    <Accordion key={`${pane.id}_title`} data-testid={`accordion-pane-${pane.id}`} expanded={activeIndex === i} elevation={0}>
                      <AccordionSummary
                        onClick={() => handleOnClickIndex(i)}
                        style={{
                          minHeight: "auto",
                          margin: 0,
                        }}
                        className={classNames("title", { ...stepClassSets[i] }, { active: activeIndex === i })}
                      >
                        <Box p="5px">
                          <div className="workflow-step-number-circle">
                            <div className="workflow-step-number-circle__inner">
                              <div className="workflow-step-number-circle__number">{i + 1}</div>
                            </div>
                          </div>
                        </Box>
                        <Typography variant="body1" className="accordion-panel__title">
                          {pane.header}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails key={`${pane.id}_content`} className="accordion-panel__content" style={{ margin: 0 }}>
                        {pane.content}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
            }
            screens={cityPlannerStackedNavigationPanels}
          />
        </Box>
      )}
      {modal === Modals.architecture && (
        <Architecture onClose={() => handleOnArchitectureModalClose()} project={multiProject} architecture={multiProject.Architecture} />
      )}
    </Box>
  );
};

export default AutoDesignPanel;

import React from "react";
import { Alert } from "@mui/material";

import { MultiProject } from "fond/types";
import { StackedNavigationButton, SvgIcon, useStackedNavigationContext } from "fond/widgets";

interface BoundaryPanelProps {
  multiProject: MultiProject;
}

const BoundaryPanel: React.FC<BoundaryPanelProps> = ({ multiProject }: BoundaryPanelProps) => {
  const hasSubareas = multiProject.Areas.length > 0;
  const { open } = useStackedNavigationContext();

  const handleOnClick = () => {
    open("boundaryDraw");
  };

  return (
    <>
      <StackedNavigationButton
        data-testid="boundary-method-navigation"
        icon={<SvgIcon icon="polygon" />}
        title="Boundary"
        onClick={handleOnClick}
        disabled={hasSubareas}
        sx={{ m: 0 }}
      />
      {hasSubareas && (
        <Alert severity="info" icon={false} sx={{ mt: 1 }}>
          <b>Note:</b> the boundary cannot be edited once subareas have been drawn.
        </Alert>
      )}
    </>
  );
};

export default BoundaryPanel;

// This drawing mode is inspired by https://www.npmjs.com/package/mapbox-gl-draw-cut-polygon-mode
// which is no longer maintained & working with MapboxDraw v1.4+

/* eslint-disable no-underscore-dangle */
import MapboxDraw, { DrawCustomMode, DrawCustomModeThis } from "@mapbox/mapbox-gl-draw";
import difference from "@turf/difference";
import { defer } from "lodash";

import { isAnyPolygon } from "fond/types/geojson";

import { defaultOptions, HIGHLIGHT_PROPERTY_NAME, PASSING_MODE_NAME } from "./constants";

const {
  constants: { events },
} = MapboxDraw;

type ExtendedDrawCustomMode = DrawCustomMode & {
  fireUpdate(feature: MapboxDraw.DrawFeature): void;
};

/**
 * A custom drawing mode that allows the user to select polygon(s) & then draw
 * a overlapping polygon that will cut the selected polygons where the overlap
 * occurs.
 */
const cutPolygonMode: ExtendedDrawCustomMode = {
  toDisplayFeatures: function toDisplayFeatures(_, geojson, display) {
    display(geojson);
  },

  onSetup: function onSetup(opt) {
    const { highlightColor = defaultOptions.highlightColor } = opt || {};
    const originalFeatures = this.getSelected()
      .filter((feat) => feat.type === "Polygon" || feat.type === "MultiPolygon")
      .map((feat) => feat.toGeoJSON());

    if (originalFeatures.length < 1) {
      throw new Error("Please select a feature/features (Polygon or MultiPolygon) to cut!");
    }

    const { api } = this._ctx;

    // The `onSetup` job should complete for this mode to work.
    // so we defer to bypass mode change until after `onSetup` is done executing.
    defer(() => {
      this.changeMode(PASSING_MODE_NAME, {
        onDraw: (cuttingPolygon) => {
          originalFeatures.forEach((feature) => {
            if (feature.type === "Feature" && isAnyPolygon(feature)) {
              const afterCut = difference(feature, cuttingPolygon);
              if (afterCut) {
                const newFeature = this.newFeature({ ...afterCut, id: feature.id });

                this.addFeature(newFeature);
                this.fireUpdate(newFeature);
              }
            } else {
              throw new Error("The feature is not a Polygon or MultiPolygon");
            }
          });
        },
        onCancel: () => {
          // Remove highlighted features
          originalFeatures.forEach((feature) => {
            if (feature.type === "Feature" && feature.id) api.setFeatureProperty(String(feature.id), HIGHLIGHT_PROPERTY_NAME, undefined);
          });
        },
      });
    });

    // Set highlight feature property for features to cut
    originalFeatures.forEach((feature) => {
      if (feature.type === "Feature" && feature.id) api.setFeatureProperty(String(feature.id), HIGHLIGHT_PROPERTY_NAME, highlightColor);
    });

    return {
      originalFeatures,
    };
  },

  fireUpdate: function fireUpdate(this: DrawCustomModeThis, newFeature) {
    this.map.fire(events.UPDATE, {
      action: "cut_feature",
      features: [newFeature.toGeoJSON()],
    });
  },
};

export default cutPolygonMode;

import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, createRoutesFromChildren, matchRoutes, Navigate, Route, Routes, useLocation, useNavigationType } from "react-router-dom";
import { LicenseManager } from "@ag-grid-enterprise/core";
import { Auth } from "@aws-amplify/auth";
import { Amplify } from "@aws-amplify/core";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grow from "@mui/material/Grow";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as Sentry from "@sentry/react";
import { ConfigCatProvider } from "configcat-react";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { SnackbarProvider } from "notistack";

import CreateAccount from "fond/accounts/CreateAccount";
import withUserInfo from "fond/accounts/withUserInfo";
import { CityPlannerPage } from "fond/cityPlanner/CityPlannerPage";
import { init as cognitoInit } from "fond/cognito/redux";
import { ACCOUNT_CONTEXT } from "fond/constants";
import { useFeatureFlag } from "fond/featureFlags";
import ForgotPassword from "fond/forgotPassword/ForgotPassword";
import ForgotPasswordSubmit from "fond/forgotPassword/ForgotPasswordSubmit";
import { Impersonate } from "fond/impersonate";
import ImportControllers from "fond/import/controller/ImportControllers";
import InvalidInvitation from "fond/invitation/InvalidInvitation";
import Invitation from "fond/invitation/Invitation";
import { Popout } from "fond/layout";
import NoMatch from "fond/noMatch/NoMatch";
import Notifier from "fond/notifications/Notifier";
import PrivateRoute from "fond/PrivateRoute";
import ProjectPage from "fond/project/ProjectPage";
import SignIn from "fond/signIn/SignIn";
import SignOut from "fond/signIn/SignOut";
import SignUp from "fond/signUp/SignUp";
import VerifyEmail from "fond/signUp/VerifyEmail";
import { snackbarComponentVariantMap } from "fond/snackbar";
import store from "fond/store";
import { StyleEditorPage } from "fond/styleEditor";
import theme from "fond/theme";
import { UserRoles } from "fond/types";
import { isAppStageIn } from "fond/utils/stages";
import { AccountManagement, Insights, ProfileManagement, UserManagement } from "fond/views";
import CityPlannerFolder from "fond/views/CityPlannerFolder/CityPlannerFolder";
import Folders from "fond/views/Folders/Folders";
import MainWorkspace from "fond/views/MainWorkspace/MainWorkspace";
import Checkout from "fond/views/Payment/Checkout";
import PaymentConfirmation from "fond/views/Payment/Confirmation/PaymentConfirmation";
import RenewalConfirmation from "fond/views/Payment/Confirmation/RenewalConfirmation";
import PaymentFailure from "fond/views/Payment/Failure/PaymentFailure";
import Recent from "fond/views/Recent/Recent";
import { CreateReport, ImportReport, ReportCost, ReportOverview, ReportRevenue, ReportSettings } from "fond/views/Report";
import PollReportStatus from "fond/views/Report/PollReportStatus/PollReportStatus";
import SharedWithMe from "fond/views/Shared/SharedWithMe";
import Starred from "fond/views/Starred/Starred";
import Modal from "fond/widgets/Modal";
import SupportedBrowser from "fond/widgets/SupportedBrowser";

import "./index.scss";

/*
 * Setup the aws amplify library with the correct cognito user pool information.
 */
const awsConfig = {
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    // Used for specifying a Cognito endpoint override. Can be left blank for default behavior.
    endpoint: process.env.REACT_APP_COGNITO_ENDPOINT,
    // Used to change the authentication flow type to "USER_PASSWORD_AUTH" for local Cognito simulation, which is
    // needed to prevent the email confirmation flow, as it's inaccessible via Docker. Can be left blank for default
    // behavior.
    authenticationFlowType: process.env.REACT_APP_AUTHENTICATION_FLOW_TYPE,
  },
};

Amplify.configure(awsConfig);
Auth.configure(awsConfig);

// Initialise dayjs settings / plugins
dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

// Set AgGrid license
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY!);

// Don't run sentry if we're running locally or running tests.
if (!isAppStageIn(["local", "test"])) {
  Sentry.init({
    dsn: "https://47c3988215ff4f299aebf95570e339e3@o312892.ingest.sentry.io/5227566",
    environment: process.env.REACT_APP_STAGE,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    // Capture 1.5% of transaction for performance monitoring.  This is conservative - once we
    // have a better idea of the number of transactions we can bump this rate as appropriate.
    tracesSampleRate: 0.015,
  });
}

/**
 * The root of the application that is mounted after the providers have been
 * mounted.  This allows the content to only be rendered once we have initialised
 * cognito (which requires redux to already be mounted)
 */
const Root = () => {
  const { value: isCreateSubscriptionsEnabled } = useFeatureFlag("stripe_create_subscriptions");
  const { value: isRenewSubscriptionsEnabled } = useFeatureFlag("stripe_renew_subscriptions");

  const [init, setInit] = useState(false);
  const [isStale, setIsStale] = useState(false);
  useEffect(() => {
    // Wait for Cognito to initialise, which includes getting the user's
    // attributes (eg. first and last names), if they're logged in, before trying
    // to render anything.
    async function initUser() {
      await cognitoInit(store);
      setInit(true);
    }
    initUser();
  }, []);

  useEffect(() => {
    const showAlert = (e: StorageEvent) => {
      if (e.key === ACCOUNT_CONTEXT) {
        setIsStale(true);
      }
    };
    window.addEventListener("storage", showAlert);

    return () => window.removeEventListener("storage", showAlert);
  }, []);

  if (!init) return null;

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <SnackbarProvider
      maxSnack={1}
      TransitionComponent={Grow}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      Components={snackbarComponentVariantMap}
    >
      <Modal
        open={isStale}
        header="Inactive tab, please refresh"
        content="The account selected has been switched in a different tab. Please refresh to see the updated changes."
        actions={
          <Button
            data-testid="refresh-button"
            variant="contained"
            color="primary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh
          </Button>
        }
      />
      <Notifier />
      <BrowserRouter>
        {/* Render the ImportControllers at top level to support status tracking of imports from any page */}
        <ImportControllers />
        <SentryRoutes>
          <Route path="/" element={<PrivateRoute component={withUserInfo(MainWorkspace)} />} />
          <Route path="/workspace" element={<PrivateRoute component={withUserInfo(MainWorkspace)} />} />
          <Route path="/shared" element={<PrivateRoute component={withUserInfo(SharedWithMe)} />} />
          <Route path="/recent" element={<PrivateRoute component={withUserInfo(Recent)} />} />
          <Route path="/starred" element={<PrivateRoute component={withUserInfo(Starred)} />} />
          <Route path="/folders/:folderId" element={<PrivateRoute component={withUserInfo(Folders)} />} />

          <Route path="/cities/:folderId" element={<PrivateRoute component={withUserInfo(CityPlannerFolder)} />} />
          <Route path="/city/:cityId" element={<PrivateRoute component={withUserInfo(CityPlannerPage)} />} />

          <Route path="/project/:uuid/" element={<PrivateRoute component={withUserInfo(ProjectPage)} />}>
            <Route path=":type/:id/" element={<PrivateRoute component={withUserInfo(ProjectPage)} />} />
          </Route>

          <Route path="/reports/create" element={<PrivateRoute component={withUserInfo(CreateReport)} />} />
          <Route path="/reports/import" element={<PrivateRoute component={withUserInfo(ImportReport)} />} />
          <Route path="/reports/:reportId">
            <Route path="settings" element={<PrivateRoute component={withUserInfo(ReportSettings)} />} />
            <Route path="overview" element={<PrivateRoute component={withUserInfo(ReportOverview)} />} />
            <Route path="cost" element={<PrivateRoute component={withUserInfo(ReportCost)} />} />
            <Route path="revenue" element={<PrivateRoute component={withUserInfo(ReportRevenue)} />} />
            <Route path="poll" element={<PrivateRoute component={withUserInfo(PollReportStatus)} />} />
            <Route index element={<Navigate to="overview" replace />} />
          </Route>

          <Route path="/settings" element={<Navigate to="/settings/profile" replace />} />
          <Route path="/settings/profile" element={<PrivateRoute component={withUserInfo(ProfileManagement)} />} />
          <Route path="/styles/:uuid" element={<PrivateRoute component={withUserInfo(StyleEditorPage)} />} />
          <Route path="/settings/account" element={<PrivateRoute component={withUserInfo(AccountManagement, UserRoles.admin)} />} />
          <Route path="/settings/users" element={<PrivateRoute component={withUserInfo(UserManagement, UserRoles.admin)} />} />
          <Route path="/settings/insights" element={<PrivateRoute component={withUserInfo(Insights, UserRoles.admin)} />} />

          {isRenewSubscriptionsEnabled && (
            <>
              <Route path="/checkout" element={<PrivateRoute component={withUserInfo(Checkout, UserRoles.admin)} />} />
              <Route path="/renewal-confirmed" element={<PrivateRoute component={withUserInfo(RenewalConfirmation, UserRoles.admin)} />} />
            </>
          )}
          {isCreateSubscriptionsEnabled && (
            <>
              <Route path="/payment-confirmed" element={<PrivateRoute component={withUserInfo(PaymentConfirmation, UserRoles.admin)} />} />
              <Route path="/payment-failed" element={<PrivateRoute component={withUserInfo(PaymentFailure, UserRoles.admin)} />} />
            </>
          )}

          <Route path="/signout" element={<SignOut />} />
          <Route path="/signin" element={<SignIn />} />
          {/* old route, just keeping around for old links */}
          <Route path="/login" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/verifyemail" element={<VerifyEmail />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/forgotpasswordsubmit" element={<ForgotPasswordSubmit />} />
          <Route path="/createaccount" element={<PrivateRoute component={withUserInfo(CreateAccount)} />} />
          <Route path="/invitations">
            <Route path="invalid" element={<InvalidInvitation />} />
            <Route path=":invitationId" element={<Invitation />} />
            <Route index element={<NoMatch />} />
          </Route>
          <Route path="/impersonate/:email" element={<Impersonate />} />
          <Route path="/widget" element={<Popout />} />
          {/* catch all route which sends the user to a 'page not found' component */}
          <Route path="*" element={<NoMatch />} />
        </SentryRoutes>
      </BrowserRouter>
    </SnackbarProvider>
  );
};

export const App = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <SupportedBrowser />
        <Provider store={store}>
          <ConfigCatProvider sdkKey={process.env.REACT_APP_CONFIG_CAT_KEY!}>
            <Root />
          </ConfigCatProvider>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

import { useEffect } from "react";
import * as React from "react";
import { useSelector } from "react-redux";

import { selectProjectAccount, useGetAccountModulesQuery } from "fond/api";
import { Architecture as _Architecture, ArchitectureBase, MultiProject, Project, Store } from "fond/types";

import ArchitectureContent from "./ArchitectureContent";
import { ArchitectureEditorContextProvider, load, useArchitectureEditorContext } from "./context";

interface IProps {
  project: Project | MultiProject;
  architecture: _Architecture | ArchitectureBase | null;
  onClose(): void;
}

const Architecture: React.FC<IProps> = ({ project, architecture, onClose }: IProps) => {
  return (
    <ArchitectureEditorContextProvider>
      <ArchitectureWrapper project={project} architecture={architecture} onClose={onClose} />
    </ArchitectureEditorContextProvider>
  );
};

const ArchitectureWrapper: React.FC<IProps> = ({ project, architecture, onClose }: IProps) => {
  const [, dispatch] = useArchitectureEditorContext();
  const bomArchRestrictions = useSelector((state: Store) => selectProjectAccount(state, project)?.BomArchRestrictions);
  const { data: accountModules } = useGetAccountModulesQuery(project.Account.ID);

  useEffect(() => {
    dispatch(load(project.Account, accountModules, architecture, bomArchRestrictions, project.SystemOfMeasurement));
  }, []);

  return <ArchitectureContent project={project} onClose={onClose} />;
};

export default Architecture;

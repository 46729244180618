import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Folder } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Divider, LinearProgress, Typography } from "@mui/material";

import { LoadingButton } from "ui";

import { useCarveMultiProjectMutation } from "fond/api";
import { MultiProject } from "fond/types";

import { ViewButton } from "./CarvePanel.styles";

enum Status {
  // Projects have been created
  COMPLETE = "complete",
  // There was an error during the creation process
  ERROR = "error",
  // The projects are being created
  PENDING = "pending",
  // The projects are ready to be created
  READY = "ready",
}

interface CarvePanelProps {
  multiProject: MultiProject;
}

const CarvePanel: React.FC<CarvePanelProps> = ({ multiProject }: CarvePanelProps) => {
  const navigate = useNavigate();
  const [carveMultiProject] = useCarveMultiProjectMutation();
  const hasProjects = useMemo(() => multiProject?.Areas.some((area) => area.Project?.ID), [multiProject]);
  const [status, setStatus] = useState<Status>(hasProjects ? Status.COMPLETE : Status.READY);

  const handleOnClick = () => {
    if (multiProject && !hasProjects) {
      setStatus(Status.PENDING);
      carveMultiProject({ ID: multiProject.ID })
        .unwrap()
        .then(() => {
          setStatus(Status.COMPLETE);
        })
        .catch(() => {
          setStatus(Status.ERROR);
        });
    }
  };

  const handleOnViewButtonClick = () => {
    navigate(`/cities/${multiProject?.Folder.ID}`);
  };

  return (
    <>
      {status === Status.READY && (
        <Alert severity="info">
          <AlertTitle>City planner is ready to be created</AlertTitle>A project will be created for each subarea.
          <br />
          <br />
          <Typography>
            <b>Note: Once projects are created the boundary and subareas are no longer editable.</b>
          </Typography>
        </Alert>
      )}

      {status === Status.PENDING && (
        <>
          <Alert severity="info">
            <AlertTitle sx={{ fontSize: 14, mb: 0 }}>Creating subarea projects</AlertTitle>
          </Alert>
          <Box sx={{ m: 2 }}>
            <LinearProgress variant="indeterminate" sx={{ mb: 0.5 }} />
            <Typography variant="caption">Processing</Typography>
          </Box>
        </>
      )}

      {status === Status.COMPLETE && (
        <>
          <Alert severity="success">
            <AlertTitle sx={{ fontSize: 14, mb: 0 }}>Subareas created</AlertTitle>
          </Alert>
          <Box sx={{ m: 1 }}>
            <Typography variant="caption">All subarea projects have been created</Typography>
          </Box>
          <Divider />
          <ViewButton startIcon={<Folder color="primary" />} onClick={handleOnViewButtonClick} fullWidth>
            View city planner projects
          </ViewButton>
          <Divider />
        </>
      )}

      {status === Status.ERROR && (
        <Alert severity="error">
          <AlertTitle sx={{ fontSize: 14, mb: 0 }}>Error creating subarea projects</AlertTitle>
          Something went wrong with creating the subarea projects. Please try again.
        </Alert>
      )}

      {status !== Status.COMPLETE && (
        <Box sx={{ mt: 1 }}>
          <LoadingButton
            onClick={handleOnClick}
            fullWidth
            variant="contained"
            disabled={status === Status.PENDING}
            loading={status === Status.PENDING}
          >
            Create Projects
          </LoadingButton>
        </Box>
      )}
    </>
  );
};

export default CarvePanel;

import { useContext } from "react";
import * as React from "react";
import { Check, Dashboard, Undo } from "@mui/icons-material";
import { Divider, ListItemIcon, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Actions } from "flexlayout-react";
import { ITabAttributes } from "flexlayout-react/declarations/model/IJsonModel";

import { multiProjectWidgets, projectWidgets } from "fond/layout/defaultLayout";
import { LayoutContext } from "fond/layout/LayoutProvider";
import { Widget } from "fond/types";
import { NestedMenuItem } from "fond/widgets";

const useStyles = makeStyles(() => ({
  menuList: {
    padding: 0,
  },
}));

interface IProps {
  type: "project" | "multiProject";
  parentMenuOpen: boolean;
  handleClose: () => void;
}

const LayoutMenu: React.FC<IProps> = ({ type, parentMenuOpen, handleClose }: IProps) => {
  const classes = useStyles();
  const { model, layoutRef, reset } = useContext(LayoutContext);
  const widgets = type === "project" ? projectWidgets : multiProjectWidgets;

  /**
   * Handles adding or removing a widget from the layout
   */
  const handleOnClick = (widget: ITabAttributes) => {
    if (model && widget.id) {
      const node = model.getNodeById(widget.id);
      if (node) {
        model.doAction(Actions.deleteTab(widget.id));
      } else {
        // Toggle maximize if there is any maximized widget before adding a new one
        const maximizedWidget = model.getMaximizedTabset();
        if (maximizedWidget) {
          model.doAction(Actions.maximizeToggle(maximizedWidget.getId()));
        }

        layoutRef.current.addTabWithDragAndDropIndirect(`Drag & Drop: ${widget.name}`, widget);
      }
    }

    handleClose();
  };

  const isWidgetOnLayout = (id: Widget): boolean => {
    if (model) {
      const node = model.getNodeById(id);
      return Boolean(node);
    }
    return false;
  };

  return (
    <NestedMenuItem
      data-testid="project-menu-layout-widgets"
      label="Layout"
      parentMenuOpen={parentMenuOpen}
      leftIcon={
        <ListItemIcon>
          <Dashboard />
        </ListItemIcon>
      }
      MenuProps={{
        open: true,
        MenuListProps: { dense: true },
      }}
      menuClasses={{ list: classes.menuList }}
    >
      {Object.values(widgets).map((widget) => (
        <MenuItem
          key={`map-style-${widget.id}`}
          data-testid={`map-style-${widget.id}`}
          onClick={() => {
            handleOnClick(widget);
          }}
          disabled={widget.id === Widget.Map || widget.id === Widget.CityMap}
        >
          {widget.id === Widget.Map || widget.id === Widget.CityMap ? (
            <ListItemIcon>
              <Check />
            </ListItemIcon>
          ) : (
            <ListItemIcon>{isWidgetOnLayout(widget.id) && <Check />}</ListItemIcon>
          )}

          {widget.name}
        </MenuItem>
      ))}
      <Divider style={{ margin: 0 }} />
      <MenuItem data-testid="map-style-default" onClick={reset}>
        <ListItemIcon>
          <Undo />
        </ListItemIcon>
        Default Layout
      </MenuItem>
    </NestedMenuItem>
  );
};

export default LayoutMenu;

import * as React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import {
  Box,
  FormControl,
  FormLabel,
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps as MuiSelectProps,
  SxProps,
  Theme,
} from "@mui/material";
import { ClassNameMap } from "@mui/styles";

import { FormHelperText } from "./common.styles";

export type SelectProps = Partial<Omit<MuiSelectProps, "onChange">> & {
  name: string;
  options: Array<{
    value: string | number;
    displayValue: string;
    disabled?: boolean;
    startAdornment?: JSX.Element;
    endAdornment?: JSX.Element;
    styles?: SxProps<Theme>;
  }>;
  /**
   * Flag indicating if the validate error text should be shown
   */
  hideError?: boolean;
  "data-testid"?: string;
  fieldProps?: Partial<FieldProps<any, any>>;
  validate?: any;
  menuStyle?: React.CSSProperties;
  labelClasses?: Partial<ClassNameMap<string>>;
  classes?: Partial<ClassNameMap<string>>;
  sxProps?: SxProps;
  nativeSelect?: boolean;
  onCustomChange?: (event: SelectChangeEvent) => void;
};

const SelectField: React.FC<SelectProps> = (props: SelectProps) => {
  const { name, options, fieldProps, validate, ...rest } = props;

  return (
    <Field
      name={name}
      render={({ input, meta }) => <SelectWrapper input={input} meta={meta} name={name} options={options} {...rest} />}
      validate={validate}
      {...fieldProps}
    />
  );
};

type SelectWrapperProps = Pick<SelectProps, "options" | "menuStyle" | "classes" | "labelClasses" | "hideError" | "nativeSelect"> &
  Partial<Omit<MuiSelectProps, "onChange">> &
  FieldRenderProps<string, HTMLElement>;

const SelectWrapper: React.FC<SelectWrapperProps> = ({
  input: { name, onChange, value, ...restInput },
  meta,
  options,
  disabled,
  label,
  labelClasses,
  autoFocus,
  placeholder,
  required,
  fullWidth,
  helperText,
  hideError = false,
  "data-testid": dataT = "select-field",
  displayEmpty,
  variant,
  nativeSelect = false,
  margin,
  onCustomChange,
  menuStyle,
  sxProps,
  classes,
  className,
  size,
  startAdornment,
}: SelectWrapperProps) => {
  const hasError = meta.error && meta.touched;
  return (
    <FormControl fullWidth={fullWidth} data-testid={`${name}-select-field`} sx={sxProps}>
      {(label || required) && (
        <FormLabel required={required} error={hasError} classes={labelClasses} sx={{ mb: 1 }} htmlFor={`label-${name}`}>
          {label}
        </FormLabel>
      )}

      <Select
        data-testid={dataT || `${name}-input`}
        name={name}
        native={nativeSelect}
        onChange={(event: any) => {
          onChange(event);
          if (onCustomChange) {
            onCustomChange(event);
          }
        }}
        value={value}
        fullWidth={fullWidth}
        inputProps={{
          id: `label-${name}`,
          ...restInput,
        }}
        error={hasError}
        margin={margin}
        autoFocus={autoFocus}
        disabled={disabled}
        displayEmpty={displayEmpty}
        variant={variant}
        classes={classes}
        size={size}
        className={className}
        startAdornment={startAdornment}
      >
        {placeholder && (
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            style={menuStyle}
            key={option.value}
            value={option.value}
            disabled={option.disabled}
            data-testid={`select-item-${option.value}`}
            sx={option.styles}
          >
            <Box display="flex" justifyContent="flex-start" alignItems="center" width="100%">
              {option.startAdornment && <ListItemIcon>{option.startAdornment}</ListItemIcon>}
              <Box sx={{ flexGrow: 1 }}>{option.displayValue}</Box>
              {option.endAdornment}
            </Box>
          </MenuItem>
        ))}
      </Select>

      {((hasError && !hideError) || helperText) && <FormHelperText error={hasError}>{meta.touched ? meta.error : helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectField;

/* eslint-disable func-names */
import MapboxDraw, { DrawCustomMode } from "@mapbox/mapbox-gl-draw";
import { SnapDirectSelect } from "mapbox-gl-draw-snap-mode";

const { constants: Constants } = MapboxDraw;

const directSelect: DrawCustomMode = {
  ...SnapDirectSelect,
};

/**
 * We customise the onMouseUp event to fire an additional event "drag_complete"
 * which we can use to monitor in the draw.update listener.
 */
directSelect.onMouseUp = function (state, e) {
  SnapDirectSelect.onMouseUp?.call(this, state, e);

  this.map.fire(Constants.events.UPDATE, {
    action: "drag_complete",
    features: this.getSelected().map((f) => f.toGeoJSON()),
  });
};

export default directSelect;
